import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getUsers = createAsyncThunk("AdminUsersFuncs", async({page,size,searchValue,col,dir}, {rejectWithValue,dispatch})=>{
    let colCheck;

    if (col) {
        // colCheck = col === 'Created at' ? 'created_at' : col === 'Last Login' ? 'last_login' : col === 'Updated at' ? 'updated_at' : col === 'Name' ? 'full_name' :  col.replace(/\s+/g, '');
        colCheck = 
            (col === 'Created at' || col === 'تم انشاءه فى' ) ? 'created_at' 
          : (col === 'Last Login' || col === 'اخر تسجيل دخول') ? 'last_login' 
          : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
          : (col === 'Name' || col === 'الاسم') ? 'full_name'
          : (col === 'Email' || col === 'الايميل') ? 'email'
          :  col.replace(/\s+/g, '');
    }
    
    const url = !col ? `/users?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/users?page=${page ? page : 1}&size=${size ? size : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${searchValue ?`&search=${searchValue}`: ''}`;
    
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addUser = createAsyncThunk("addUserFuncs", async(values, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await toast.promise(axios.post(`/users`,values),
        {
            pending: 'loading...',
            success: 'User Added Successfully 👌',
            // error: 'Promise rejected 🤯'
        },{toastId : "UserAddingToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getBackOfficeStuff = createAsyncThunk("getBackOfficeStuffFuncs", async(_, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`/users/back-office/list`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getBackOfficeUsers = createAsyncThunk("getBackOfficeUsersFuncs", async({page,size,searchValue,col,dir}, {rejectWithValue,dispatch})=>{

    let colCheck;

    if (col) {
        // colCheck = col === 'Created At' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Name' ? 'first_name' :  col.replace(/\s+/g, '').toLowerCase();
        colCheck = 
            (col === 'Created at' || col === 'تم انشاءه فى' ) ? 'created_at' 
          : (col === 'Last Login' || col === 'اخر تسجيل دخول') ? 'last_login' 
          : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
          : (col === 'Name' || col === 'الاسم') ? 'first_name'
          : (col === 'Email' || col === 'الايميل') ? 'email'
          :  col.replace(/\s+/g, '');
    }
    
    const url = !col ? `/users/back-office?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/users/back-office?page=${page ? page : 1}&size=${size ? size : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${searchValue ?`&search=${searchValue}`: ''}`;

    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getCustomerServiceUsers = createAsyncThunk("getCustomerServiceUsersFuncs", async({page,size,searchValue,col,dir}, {rejectWithValue,dispatch})=>{

    let colCheck;

    if (col) {
        // colCheck = col === 'Created At' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Name' ? 'first_name' :  col.replace(/\s+/g, '');
        colCheck = 
        (col === 'Created at' || col === 'تم انشاءه فى' ) ? 'created_at' 
      : (col === 'Last Login' || col === 'اخر تسجيل دخول') ? 'last_login' 
      : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
      : (col === 'Name' || col === 'الاسم') ? 'first_name'
      : (col === 'Email' || col === 'الايميل') ? 'email'
      :  col.replace(/\s+/g, '');
    }
    
    const url = !col ? `/users/customer-service?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/users/customer-service?page=${page ? page : 1}&size=${size ? size : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${searchValue ?`&search=${searchValue}`: ''}`;

    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getSuperadminUsers = createAsyncThunk("getSuperadminUsersFuncs", async({page,size,searchValue,col,dir}, {rejectWithValue,dispatch})=>{

    let colCheck;

    if (col) {
        // colCheck = col === 'created at' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Name' ? 'first_name' :  col.replace(/\s+/g, '');
        colCheck = 
        (col === 'Created at' || col === 'تم انشاءه فى' ) ? 'created_at' 
      : (col === 'Last Login' || col === 'اخر تسجيل دخول') ? 'last_login' 
      : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
      : (col === 'Name' || col === 'الاسم') ? 'first_name'
      : (col === 'Email' || col === 'الايميل') ? 'email'
      :  col.replace(/\s+/g, '');
    }
    
    const url = !col ? `/users/admin?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/users/admin?page=${page ? page : 1}&size=${size ? size : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${searchValue ?`&search=${searchValue}`: ''}`;

    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getAccountManager = createAsyncThunk("getAccountManagerFuncs", async({page,size,searchValue,col,dir}, {rejectWithValue,dispatch})=>{

    let colCheck;

    if (col) {
        // colCheck = col === 'created at' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Name' ? 'first_name' :  col.replace(/\s+/g, '');
        colCheck = 
        (col === 'Created at' || col === 'تم انشاءه فى' ) ? 'created_at' 
      : (col === 'Last Login' || col === 'اخر تسجيل دخول') ? 'last_login' 
      : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
      : (col === 'Name' || col === 'الاسم') ? 'first_name'
      : (col === 'Email' || col === 'الايميل') ? 'email'
      :  col.replace(/\s+/g, '');
    }
    
    const url = !col ? `users/account-managers?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `users/account-managers?page=${page ? page : 1}&size=${size ? size : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${searchValue ?`&search=${searchValue}`: ''}`;

    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

const initialState = {
    loading: false,
    users : [],
    backOfficeUsers : [],
    backOfficeStuff : [],
    customerServiceUsers : [],
    adminUsers : [],
    accountManager : [],
    error : null
}
export const UsersSlice = createSlice({ 
    name: "UsersSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getUsers.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getUsers.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.users = action.payload
            // console.log(action.payload);
        })

        .addCase(getUsers.rejected, (state,action)=>{
            state.loading = false;
            state.users = ""
            state.error = action.payload
        })
        .addCase(addUser.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addUser.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(addUser.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(getBackOfficeUsers.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getBackOfficeUsers.fulfilled, (state,action)=>{
            state.loading = false;
            state.backOfficeUsers = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getBackOfficeUsers.rejected, (state,action)=>{
            state.loading = false;
            state.backOfficeUsers = action.payload
            state.error = action.payload
        })

        .addCase(getBackOfficeStuff.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getBackOfficeStuff.fulfilled, (state,action)=>{
            state.loading = false;
            state.backOfficeStuff = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getBackOfficeStuff.rejected, (state,action)=>{
            state.loading = false;
            state.backOfficeStuff = action.payload
            state.error = action.payload
        })

        .addCase(getCustomerServiceUsers.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getCustomerServiceUsers.fulfilled, (state,action)=>{
            state.loading = false;
            state.customerServiceUsers = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getCustomerServiceUsers.rejected, (state,action)=>{
            state.loading = false;
            state.customerServiceUsers = null
            state.error = action.payload
        })

        .addCase(getSuperadminUsers.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getSuperadminUsers.fulfilled, (state,action)=>{
            state.loading = false;
            state.adminUsers = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getSuperadminUsers.rejected, (state,action)=>{
            state.loading = false;
            state.adminUsers = null
            state.error = action.payload
        })

        .addCase(getAccountManager.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getAccountManager.fulfilled, (state,action)=>{
            state.loading = false;
            state.accountManager = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getAccountManager.rejected, (state,action)=>{
            state.loading = false;
            state.accountManager = null
            state.error = action.payload
        })


    }
});

// export const {} = UsersSlice.actions

export default UsersSlice.reducer

