import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Astrisks from "../../../Components/Common/astrisk"
import { Button, FormControl, InputAdornment, OutlinedInput } from "@mui/material"
import { useEffect } from "react"
import { getDeliveryValues, updateDeliveryValues } from "../../../Components/Redux/Slice/Delivery/delivery"
import Overlay from "../../../Components/Common/overlay"
import { toast } from "react-toastify"

const ChargesDetails = ({t}) => {
    const dispatch = useDispatch()
    const form = useForm()
    const {register,control,handleSubmit,formState,reset,setValue} = form
    const {errors,isDirty} = formState

    const {delivery} = useSelector(state=>state)

    // console.log(delivery?.loading);
    useEffect(() => {
        dispatch(getDeliveryValues())
    }, [dispatch])

    const resetDefault= () =>{
        setValue('minimumFare',delivery?.deliveryValues?.data?.minimumFare)
        setValue('maximumFare',delivery?.deliveryValues?.data?.maximumFare)
        setValue('baseRatePerKm',delivery?.deliveryValues?.data?.baseRatePerKm)
        setValue('driverShare',delivery?.deliveryValues?.data?.driverShare)
    }

    useEffect(() => {
        setValue('minimumFare',delivery?.deliveryValues?.data?.minimumFare)
        setValue('maximumFare',delivery?.deliveryValues?.data?.maximumFare)
        setValue('baseRatePerKm',delivery?.deliveryValues?.data?.baseRatePerKm)
        setValue('driverShare',delivery?.deliveryValues?.data?.driverShare)
    }, [delivery?.deliveryValues?.data?.baseRatePerKm, delivery?.deliveryValues?.data?.driverShare, delivery?.deliveryValues?.data?.maximumFare, delivery?.deliveryValues?.data?.minimumFare, setValue])
    

    const submitHandler = (data)=>{
        // console.log(data);
        if(parseInt(data?.minimumFare) > parseInt(data?.maximumFare)){
            toast.error('Minimum Fare can not be larger than Maximum Fare')
        } else {
        dispatch(updateDeliveryValues(data))
        }
    }

    return ( 
        <section className="delivery-charges-section">
            <div className="charges-details-wrapper">
                <section className="charges-details-title">
                    <h2 className="font-semibold text-lg">{t('delivery.charges_details.title')}</h2>
                </section>

                <form className="charges-details-form-wrapper mt-5" onSubmit={handleSubmit(submitHandler)} noValidate>
                    <section className="form-inputs-wrapper flex flex-wrap gap-4 [&>div]:basis-full [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1 [&>div>input]:text-sm">
                        <div>
                            <label>{t('delivery.charges_details.form.min_fare')} <Astrisks /></label>
                            <input type="text" id="minimumFare"
                                className="placeholder:capitalize"
                                placeholder={t('delivery.charges_details.form.min_fare_placeholder')}
                                {...register('minimumFare',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern:{
                                        value: /^[0-9#]+(\.[0-9]*)?$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                    }
                                })} 
                            />

                            {errors?.minimumFare?.message ? <p className="capitalize text-sm text-danger">{errors?.minimumFare?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('delivery.charges_details.form.min_fare_placeholder')}</p>}
                        </div>

                        <div>
                            <label> {t('delivery.charges_details.form.max_fare')} <Astrisks /></label>
                            <input type="text" id="maximumFare"
                                className="placeholder:capitalize"
                                placeholder={t('delivery.charges_details.form.max_fare_placeholder')}
                                {...register('maximumFare',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern:{
                                        value: /^[0-9#]+(\.[0-9]*)?$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                    }
                                })} 
                            />

                            {errors?.maximumFare?.message ? <p className="capitalize text-sm text-danger">{errors?.maximumFare?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('delivery.charges_details.form.max_fare_placeholder')}</p>}
                        </div>

                        <div>
                            <label>{t('delivery.charges_details.form.base_rate')} <Astrisks /></label>
                            <input type="text" id="baseRatePerKm"
                                className="placeholder:capitalize"
                                placeholder={t('delivery.charges_details.form.base_rate_placeholder')}
                                {...register('baseRatePerKm',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern:{
                                        value: /^[0-9#]+(\.[0-9]*)?$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                    }
                                })} 
                            />

                            {errors?.baseRatePerKm?.message ? <p className="capitalize text-sm text-danger">{errors?.baseRatePerKm?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('delivery.charges_details.form.base_rate_placeholder')}</p>}
                        </div>

                        <div className="[&>div]:w-full [&>div]:bg-[#f3f6f9] [&>p]:mt-1">
                            <label>{t('delivery.charges_details.form.driver_share')} % <Astrisks /></label>
                            {/* <input type="text" id="driverShare"
                                placeholder="Please enter the Driver Share"
                                {...register('driverShare',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern:{
                                        value: /^[0-9#]+(\.[0-9]*)?$/,
                                        message: 'only numbers are allowed'
                                    }
                                })} 
                            /> */}

                            <FormControl variant="outlined" className="input-placeholder rounded-md [&>div>fieldset]:border-none [&>div>fieldset]:hover:outline-none [&>div>fieldset]:hover:border-none  [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1 [&>div>input]:text-sm">
                                <OutlinedInput
                                    type="text"
                                    id="driverShare"
                                    placeholder={t('delivery.charges_details.form.driver_share_placeholder')}
                                    {...register('driverShare',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value: /^[0-9#]+(\.[0-9]*)?$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                        },
                                        max : {
                                            value : 100,
                                            message : localStorage.getItem('lang') === 'ar' ? "اقصى قيمة مسموح بها 100%" : "Max Value allowed is 100%"
                                        }
                                    })} 
                                    max={100}
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    aria-describedby="driverShare-helper-text"
                                    inputProps={{
                                    'aria-label': '%',
                                    }}
                                />
                            </FormControl>


                            {errors?.driverShare?.message ? <p className="capitalize text-sm text-danger">{errors?.driverShare?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('delivery.charges_details.form.driver_share_placeholder')}</p>}
                        </div>
                    </section>

                    <section className={`form-inputs w-full mt-5 flex gap-5 justify-end items-center [&>button]:border [&>button]:w-[150px] [&>button]:py-[10px] [&>button]:capitalize [&>button]:text-sm ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        <Button type="button" onClick={resetDefault} variant="outlined" className="border-[3px] border-mainColor text-mainColor bg-transparent transition-all duration-300 hover:bg-mainColor hover:text-white">{t('common.cancel_btn')}</Button>
                        <Button type="submit" className="bg-mainColor text-white">{t('common.save_btn')}</Button>
                    </section>
                </form>
            </div>
            {delivery?.loading ? <Overlay /> : null}
        </section>
    );
}

export default ChargesDetails;