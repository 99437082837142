import OrderSummary from "./Dashboard-Components/orderSummary";
import DashboardResturantInfo from "./Dashboard-Components/resturantInfo";
import Revenue from "./Dashboard-Components/revenue";
import DashRating from "./Dashboard-Components/dashRating";
import BestSellingItems from "./Dashboard-Components/dashBestSellingItem";
import WeeklyStats from "./Dashboard-Components/dashWekklyStats";
import { useTranslation } from "react-i18next";


const ManagerDashboard = () => {
    const {t,i18n} = useTranslation()
    

    return ( 
        <div className="dashboard-analytics-wrapper py-3" dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t("dashboard.title")}</h1>
            </section>
            
            <div className="dashboard-charts flex flex-wrap lg:flex-nowrap gap-4 h-[400px] max-h-[400px]">
                <div className="revenue-wrapper h-full basis-full  xl:basis-[30%] shadow-sm capitalize">
                    <Revenue />
                </div>

                <div className="order-summary-wrapper h-full basis-full xl:basis-[40%] shadow-sm capitalize">
                    <OrderSummary />
                </div>

                <div className="dashboard-resturant-info-wrapper h-full basis-full xl:basis-[30%] shadow-sm">
                    <DashboardResturantInfo />
                </div>
            </div>

            <div className="dashboard-statistics-reviews flex flex-wrap xl:flex-nowrap gap-4 mt-5 [&>div]:py-2 [&>div]:px-3 [&>div]:rounded-md [&>div]:min-h-[500px] [&>div]:bg-white [&>div]:h-full">

                <div className="review-wrapper basis-full xl:basis-[30%] xl:max-w-[29.3%] md:max-w-full shadow-sm">
                    <DashRating />
                </div>

                <div className="best-selling-items-wrapper basis-full xl:basis-[40%] shadow-sm">
                    <BestSellingItems />
                </div>

                <div className="dashboard-weekly-sales-wrapper basis-full xl:basis-[30%] xl:max-w-[30%] shadow-sm">
                    <WeeklyStats />
                </div>
            </div>
        </div>
    );
}

export default ManagerDashboard;