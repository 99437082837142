import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getRestaurants = createAsyncThunk("restaurantsFunc", async({page,size,col,dir,searchValue}, {rejectWithValue,dispatch})=>{
    let colCheck;

    if (col) {
        colCheck = 
            (col === 'Joining Date' || col === 'تاريخ التسجيل') ? 'created_at' 
          : (col === 'Restaurant Phone' || col === 'هاتف المطعم') ? 'phone_number' 
          : (col === 'Open Time' || col === 'وقت الفتح') ? 'opening_time' 
          : (col === 'Close Time' || col === 'وقت الإغلاق') ? 'closing_time' 
          : (col === 'Order Deadline Time' || col === 'وقت اخر طلب') ? 'order_deadline' 
          : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
          : (col === 'Restaurant Name' || col === 'اسم المطعم') ? 'name' 
          : (col === 'Arabic Name' || col === 'اسم المطعم بالعربية') ? 'name_ar' 
          : (col === 'Marketing Phone' || col === 'هاتف التسويق') ? 'marketing_phone_number' 
          : (col === 'Marketing Email' || col === 'ايميل التسويق') ? 'marketing_email' 
          : (col === 'Email' || col === 'الايميل') ? 'email' 
          : (col === 'Views' || col === 'المشاهدات') ? 'views' 
          : (col === 'Status' || col === 'الحالة') ? 'status' 
          : (col === 'Min Charge' || col === 'اقل قيمة') ? 'minimum_charge' 
          : (col === 'Avg Delivery Time' || col === 'متوسط وقت التوصيل') ? 'average_delivery_time' 
          : (col === 'Avg Rating' || col === 'متوسط التقييم') ? 'average_rating' 
          : (col === 'Total Orders' || col === 'اجمالى الطلبات') ? 'total_orders' 
          : col.replace(/\s+/g, '').toLowerCase();
    }
    
    const url = !col ? `/restaurants?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/restaurants?page=${page ? page : 1}&size=${size ? size : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${searchValue ?`&search=${searchValue}`: ''}`;
    
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getRestaurantRequests = createAsyncThunk("RestaurantRequestsFunc", async({page,size,col,dir,searchValue}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    let colCheck;
    
    if (col) {
        colCheck = 
            // col === 'Joining Date' ? 'created_at' : col === 'Updated at' ? 'updated_at' : col === 'Restaurant Name' ? 'restaurant_name' : col === 'Arabic Name' ? 'name_ar' : col === 'Min Charge' ? 'minimum_charge' : col === 'Avg Delivery Time' ? 'average_delivery_time' : col === 'Avg Rating' ? 'average_rating' : col === 'Total Orders' ? 'total_orders' : col.replace(/\s+/g, '').toLowerCase();
            (col === 'Joining Date' || col === 'تاريخ التسجيل') ? 'created_at' 
          : (col === 'Restaurant Phone' || col === 'هاتف المطعم') ? 'phone_number' 
          : (col === 'Open Time' || col === 'وقت الفتح') ? 'opening_time' 
          : (col === 'Close Time' || col === 'وقت الإغلاق') ? 'closing_time' 
          : (col === 'Order Deadline Time' || col === 'وقت اخر طلب') ? 'order_deadline' 
          : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
          : (col === 'Restaurant Name' || col === 'اسم المطعم') ? 'restaurant_name' 
          : (col === 'Arabic Name' || col === 'اسم المطعم بالعربية') ? 'name_ar' 
          : (col === 'Marketing Phone' || col === 'هاتف التسويق') ? 'marketing_phone_number' 
          : (col === 'Marketing Email' || col === 'ايميل التسويق') ? 'marketing_email' 
          : (col === 'Email' || col === 'الايميل') ? 'email' 
          : (col === 'Views' || col === 'المشاهدات') ? 'views' 
          : (col === 'Status' || col === 'الحالة') ? 'status' 
          : (col === 'Min Charge' || col === 'اقل قيمة') ? 'minimum_charge' 
          : (col === 'Avg Delivery Time' || col === 'متوسط وقت التوصيل') ? 'average_delivery_time' 
          : (col === 'Avg Rating' || col === 'متوسط التقييم') ? 'average_rating' 
          : (col === 'Total Orders' || col === 'اجمالى الطلبات') ? 'total_orders' 
          : col.replace(/\s+/g, '').toLowerCase();
    }
    
    const url = !col ? `/restaurants/requests?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/restaurants/requests?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getRestaurantRejected = createAsyncThunk("RestaurantRejectedFunc", async({page,size,col,dir,searchValue}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    let colCheck;
    
    if (col) {
        // colCheck = col === 'Joining Date' ? 'created_at' : col === 'Updated at' ? 'updated_at' : col === 'Restaurant Name' ? 'name' : col === 'Arabic Name' ? 'name_ar' : col === 'Min Charge' ? 'minimum_charge' : col === 'Phone Number' ? 'phone_number' : col === 'Marketing Email' ? 'marketing_email' : col === 'Avg Delivery Time' ? 'average_delivery_time' : col === 'Avg Rating' ? 'average_rating' : col === 'Total Orders' ? 'total_orders' : col.replace(/\s+/g, '').toLowerCase();
        colCheck = 
            (col === 'Joining Date' || col === 'تاريخ التسجيل') ? 'created_at' 
          : (col === 'Restaurant Phone' || col === 'هاتف المطعم') ? 'phone_number' 
          : (col === 'Open Time' || col === 'وقت الفتح') ? 'opening_time' 
          : (col === 'Close Time' || col === 'وقت الإغلاق') ? 'closing_time' 
          : (col === 'Order Deadline Time' || col === 'وقت اخر طلب') ? 'order_deadline' 
          : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
          : (col === 'Restaurant Name' || col === 'اسم المطعم') ? 'name' 
          : (col === 'Arabic Name' || col === 'اسم المطعم بالعربية') ? 'name_ar' 
          : (col === 'Marketing Phone' || col === 'هاتف التسويق') ? 'marketing_phone_number' 
          : (col === 'Marketing Email' || col === 'ايميل التسويق') ? 'marketing_email' 
          : (col === 'Email' || col === 'الايميل') ? 'email' 
          : (col === 'Views' || col === 'المشاهدات') ? 'views' 
          : (col === 'Status' || col === 'الحالة') ? 'status' 
          : (col === 'Min Charge' || col === 'اقل قيمة') ? 'minimum_charge' 
          : (col === 'Avg Delivery Time' || col === 'متوسط وقت التوصيل') ? 'average_delivery_time' 
          : (col === 'Avg Rating' || col === 'متوسط التقييم') ? 'average_rating' 
          : (col === 'Total Orders' || col === 'اجمالى الطلبات') ? 'total_orders' 
          : col.replace(/\s+/g, '').toLowerCase();
    }
    
    const url = !col ? `/restaurants/requests/rejected?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/restaurants/requests/rejected?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getSingleRestaurants = createAsyncThunk("SinglerestaurantsFunc", async(id, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await axios.get(`/restaurants/${id}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addrestaurant = createAsyncThunk("AddrestaurantsFunc", async(values, {rejectWithValue,dispatch})=>{
    // console.log(values);
    
    // console.log(formDataJson);
    try {
        const res = await toast.promise(axios.post(`/restaurants`,values),{
            pending: 'Loading...',
            success: 'Restaurant Added Successfully 👌',
        },{toastId : "AddrestaurantToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editrestaurant = createAsyncThunk("AddrestaurantsFunc", async({formData,id}, {rejectWithValue,dispatch})=>{
    // console.log(formData,id);
    try {
        const res = await toast.promise(axios.put(`/restaurants/${id}`,formData),{
            pending: 'Loading...',
            success: 'Restaurant Updated Successfully 👌',
        },{toastId : "EditrestaurantToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getDeliveryTypes = createAsyncThunk("getDeliveryTypesFun", async(_, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await axios.get(`/delivery-types/list`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const toggleRestaurant = createAsyncThunk("toggleRestaurantsFunc", async({status,id,values}, {rejectWithValue,dispatch})=>{

    let val = {status,...values}
    // console.log(val);
    try {
        const res = await toast.promise(axios.put(`/restaurants/${id}/status`,{
            ...val
        }),
        {
            pending: 'Loading....',
            success: `Restaurant ${val?.status?.toLowerCase()} Successfully 👌`,
            // error: 'Promise rejected 🤯'
        },{toastId : "RestaurantStatusToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getRestaurantRequestById = createAsyncThunk("getRestaurantRequestByIdFunc", async(id, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await axios.get(`/restaurants/requests/${id}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    addLoading : false,
    restaurants : [],
    singleRestaurant : [],
    deliveryTypes : [],
    restaurantRequests : [],
    restaurantRejected : [],
    singleRestaurantRequest : [],
    response : '',
    error : null,
}
export const RestaurantSlice = createSlice({ 
    name: "restaurantSlice-Func", 
    initialState,
    reducers: {
        clearState : (state,action)=>{
            state.singleRestaurant = []
        }
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getRestaurants.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getRestaurants.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.restaurants = action.payload
            // console.log(action.payload);
        })

        .addCase(getRestaurants.rejected, (state,action)=>{
            state.loading = false;
            state.restaurants = ""
            state.error = action.payload
        })

        .addCase(getSingleRestaurants.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getSingleRestaurants.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.singleRestaurant = action.payload
            // console.log(action.payload);
        })

        .addCase(getSingleRestaurants.rejected, (state,action)=>{
            state.loading = false;
            state.singleRestaurant = ""
            state.error = action.payload
        })

        .addCase(addrestaurant.pending, (state,action)=>{
            state.addLoading = true;
            state.error = ""
        })

        .addCase(addrestaurant.fulfilled, (state,action)=>{
            state.addLoading = false;
            state.error = ""
            state.response = action.payload
            // console.log(action.payload);
        })

        .addCase(addrestaurant.rejected, (state,action)=>{
            state.addLoading = false;
            state.response = ""
            state.error = action.payload
        })

        .addCase(getDeliveryTypes.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getDeliveryTypes.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.deliveryTypes = action.payload
            // console.log(action.payload);
        })

        .addCase(getDeliveryTypes.rejected, (state,action)=>{
            state.loading = false;
            state.deliveryTypes = ""
            state.error = action.payload
        })

        .addCase(getRestaurantRequests.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getRestaurantRequests.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.restaurantRequests = action.payload
            // console.log(action.payload);
        })

        .addCase(getRestaurantRequests.rejected, (state,action)=>{
            state.loading = false;
            state.restaurantRequests = ""
            state.error = action.payload
        })

        .addCase(getRestaurantRejected.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getRestaurantRejected.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.restaurantRejected = action.payload
            // console.log(action.payload);
        })

        .addCase(getRestaurantRejected.rejected, (state,action)=>{
            state.loading = false;
            state.restaurantRejected = ""
            state.error = action.payload
        })
        
        .addCase(getRestaurantRequestById.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getRestaurantRequestById.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.singleRestaurantRequest = action.payload
            // console.log(action.payload);
        })

        .addCase(getRestaurantRequestById.rejected, (state,action)=>{
            state.loading = false;
            state.singleRestaurantRequest = ""
            state.error = action.payload
        })
        

    }
});

export const {clearState} = RestaurantSlice.actions

export default RestaurantSlice.reducer

