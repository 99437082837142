import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getMapKey = createAsyncThunk("get-map-key", async(_, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/google-maps`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
                if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

const initialState = {
    loading: false,
    key : localStorage.getItem('gkey') ? localStorage.getItem('gkey') : '' ,
    error : null,
}
export const Mapkey = createSlice({ 
    name: "MAP-KEY-SLICE", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getMapKey.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getMapKey.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.key = action.payload
            localStorage.setItem('gkey',action.payload?.data?.GOOGLE_MAP_KEYS)
            // console.log(action.payload?.data?.GOOGLE_MAP_KEYS);
        })

        .addCase(getMapKey.rejected, (state,action)=>{
            state.loading = false;
            state.key = ""
            state.error = action.payload
            localStorage.removeItem('gkey')
        })

    }
});

export default Mapkey.reducer

