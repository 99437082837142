import { BsTelephone } from 'react-icons/bs';
import './timeline.scss'
import { MdAlternateEmail } from 'react-icons/md';

const TicketTimeline = ({createdBy,assignedTo, t}) => {
    // console.log(createdBy);
    // console.log(assignedTo);
    return ( 
        <div className={`${localStorage.getItem('lang') === 'ar' ? 'timeline-container-ar' : 'timeline-container'}`}>
            
            <div className="time-line-event -mt-1 table table-fixed relative min-h-[110px] pb-6">
                <div className={`active`}></div>

                <div className={`timeline-text capitalize table-cell ${localStorage.getItem('lang') === 'ar' ? 'pr-4 mr-4' : 'pl-4 ml-3'}`}>
                    <p className='text-[#999999] text-[12px] mb-1'>{t('trouble_tickets.ticket_details.created_by')}</p>                    
                    <div className="flex flex-col mb-2 gap-y-1 text-[#333333]">
                        <p className='text-sm capitalize font-semibold'>{createdBy?.firstName || createdBy?.lastName ? createdBy?.firstName + " " + createdBy?.lastName : t('trouble_tickets.ticket_details.no_name')}</p>
                        
                        <div className="flex items-center gap-x-2 mt-1">
                            <span className="w-6 h-6 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto w-4 h-4 block text-white text-lg" /></span>
                            <p className="text-sm font-semibold">{createdBy?.mobileNumber ? createdBy?.mobileNumber : t('trouble_tickets.ticket_details.no_telephone')}</p>
                        </div>

                        <div className="flex items-center gap-x-2 mt-2">
                            <span className="w-6 h-6 rounded-[50%] bg-mainYellow text-center flex items-center"><MdAlternateEmail className="m-auto w-4 h-4 block text-white text-lg" /></span>
                            <p className="text-sm font-semibold">{createdBy?.email ? createdBy?.email : t('trouble_tickets.ticket_details.no_email')}</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="time-line-event -mt-1 table table-fixed relative min-h-[60px]">
                <div className={`pending`}></div>

                <div className={`timeline-text capitalize table-cell ${localStorage.getItem('lang') === 'ar' ? 'pr-4 mr-4' : 'pl-4 ml-3'}`}>
                    <p className='text-[#999999] text-[12px]'>{t('trouble_tickets.assign_modal.form.assign_to')}</p>

                    <div className="flex flex-col mb-2 gap-y-1 text-[#333333]">
                        <p className='text-sm capitalize font-semibold'>{assignedTo?.firstName || assignedTo?.lastName ? assignedTo?.firstName + " " + assignedTo?.lastName : t('trouble_tickets.ticket_details.no_name')}</p>
                        
                        <div className="flex items-center gap-x-2 mt-1">
                            <span className="w-6 h-6 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto w-4 h-4 block text-white text-lg" /></span>
                            <p className="text-sm font-semibold">{assignedTo?.mobileNumber ? assignedTo?.mobileNumber : t('trouble_tickets.ticket_details.no_telephone')}</p>
                        </div>

                        <div className="flex items-center gap-x-2 mt-2">
                            <span className="w-6 h-6 rounded-[50%] bg-mainYellow text-center flex items-center"><MdAlternateEmail className="m-auto w-4 h-4 block text-white text-lg" /></span>
                            <p className="text-sm font-semibold">{assignedTo?.email ? assignedTo?.email : t('trouble_tickets.ticket_details.no_email')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TicketTimeline;