import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../../Toast-Messages/toastMessage";

export const getDeals = createAsyncThunk("getDealsFunc", async({page,size,col,dir,searchValue}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    let colCheck;

    if (col) {
        colCheck = 
            (col === 'Created At' || col === 'تم انشاءه في') ? 'created_at' 
          : (col === 'Updated at' || col === 'تعديل في') ? 'updated_at' 
          : (col === 'Deal Name' || col === 'اسم الصفقة') ? 'name' 
          : (col === 'Arabic Name' || col === 'اسم الصفقة بالعربية') ? 'name_ar' 
          : (col === 'Price' || col === 'السعر') ? 'price_before' 
          : (col === 'Price Before' || col === 'السعر سابقا') ? 'price_before' 
          : (col === 'Calories' || col === 'الكالورى') ? 'calories' 
          : col.replace(/\s+/g, '').toLowerCase();
    }
    
    const url = !col ? `/my-restaurant/deals?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/my-restaurant/deals?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    

    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getDealById = createAsyncThunk("getDealByIdFunc", async(id, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await axios.get(`/my-restaurant/deals/${id}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addDeals = createAsyncThunk("addDealsFunc", async(values, {rejectWithValue,dispatch})=>{
    // console.log(values);
    try {
        const res = await toast.promise(axios.post(`/my-restaurant/deals`,values),{
            pending : 'loading...',
            success : 'Deal Added Successfully 👌'
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addDealV2 = createAsyncThunk("addDealV2Func", async(values, {rejectWithValue,dispatch})=>{
    // console.log(values);
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/menu-items/${values?.menuItemId}/create-deal`,values),{
            pending : 'loading...',
            success : 'Deal added/updated Successfully 👌'
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editDeal = createAsyncThunk("editDealsFunc", async({updatedData,id}, {rejectWithValue,dispatch})=>{
      
    // console.log(id);

    try {
        const res = await toast.promise(axios.put(`/my-restaurant/deals/${id}`,updatedData),{
            pending : 'loading...',
            success : 'Deal Edited Successfully 👌'
        },{toastId:'editdealsToast'})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const toggleDealActivation = createAsyncThunk("toggleDealActivationFunc", async({status,id}, {rejectWithValue,dispatch})=>{
    // console.log(id);
    try {
        const res = await toast.promise(axios.put(`/my-restaurant/deals/${status}`,{id:id}),{
            pending : 'loading...',
            success : `Deal ${status?.charAt(0).toUpperCase()+status?.slice(1)} Successfully 👌`
        },{toastId:'toggleDealActivationToast'})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const deleteDeal = createAsyncThunk("deleteDealFunc", async(id, {rejectWithValue,dispatch})=>{
    // console.log(id);
    try {
        const res = await toast.promise(axios.delete(`/my-restaurant/deals/${id}`),{
            pending : 'loading...',
            success : 'Deal Deleted Successfully 👌'
        },{toastId:'dealDeletionToast'})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    deals : [],
    dealById : null,
    error : null,
}
export const DealsSlice = createSlice({ 
    name: "DealsSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getDeals.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getDeals.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.deals = action.payload
            // console.log(action.payload);
        })

        .addCase(getDeals.rejected, (state,action)=>{
            state.loading = false;
            state.deals = []
            state.error = action.payload
        })

        .addCase(addDeals.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addDeals.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(addDeals.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(getDealById.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getDealById.fulfilled, (state,action)=>{
            state.loading = false;
            state.dealById = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getDealById.rejected, (state,action)=>{
            state.loading = false;
            state.dealById = null
            state.error = action.payload
        })

    }
});

export default DealsSlice.reducer

