import { Box, Button, Chip, FormControl, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {MdOutlineModeEditOutline} from 'react-icons/md'
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import previewIcon from '../../../Assets/imgs/previewIcon.svg'
import pdfIcon from '../../../Assets/imgs/pdfIcon.png'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { editrestaurant, getDeliveryTypes, getSingleRestaurants, toggleRestaurant } from "../../../Components/Redux/Slice/Restaurants/restaurantsSlice";
import { getTags } from "../../../Components/Redux/Slice/Tags/tags";
import { getcuisines } from "../../../Components/Redux/Slice/Cuisines/cuisines";
import { getMealsType } from "../../../Components/Redux/Slice/MealsType/mealsType";
import { addFile } from "../../../Components/Redux/Slice/Upload/file";
import Overlay from "../../../Components/Common/overlay";
import BreadCrumb from "../../../Components/BreadCrumb/breadCrumb";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Astrisks from "../../../Components/Common/astrisk";
import { useTranslation } from "react-i18next";
import { isValidIBAN } from "../../../Components/Common/ibanValidation";
import defRes from '../../../Assets/imgs/Banner.svg'
import { BiReset } from "react-icons/bi"; 
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Compressor from "compressorjs";


export function getFileType(url) {
    // Use the URL constructor to parse the URL
    const urlObj = new URL(url);
    // Get the pathname from the URL object and split it into parts
    const pathParts = urlObj.pathname.split('.');
    // Get the last part of the path, which should be the file extension
    const extension = pathParts[pathParts.length - 1];
  
    // console.log(extension);
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase())) {
      return 'image';
    } else if (extension.toLowerCase() === 'pdf' || extension.startsWith('application/pdf')) {
      return 'pdf';
    } else {
      return 'unknown';
    }
  }

const EditRestaurant = () => {
    const {t, i18n} = useTranslation()
    const [searchParams] = useSearchParams();
    const location = useLocation()
    const id = searchParams.get("id");
    // console.log(testId);
    const MySwal = withReactContent(Swal);
    const {singleRestaurant} = useSelector(state=>state?.restaurants)
    const dispatch = useDispatch()
    const [img, setimg] = useState(null);
    const [preview, setPreview] = useState(singleRestaurant?.data?.bannerImage);
    const [profilePreview,setprofilePreview] = useState(singleRestaurant?.data?.logoUrl)
    const [commericalPreview,setcommericalPreview] = useState(singleRestaurant?.data?.commercialRegisterationLetter)
    const [ibanPreview,setibanPreview] = useState(singleRestaurant?.data?.ibanLetter)
    const [vatPreview,setvatPreview] = useState(singleRestaurant?.data?.vatCertificate)
    const [isImage, setisImage] = useState(null)
    const [isBanner, setisBanner] = useState(singleRestaurant?.data?.bannerImage)
    const [allowImg,setallowImg] = useState(false)
    const [isCommerical, setisCommerical] = useState(null)
    const [isIban, setisIban] = useState(null)
    const [isVat, setisVat] = useState(null)
    const [discountType, setdiscountType] = useState(singleRestaurant?.data?.takeinSharePerOrderType || []);

    const [opentime, setopentime] = useState(singleRestaurant?.data?.openingTime || null)
    const [closetime, setclosetime] = useState(singleRestaurant?.data?.closingTime || null)
    const [ordertime, setordertime] = useState(singleRestaurant?.data?.orderDeadline || null)
    const navigate = useNavigate()

    // console.log(preview);

    const resTags = useSelector(state=>state?.tags) // all tags available
    const defTagsIDs = singleRestaurant?.data?.tags?.map((tag) => ({ id: tag.id })) //restaurant tags (default tags)

    const resCuisines = useSelector(state=>state?.cuisines) // all cuisine available
    const defCuisine = singleRestaurant?.data?.cuisines?.map((cuisine) => ({ id: cuisine.id })) //default cuisines

    const resMealsType = useSelector(state=>state?.mealsType) // all meals type available
    const defMealsType = singleRestaurant?.data?.mealTypes?.filter((meal) => meal != null).map((meal) => ({ id: meal.id })); //default meals

    const resDeliveryType = useSelector(state=>state?.restaurants?.deliveryTypes) // all delivery type available
    const defDeliveryTypes = singleRestaurant?.data?.restaurantDeliveryTypes?.filter((type) => type != null).map((type) => ({ id: type.deliveryTypeId })); //default delivery types

    useEffect(() => {
        dispatch(getTags())
        dispatch(getcuisines())
        dispatch(getMealsType())
        dispatch(getDeliveryTypes())
    }, [])    
    
    useEffect(() => {

        dispatch(getSingleRestaurants(id))
        setPreview(singleRestaurant?.data?.bannerImage)
        setprofilePreview(singleRestaurant?.data?.logoUrl)
        setcommericalPreview(singleRestaurant?.data?.commercialRegisterationLetter)
        setibanPreview(singleRestaurant?.data?.ibanLetter)
        setvatPreview(singleRestaurant?.data?.vatCertificate)
        setopentime(singleRestaurant?.data?.openingTime || null)
        setclosetime(singleRestaurant?.data?.closingTime || null)
        setordertime(singleRestaurant?.data?.orderDeadline || null)
        setdiscountType(singleRestaurant?.data?.takeinSharePerOrderType || [])
        }, [dispatch, location.pathname, id, singleRestaurant?.data?.bannerImage, singleRestaurant?.data?.closingTime, singleRestaurant?.data?.commercialRegisterationLetter, singleRestaurant?.data?.ibanLetter, singleRestaurant?.data?.logoUrl, singleRestaurant?.data?.openingTime, singleRestaurant?.data?.orderDeadline])
    
    const defaultValues = {
            name: singleRestaurant?.data?.name,
            phoneNumber: singleRestaurant?.data?.phoneNumber,
            hotline: singleRestaurant?.data?.hotline,
            email: singleRestaurant?.data?.email,
            minimumCharge: singleRestaurant?.data?.minimumCharge,
            tags : defTagsIDs,
            cuisines : defCuisine,
            mealTypes : defMealsType,
            bannerImage : singleRestaurant?.data?.bannerImage,
            logoUrl : singleRestaurant?.data?.logoUrl,
            openingTime : singleRestaurant?.data?.openingTime,
            closingTime : singleRestaurant?.data?.closingTime,
            orderDeadline : singleRestaurant?.data?.orderDeadline
        }
    const form = useForm({
        defaultValues: defaultValues,
    })
    const {register,control,handleSubmit,formState,setValue,getValues,watch,trigger} = form
    const {errors} = formState

    const maxSize = 1024*1024*5
    const compressionLimit = 55
    const acceptedImageTypes = ['image/jpeg', 'image/png'];
    const acceptedFiles = ['image/jpeg', 'image/png','application/pdf'];

    const [loadImgCompress,setloadImgCompress] = useState(false)

    const handleProfileImageChange = (e)=> {
        const file = e.target.files[0];
        
        if(file){
            // setValue('logo',e.target.files[0])
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الصورة يجب ان تكون (jbg, jbeg, png) فقط' : 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                if((file.size / 1024) > compressionLimit){
                    setloadImgCompress(true)
                    new Compressor(file, {
                        quality: 0.6,
                        maxWidth : 600,
                        maxHeight : 600,


                        success: (compressedResult) => {
                            setloadImgCompress(false)
                            setisImage(compressedResult)                            
                        },
                    });
                } else {
                    setisImage(file)
                }
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    // setValue('logo', reader.result);
                    setprofilePreview(reader.result);
                }
            }
        }
    }

    const [loadBannerCompress,setloadBannerCompress] = useState(false)

    const handleBannerImageChange = (e)=> {
        const file = e.target.files[0];

        if(file){
            // setValue('bannerImage',e.target.files[0])            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الصورة يجب ان تكون (jbg, jbeg, png) فقط' : 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setloadBannerCompress(true)
                new Compressor(file, {
                    quality: 0.4,
                    maxWidth : 400,
                    maxHeight : 400,


                    success: (compressedResult) => {
                        setloadBannerCompress(false)
                        setisBanner(compressedResult)
                        // console.log('compressor',((compressedResult?.size) / (1024 * 1024))?.toFixed(5));
                    },
                });
                setallowImg(true)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    // setValue('bannerImage', reader.result);
                    setPreview(reader.result);
                }
            }
        }
    }

    const handleBannerRest = ()=>{
        setisBanner(null)
        setPreview(null)
        setallowImg(false)
    }

    const handleCommericalLetterChange = (e)=> {
        const file = e.target.files[0];
        if(file){
            // setValue('bannerImage',e.target.files[0])            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedFiles.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الملف يجب ان تكون (jbg, jbeg, png, pdf) فقط' : 'File must be in type of (jpg, jpeg, png, pdf) only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setisCommerical(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    // setValue('bannerImage', reader.result);
                    setcommericalPreview(reader.result);
                }
            }
        }
    }

    const handleIbanLetterChange = (e)=> {
        const file = e.target.files[0];

        if(file){
            // setValue('bannerImage',e.target.files[0])            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedFiles.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الملف يجب ان تكون (jbg, jbeg, png, pdf) فقط' : 'File must be in type of (jpg, jpeg, png, pdf) only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setisIban(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    // setValue('bannerImage', reader.result);
                    setibanPreview(reader.result);
                }
            }
        }
    }

    const handleVatLetterChange = (e)=> {
        const file = e.target.files[0];

        if(file){
            // setValue('bannerImage',e.target.files[0])            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedFiles.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الملف يجب ان تكون (jbg, jbeg, png, pdf) فقط' : 'File must be in type of (jpg, jpeg, png, pdf) only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setisVat(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    // setValue('bannerImage', reader.result);
                    setvatPreview(reader.result);
                }
            }
        }
    }

    useEffect(() => {
        // console.log(img);       
    }, [img])

    const [shareType,setshareType] = useState('')

    const handleOfferTypeSelectChange = (event) => {
        const { target: { value },} = event;
        setdiscountType(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
        setValue('takeinSharePerOrderType', value,{shouldValidate:true})
        setshareType(value)
    }; 

    // console.log(singleRestaurant?.data);
    // console.log(getValues());
    useEffect(() => {

        // setValue('firstName', singleRestaurant?.data?.firstName); 
        // setValue('lastName', singleRestaurant?.data?.lastName); 
        // setValue('personalEmail', singleRestaurant?.data?.personalEmail); 
        // setValue('personalPhoneNumber', singleRestaurant?.data?.personalPhoneNumber); 
        // setValue('nationalId', singleRestaurant?.data?.nationalId);
        setValue('name', singleRestaurant?.data?.name); 
        setValue('nameAr', singleRestaurant?.data?.nameAr); 
        setValue('phoneNumber', singleRestaurant?.data?.phoneNumber);
        // setValue('hotline', singleRestaurant?.data?.hotline);
        setValue('email', singleRestaurant?.data?.email);
        setValue('marketingPhoneNumber', singleRestaurant?.data?.marketingPhoneNumber);
        setValue('marketingEmail', singleRestaurant?.data?.marketingEmail);
        setValue('bankName', singleRestaurant?.data?.bankName);
        setValue('bankAccountNumber', singleRestaurant?.data?.bankAccountNumber);
        setValue('commercialRegisterationNumber', singleRestaurant?.data?.commercialRegisterationNumber);
        setValue('vatNumber', singleRestaurant?.data?.vatNumber);
        // setValue('minimumCharge', singleRestaurant?.data?.minimumCharge);
        setValue('tags', defTagsIDs || []);
        setValue('cuisines', defCuisine || []);
        setValue('mealTypes', defMealsType || []);
        // setValue('deliveryTypes', defDeliveryTypes || []);
        setValue('bannerImage', singleRestaurant?.data?.bannerImage);
        setValue('logoUrl', singleRestaurant?.data?.logoUrl);
        setValue('commercialRegisterationLetter', singleRestaurant?.data?.commercialRegisterationLetter);
        setValue('ibanLetter', singleRestaurant?.data?.ibanLetter);
        setValue('takeinSharePerOrder', singleRestaurant?.data?.takeinSharePerOrder);
        setValue('takeinSharePerOrderType', singleRestaurant?.data?.takeinSharePerOrderType);
        // setValue('acceptsCurbsidePickup', singleRestaurant?.data?.acceptsCurbsidePickup.toString());
        // setValue('acceptsDelivery', singleRestaurant?.data?.acceptsDelivery.toString());
        // setValue('acceptsOnlinePayment', singleRestaurant?.data?.acceptsOnlinePayment.toString());
        // setValue('acceptsPromocode', singleRestaurant?.data?.acceptsPromocode.toString());
        // setValue('alwaysOpen', singleRestaurant?.data?.alwaysOpen.toString());

        setValue('openingTime', singleRestaurant?.data?.openingTime);
        setValue('closingTime', singleRestaurant?.data?.closingTime);
        setValue('orderDeadline', singleRestaurant?.data?.orderDeadline);
    }, [setValue,singleRestaurant]);

    const handleImageChange = (e)=> {
        const file = e.target.files[0];
        if(file){
            setimg(e.target.files[0])
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = ()=>{
                setValue('image', reader.result);
                setPreview(reader.result);
            }
        }
    }
    
    // handle tags select

    const [openTag, setOpenTag] = useState(false);

    const handleCloseTag = () => {
        setOpenTag(false);
    };
    const handleOpenTag = () => {
        setOpenTag(true);
    };

    const defaultTagIDs = defTagsIDs?.map(tag => tag.id); // return array of the default ids while defTagsIDS return array of object with the ids
    const defaultTagsObjects = resTags?.tags?.data?.filter(tag => defaultTagIDs?.includes(tag.id)); //filter the default tags with all tags from api with name and id
    
    // const defaultTagNames = defaultTags?.map(tag => tag.name);

    const tagsList = resTags?.tags?.data?.map( item=> item )

    const [tags, settags] = useState(tagsList?.filter((e) =>
    defaultTagsObjects?.some((item) => e.id === item.id))) // used to handle fetched 

    useEffect(() => {
        settags(tagsList?.filter((e) =>
            defaultTagsObjects?.some((item) => e.id === item.id)
        ))
    }, [])

    const handleTagsChipSelectChange = (event) => {
        const { target: { value }, } = event;
        const selectedValues = typeof value === 'string' ? value.split(',') : value;
        
       if(selectedValues){
        settags(
            tagsList.filter((e) =>
            selectedValues.some((item) => e.id === item?.id)
            )
        );
       }
    };

    const [isComponentMounted, setIsComponentMounted] = useState(false);

    useEffect(() => {
        if ((defaultTagsObjects?.length && !isComponentMounted)) {
        setIsComponentMounted(true);
        settags(defaultTagsObjects);
        // setcuisines(defaultCuisines)
        }
    }, [defaultTagsObjects, isComponentMounted,singleRestaurant]);
    

    useEffect(() => {
        // console.log(getValues())
        // console.log(tags);
        // console.log(defTagsDetails);
    }, [getValues, tags, tagsList]);


    // handle cuisines select

    const [openCuisine, setopenCuisine] = useState(false);

    const handleCloseCuisine = () => {
        setopenCuisine(false);
    };
    const handleOpenCuisine = () => {
        setopenCuisine(true);
    };

    const defaultCuisineIDs = defCuisine?.map(cuisine => cuisine.id);
    const defaultCuisines = resCuisines?.cuisines?.data?.filter(cuisine => defaultCuisineIDs?.includes(cuisine.id));

    const cuisinesList = resCuisines?.cuisines?.data?.map( item=> item )

    
    const [cuisines, setcuisines] = useState(cuisinesList?.filter((e) =>
    defaultCuisines?.some((item) => e.id === item.id)));


    const handleCuisinesChipSelectChange = (event) => {
        const { target: { value }, } = event;
        const selectedValues = typeof value === 'string' ? value.split(',') : value;
        
        if(selectedValues){
            setcuisines(
                cuisinesList.filter((e) =>
                selectedValues.some((item) => e.id === item?.id)
                )
            );
        }
    };

    useEffect(() => {
        if ((defaultCuisines?.length && !isComponentMounted)) {
        setIsComponentMounted(true);
        setcuisines(defaultCuisines);
        }
    }, [defaultCuisines, isComponentMounted,singleRestaurant]);


    // handle MealsType select

    const [openMeals, setopenMeals] = useState(false);

    const handleCloseMeals = () => {
        setopenMeals(false);
    };
    const handleOpenMeals = () => {
        setopenMeals(true);
    };

    const defaultMealsIDs = defMealsType?.map(meal => meal.id);
    const defaultMeals = resMealsType?.mealsType?.data?.filter(meal => defaultMealsIDs?.includes(meal.id));

    const mealsList = resMealsType?.mealsType?.data?.map( item=> item )

    const [meals, setmeals] = useState(mealsList?.filter((e) =>
    defaultMeals?.some((item) => e.id === item.id))); // used to handle fetched 


    // console.log(mealsList);
    
    
    const handleMealsChipSelectChange = (event) => {
        const { target: { value }, } = event;
        const selectedValues = typeof value === 'string' ? value.split(',') : value;
        
        if(selectedValues){
            setmeals(
                mealsList.filter((e) =>
                selectedValues.some((item) => e.id === item?.id)
                )
            );
        }
    };

    useEffect(() => {
        if ((defaultMeals?.length && !isComponentMounted)) {
        setIsComponentMounted(true);
        setmeals(defaultMeals);
        }
    }, [defaultMeals, isComponentMounted,singleRestaurant]);

    // console.log(resDeliveryType);

    // handle Delivery Type select
    const defaultDeliveryID = defDeliveryTypes?.map(delivery => delivery.id);
    const defaultDeliveryType = resDeliveryType?.data?.filter(delivery => defaultDeliveryID?.includes(delivery.id));

    const deliveryList = resDeliveryType?.data?.map( item=> item )

    
    const [deliveryType, setdeliveryType] = useState(deliveryList?.filter((e) =>
    defaultDeliveryType?.some((item) => e.id === item.id)));


    const handleDeliveryTypeChipSelectChange = (event) => {
        const { target: { value }, } = event;
        const selectedValues = typeof value === 'string' ? value.split(',') : value;
        
        setdeliveryType(
            deliveryList.filter((e) =>
            selectedValues.some((item) => e.id === item?.id)
            )
        );
    };


    useEffect(() => {
        if ((defaultDeliveryType?.length && !isComponentMounted)) {
        setIsComponentMounted(true);
        setdeliveryType(defaultDeliveryType);
        }
    }, [defaultDeliveryType, isComponentMounted,singleRestaurant]);


    const {restaurants} = useSelector(state=>state)

    // const id = id

    const [userData, setuserData] = useState({})
    
    const submitHandler = async (values)=>{
        const tagsJsonString = tags?.map(item=>( {id:item?.id} ))
        const cuisineJsonString = cuisines?.map(item=>( {id:item?.id} ))
        const mealJsonString = meals?.map(item=>( {id:item?.id} ))
        const deliveryTypeJsonString = deliveryType?.map(item=>( {id:item?.id} ))

        let updatedData

        let ImageResponse = null,
            bannerResponse = null,
            commericalResponse = null,
            ibanResponse = null,
            vatResponse = null

        const image = new FormData()
        image.append('file',isImage)

        const banner = new FormData()
        banner.append('file',isBanner)

        const commerical = new FormData()
        commerical.append('file',isCommerical)

        const iban = new FormData()
        iban.append('file',isIban)

        const vat = new FormData()
        vat.append('file',isVat)

        if(isImage !== null){
            ImageResponse = await dispatch(addFile(image));
        }

        if(isBanner !== null && allowImg === true){
            bannerResponse = await dispatch(addFile(banner))
        }

        if(isCommerical !== null){
            commericalResponse = await dispatch(addFile(commerical))
        }

        if(isIban !== null){
            ibanResponse = await dispatch(addFile(iban))
        }

        if(isVat !== null){
            vatResponse = await dispatch(addFile(vat))
            
        }
        updatedData = {
            ...values, // include all other form data
            logoUrl : ImageResponse?.payload?.data?.url ? ImageResponse?.payload?.data?.url : singleRestaurant?.data?.logoUrl,
            // bannerImage : bannerResponse?.payload?.data ? bannerResponse?.payload?.data?.url : singleRestaurant?.data?.bannerImage,
            bannerImage : bannerResponse?.payload?.data ? bannerResponse?.payload?.data?.url : isBanner === null ? null : singleRestaurant?.data?.bannerImage,
            commercialRegisterationLetter : commericalResponse?.payload?.data?.url ? commericalResponse?.payload?.data?.url : singleRestaurant?.data?.commercialRegisterationLetter,
            ibanLetter : ibanResponse?.payload?.data?.url ? ibanResponse?.payload?.data?.url : singleRestaurant?.data?.ibanLetter,
            vatCertificate : vatResponse?.payload?.data?.url ? vatResponse?.payload?.data?.url : singleRestaurant?.data?.vatCertificate,
            tags: tagsJsonString, // convert tags array to JSON string
            cuisines: cuisineJsonString, // convert cuisine array to JSON string
            mealTypes: mealJsonString, // convert meals array to JSON string,
            deliveryTypes : deliveryTypeJsonString, // convert delivery types array to JSON string,
            
        }

        const keysToUpdate = [
            "acceptsCurbsidePickup",
            "acceptsDelivery",
            "acceptsOnlinePayment",
            "acceptsPromocode",
            "alwaysOpen"
        ];
            
        keysToUpdate.forEach((key) => {
            if (values.hasOwnProperty(key)) {
              updatedData[key] = values[key] === "true"; // Convert the string value to boolean
            }
        });


        // console.log(updatedData);

        const formData = {
            ...updatedData, // include all other form data
        };

        
        try { 
            await dispatch(editrestaurant({formData,id})).then( (e)=>{
                if(e?.payload?.message === 'SUCCESS'){
                    navigate('/dashboard/restaurant')
                    dispatch(getSingleRestaurants(id))
                }
            } )
        } catch (error) {
            setuserData(updatedData);
        }
        // .then((e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/restaurant') : null)
    }

    const handleError = () => {
        // Restore the form values to the user input before the error
        Object.keys(userData).forEach((key) => {
            setValue(key, userData[key]);            
        });
    };

    const {file} = useSelector(state=>state)

    // console.log(restaurants?.addLoading);

    // breadCrumb paths
    const paths = [
        {path:'/dashboard/restaurant',name:'restaurants', nameAr:"المطاعم"},
        {path:'',name:'edit restaurant', nameAr : "تعديل المطعم"},
    ]

    const toggleRestaurantHandler = async (status)=>{
        dispatch(toggleRestaurant({status,id}))
        .then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                // navigate('/dashboard/restaurant')
                dispatch(getSingleRestaurants(id))
            }
        } )
    }

    useEffect(() => {
        // console.log(getValues());
        // console.log(meals);
        // console.log(cuisines);
    }, [meals, profilePreview,cuisines, preview, errors, setValue, getValues])

    // useEffect(() => {
    //     // console.log(opentime);
    //     // let openingTime = opentime ? opentime?.$H + ":" + opentime?.$m + ":" + opentime?.$s : null
    //     let openingTime = opentime ? new Date(opentime) : null
    //     let closingTime = closetime ? new Date(closetime) : null
    //     let orderTime = ordertime ? new Date(ordertime) : null
    //     setValue('openingTime',openingTime?.toTimeString()?.slice(0,8))
    //     setValue('closingTime',closingTime?.toTimeString()?.slice(0,8))
    //     setValue('orderDeadline',orderTime?.toTimeString()?.slice(0,8))

    // }, [opentime,closetime,ordertime,setValue])

    useEffect(() => {
        let openingTime = new Date(opentime)
        setValue('openingTime',openingTime?.toTimeString()?.slice(0,8))
    }, [opentime, setValue])
    
    useEffect(() => {
        let closingTime = new Date(closetime)
        setValue('closingTime',closingTime?.toTimeString()?.slice(0,8))
    }, [closetime, setValue])
    
    useEffect(() => {
        let orderTime = new Date(ordertime)
        setValue('orderDeadline',orderTime?.toTimeString()?.slice(0,8))
    }, [ordertime, setValue])

    const auth = useSelector(state=>state.auth)

    // console.log(opentime);

    return ( 
        <article className="profile-wrapper mt-6">
            <div className=" relative flex flex-wrap  justify-between gap-4 items-start [&>section]:basis-full [&>section]:rounded-md mb-32">
                <section className="profile-left-section bg-white p-5">
                    <div className="profile-title text-[#333333] font-semibold capitalize text-xl">
                        <h2 className="font-playfair">{t('restaurants.form.edit_title')}</h2>
                    </div>

                    <div><BreadCrumb paths={paths} seperator='/' /></div>
                    
                    <form className="profile-image-wrapper mt-5" onSubmit={handleSubmit(submitHandler,handleError)} noValidate>

                        <div className="restaurant-preview-wrappe w-full h-[250px] relative">
                            <div className="defaultImage w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,.3)] flex items-center justify-center">
                                <img src={previewIcon} alt="default" className="w-[150px] h-[150px] m-auto object-cover" />
                            </div>
                            
                            <div className="banner-preview-wrapper w-full h-full relative">
                                <div className="absolute top-0 right-0 cursor-pointer w-full h-full">
                                    <div className="w-full h-full ml-auto relative cursor-pointer">
                                        <input type="file" id="file" disabled={auth?.role !== 'SUPER_ADMIN'} accept="image/*" onChange={handleBannerImageChange} className="w-full h-full absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0 z-10 opacity-1" />
                                        {/* <span className="block absolute right-0 text-sm bg-[rgba(22,22,22,0.38)] p-1 text-white cursor-pointer capitalize w-full h-full rounded-md text-center">edit banner image</span> */}
                                        {auth?.role !== 'SUPER_ADMIN' ? null : 
                                            <>
                                                <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span>
                                                <span onClick={handleBannerRest} className="block absolute bottom-0 right-0 text-2xl bg-[#fff] bg-opacity-30 p-1 text-mainRed cursor-pointer z-[99]"><BiReset /></span>
                                            </>

                                        }

                                    </div>
                                </div>
                                <img src={preview ? preview : defRes} alt="Preview" className="w-full h-full object-cover" />
                                
                                {loadBannerCompress ? <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,.4)] text-white flex items-center justify-center">
                                    <p className="w-fit animate-spin text-3xl"><AiOutlineLoading3Quarters /></p>
                                </div> : null}
                            </div>

                            <div className="restaurant-profile-image-preview absolute -bottom-[33%] w-[150px] h-[150px] bg-[rgba(0,0,0,.3)] left-6 rounded-lg z-50">
                                <div className="defaultImage w-full h-full z-10 absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center rounded-lg">
                                    <img src={previewIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                                </div>
                                <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full">
                                    <div className="absolute top-0 right-0 w-full h-full rounded-lg ">
                                        <input type="file" id="file" disabled={auth?.role !== 'SUPER_ADMIN'} accept="image/*" onChange={handleProfileImageChange} className="absolute right-0 cursor-pointer opacity-1 z-10 opacity-0 w-full h-full" />
                                        {auth?.role !== 'SUPER_ADMIN' ? null : <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span> }
                                    </div>
                                    {profilePreview && <img src={profilePreview} alt="restaurant Profile" className="w-full h-full rounded-lg object-cover" />}
                                    
                                    {loadImgCompress ? <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,.4)] text-white flex items-center justify-center">
                                    <p className="w-fit animate-spin text-3xl"><AiOutlineLoading3Quarters /></p>
                                </div> : null}
                                </div>
                            </div>

                        </div>

                        <div className="form-input-wrapper mt-24 grid grid-cols-1 md:grid-cols-2 gap-8 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">

                            {/* <div className="col-span-2 text-lg font-semibold">
                                <h2>Personal Information</h2>
                            </div>

                            <div>
                                <label>first name *</label>
                                <input type="text" id="firstName"
                                    {...register('firstName',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 3,
                                            message : 'min length is 3 characters'
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : 'max length is 50 characters'
                                        }
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.firstName?.message ? <p className="capitalize text-sm text-danger">{errors?.firstName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter first name</p>}
                            </div>

                            <div>
                                <label>last name *</label>
                                <input type="text" id="lastName"
                                    {...register('lastName',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 3,
                                            message : 'min length is 3 characters'
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : 'max length is 50 characters'
                                        }
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.lastName?.message ? <p className="capitalize text-sm text-danger">{errors?.lastName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter last name</p>}
                            </div>

                            <div>
                                <label>personal email *</label>
                                <input type="text" id="personalEmail"
                                    {...register('personalEmail',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 3,
                                            message : 'min length is 3 characters'
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : 'max length is 50 characters'
                                        }
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.personalEmail?.message ? <p className="capitalize text-sm text-danger">{errors?.personalEmail?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter personal email</p>}
                            </div>

                            <div>
                                <label>personal phone *</label>
                                <input type="text" id="personalPhoneNumber"
                                    {...register('personalPhoneNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 3,
                                            message : 'min length is 3 characters'
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : 'max length is 50 characters'
                                        }
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.personalPhoneNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.personalPhoneNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter personal phone</p>}
                            </div>

                            <div className="col-span-2">
                                <label>national ID *</label>
                                <input type="text" id="nationalId"
                                    {...register('nationalId',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 3,
                                            message : 'min length is 3 characters'
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : 'max length is 50 characters'
                                        }
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.nationalId?.message ? <p className="capitalize text-sm text-danger">{errors?.nationalId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter national ID</p>}
                            </div> */}

                            {/* <div className="col-span-2 text-lg font-semibold">
                                <h2>Restaurant Information</h2>
                            </div> */}

                            <div className="col-span-2 text-lg font-semibold">
                                <h2 className="text-center"><span className="text-mainColor block w-full text-center m-auto relative after:block after:h-1 after:bg-mainColor after:absolute after:w-[30%] after:left-[25%]  after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2  before:block before:h-1 before:bg-mainColor before:absolute before:w-[30%] before:left-[75%]  before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2">{t('restaurants.form.restaurant_info')}</span></h2>
                            </div>

                            <div>
                                <label>{t('restaurants.form.name_en')} <Astrisks /></label>
                                <input type="text" id="name"
                                    {...register('name',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value: /^[a-zA-Z+\s#&-]{1,50}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزية والارقام والمسافات وعلامه (+, &, #) فقط' : 'Only English letters, numbers, (+, &, #) or spaces allowed'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : t('common.min_length',{length : '3'})
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : t('common.max_length',{length : '50'})
                                        },
                                    })}
                                    minLength={3}
                                    maxLength={50}

                                    readOnly = {auth?.role !== 'SUPER_ADMIN'}
                                    defaultValue={singleRestaurant?.data?.name}
                                />

                                {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.name_en_helper')}</p>}
                            </div>

                            <div className="[&>input]:w-full basis-full">
                                <label>{t('restaurants.form.name_ar')} <Astrisks /></label>
                                <input type="text" id="nameAr"
                                    {...register('nameAr',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern: {
                                            value: /^[\u0621-\u064A\s&0-9]+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والارقام والمسافات وعلامه & فقط' : 'Only Arabic letters, numbers, spaces, & sign are allowed'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : t('common.min_length',{length : '3'})
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : t('common.max_length',{length : '50'})
                                        },
                                    })}

                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.name_ar_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.manage_phone')} <Astrisks /></label>
                                <input type="text" id="phoneNumber"
                                    {...register('phoneNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 10,
                                            message : t('common.min_length',{length : '10'})
                                        },
                                        maxLength : {
                                            value : 16,
                                            message : t('common.max_length',{length : '16'})
                                        },                           
                                        pattern:{
                                            value: /^(\+)?\d+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف بالارقام وعلامه + بالبداية فقط' :  'Only numbers and (+) sign in the beginning allowed'
                                        }
                                    })} 
                                    minLength={11}
                                    maxLength={16}
                                    readOnly = {auth?.role !== 'SUPER_ADMIN'}
                                    defaultValue={singleRestaurant?.data?.phoneNumber}
                                />

                                {errors?.phoneNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.phoneNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.manage_phone_helper')}</p>}
                            </div>

                            {/* <div>
                                <label>hotline</label>
                                <input type="text" id="hotline"
                                    {...register('hotline',{
                                        // required:{
                                        //     value : true,
                                        //     message : t('common.required')
                                        // },
                                        minLength : {
                                            value : 5,
                                            message : 'min length is 5 digits'
                                        },
                                        maxLength : {
                                            value : 7,
                                            message : 'max length is 7 digits'
                                        },                                   
                                        pattern:{
                                            value: /^(\+)?\d+$/,
                                            message: 'Only numbers and (+) sign the beginning allowed'
                                        }
                                    })} 
                                    minLength={5}
                                    maxLength={7}
                                    readOnly = {auth?.role !== 'SUPER_ADMIN'}
                                    defaultValue={singleRestaurant?.data?.hotline}
                                />

                                {errors?.hotline?.message ? <p className="capitalize text-sm text-danger">{errors?.hotline?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter restaurant hotline</p>}
                            </div> */}

                            <div>
                                <label>{t('restaurants.form.manage_email')} <Astrisks /></label>
                                <input type="email" id="email"
                                    {...register('email',{
                                        pattern:{
                                            value : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'خطا بصيغه الايميل' : 'Wrong Email Format'
                                        },
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        maxLength : {
                                            value : 60,
                                            message : t('common.max_length',{length : '60'})
                                        },  
                                    })} 
                                    maxLength={60}
                                    readOnly = {auth?.role !== 'SUPER_ADMIN'}
                                />

                                {errors?.email?.message ? <p className="text-sm text-danger">{errors?.email?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.manage_email_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.markerting_phone')} <Astrisks /></label>
                                <input type="text" id="marketingPhoneNumber"
                                    {...register('marketingPhoneNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 10,
                                            message : t('common.min_length',{length : '10'})
                                        },
                                        maxLength : {
                                            value : 16,
                                            message : t('common.max_length',{length : '16'})
                                        },                           
                                        pattern:{
                                            value: /^(\+)?\d+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف بالارقام وعلامه + بالبداية فقط' :  'Only numbers and (+) sign in the beginning allowed'
                                        }
                                    })}
                                    minLength={11}
                                    maxLength={16}
                                />

                                {errors?.marketingPhoneNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.marketingPhoneNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.markerting_phone_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.markerting_email')} <Astrisks /></label>
                                <input type="email" id="marketingEmail"
                                    {...register('marketingEmail',{
                                        pattern:{
                                            value : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'خطا بصيغه الايميل' : 'Wrong Email Format'
                                        },
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        maxLength : {
                                            value : 60,
                                            message : t('common.max_length',{length : '60'})
                                        },
                                    })}
                                    maxLength={60}
                                />

                                {errors?.marketingEmail?.message ? <p className="text-sm text-danger">{errors?.marketingEmail?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.markerting_email_helper')}</p>}
                            </div>   


                            <div>
                                <label>{t('restaurants.form.commerical_no')} <Astrisks /></label>
                                <input type="text" id="commercialRegisterationNumber"
                                    {...register('commercialRegisterationNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value: /^[0-9#]{1,10}$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                        },
                                        minLength : {
                                            value : 10,
                                            message : t('common.min_length',{length : '10'})
                                        },
                                        maxLength : {
                                            value : 10,
                                            message : t('common.max_length',{length : '10'})
                                        }, 
                                    })} 
                                    minLength={10}
                                    maxLength={10}
                                />

                                {errors?.commercialRegisterationNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.commercialRegisterationNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.commerical_no_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.vat')} <Astrisks /></label>
                                <input type="text" id="vatNumber"
                                    {...register('vatNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        // pattern:{
                                        //     value: /^[0-9#]{1,10}$/,
                                        //     message: 'Only Digits Allowed'
                                        // },
                                    })} 
                                    // minLength={10}
                                    // maxLength={10}
                                />

                                {errors?.vatNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.vatNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.vat_helper')}</p>}
                            </div>

                            <div className="col-span-2 flex gap-x-1 justify-between items-center [&>div]:basis-[33%] [&>div>label]:font-semibold [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:mb-2 [&>div>label]:block [&>div>label]:capitalize">
                                <div className="openTime [&>div]:bg-[#f3f6f9] [&>div]:focus-within:outline-none [&>div]:w-full">
                                    <label>{t('restaurants.form.open_time')} <Astrisks /></label>
                                    <Controller
                                        name={'openingTime'}
                                        control={control}
                                        // defaultValue={def} 
                                        rules={{required:t('common.required')}}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                views={["hours", "minutes"]}
                                                // label="Opening Time"
                                                value={value ? dayjs(value, "HH:mm") : null}
                                                sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                                onChange={(newValue) => {
                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                    // console.log(timeString);
                                                    onChange(timeString);
                                                }}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                />
                                                )}
                                            />
                                            </LocalizationProvider>
                                        )}
                                    />

                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker views={['hours', 'minutes']}
                                            sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                            {...register('openingTime',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                            })}
                                            value={opentime || { hour: 0, minute: 0 }}
                                            onChange={(e)=>{
                                                setopentime(e)
                                                // setValue('','',{shouldDirty:true})
                                            }}
                                            name='openingTime'
                                            className="w-full"
                                            id='openingTime'
                                            
                                        />
                                    </LocalizationProvider> */}

                                    {errors?.openingTime?.message ? <p className="capitalize text-sm text-danger">{errors?.openingTime?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.open_time_helper')}</p>}
                                </div>

                                <div className="[&>div]:bg-[#f3f6f9] [&>div]:focus-within:outline-none [&>div]:w-full">
                                    <label>{t('restaurants.form.close_time')} <Astrisks /></label>
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker views={['hours', 'minutes']}
                                            sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                            // label="Closing Time"
                                            {...register('closingTime',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                            })}
                                            value={closetime || { hour: 0, minute: 0}}
                                            onChange={(e)=>{
                                                setclosetime(e)
                                                // setValue('','',{shouldDirty:true})
                                            }}
                                            name='closingTime'
                                            className="w-full"
                                            id='closingTime'
                                        />
                                    </LocalizationProvider> */}

                                    <Controller
                                        name={'closingTime'}
                                        control={control}
                                        // defaultValue={def} 
                                        rules={{required:t('common.required')}}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                views={["hours", "minutes"]}
                                                // label="Opening Time"
                                                value={value ? dayjs(value, "HH:mm") : null}
                                                sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                                onChange={(newValue) => {
                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                    // console.log(timeString);
                                                    onChange(timeString);
                                                }}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                />
                                                )}
                                            />
                                            </LocalizationProvider>
                                        )}
                                    />

                                    {errors?.closingTime?.message ? <p className="capitalize text-sm text-danger">{errors?.closingTime?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.close_time_helper')}</p>}
                                </div>

                                <div className="[&>div]:bg-[#f3f6f9] [&>div]:focus-within:outline-none [&>div]:w-full">
                                    <label>{t('restaurants.form.last_order_time')} <Astrisks /></label>
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker views={['hours', 'minutes']}
                                            // label="Last Order Time"
                                            sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                            {...register('orderDeadline',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                            })}
                                            value={ordertime || { hour: 0, minute: 0 }}
                                            onChange={(e)=>{
                                                setordertime(e)
                                                // setValue('','',{shouldDirty:true})
                                            }}
                                            name='orderDeadline'
                                            className="w-full"
                                            id='orderDeadline'
                                        />
                                    </LocalizationProvider> */}

                                    <Controller
                                        name={'orderDeadline'}
                                        control={control}
                                        // defaultValue={def} 
                                        rules={{required:t('common.required')}}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                views={["hours", "minutes"]}
                                                // label="Opening Time"
                                                value={value ? dayjs(value, "HH:mm") : null}
                                                sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                                onChange={(newValue) => {
                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                    // console.log(timeString);
                                                    onChange(timeString);
                                                }}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                />
                                                )}
                                            />
                                            </LocalizationProvider>
                                        )}
                                    />

                                    {errors?.orderDeadline?.message ? <p className="capitalize text-sm text-danger">{errors?.orderDeadline?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.last_order_time_helper')}</p>}
                                </div>
                            </div>
                            
                            {/* <div>
                                <label>minimum charge</label>
                                <input type="text" id="minimumCharge"
                                    {...register('minimumCharge',{
                                        // required:{
                                        //     value : true,
                                        //     message : t('common.required')
                                        // },
                                        minLength : {
                                            value : 1,
                                            message : 'Min length is 1 digits'
                                        },
                                        maxLength : {
                                            value : 3,
                                            message : 'Max length is 3 digits'
                                        },
                                        max:{
                                            value:300,
                                            message : 'Max value is 300'
                                        },                           
                                        pattern:{
                                            value: /^\d+$/,
                                            message: 'Only numbers are allowed'
                                        }
                                    })} 
                                    min={0}
                                    max={300}
                                    minLength={1}
                                    maxLength={3}
                                    readOnly = {auth?.role !== 'SUPER_ADMIN'}
                                    defaultValue={singleRestaurant?.data?.minimumCharge}
                                />

                                {errors?.minimumCharge?.message ? <p className="capitalize text-sm text-danger">{errors?.minimumCharge?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter minimum charge</p>}
                            </div> */}

                            {/* <div>
                                <label htmlFor="select">Always Open <Astrisks /></label>
                                <Controller
                                    name="alwaysOpen"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} disabled={auth?.role !== 'SUPER_ADMIN'} value={value}  className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={'true'}>yes</option>
                                            <option value={'false'}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div> */}

                            {/* <div>
                                <label htmlFor="selectCurbside *">accepts curbside pickup</label>
                                <Controller
                                    name="acceptsCurbsidePickup"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} value={value} disabled={auth?.role !== 'SUPER_ADMIN'} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={'true'}>yes</option>
                                            <option value={'false'}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div>
                                <label htmlFor="acceptsDelivery">accepts delivery</label>
                                <Controller
                                    name="acceptsDelivery"
                                    control={control}
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        
                                        <>
                                        <select 
                                            onChange={(e) => { onChange(e)}} 
                                            value={value} 
                                            className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1"
                                            disabled={auth?.role !== 'SUPER_ADMIN'}
                                        >

                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={true}>yes</option>
                                            <option value={false}>no</option>

                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div> */}

                            {/* <div>
                                <label htmlFor="onlinePayment">accepts online payment <Astrisks /></label>
                                <Controller
                                    name="acceptsOnlinePayment"
                                    control={control}
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} value={value} disabled={auth?.role !== 'SUPER_ADMIN'} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={true}>yes</option>
                                            <option value={false}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div>
                                <label htmlFor="promoCode">accepts Promo Code <Astrisks /></label>
                                <Controller
                                    name="acceptsPromocode"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} value={value} disabled={auth?.role !== 'SUPER_ADMIN'} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={true}>yes</option>
                                            <option value={false}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div> */}

                            <div className="[&>div]:bg-[#f3f6f9] col-span-2">
                                <label htmlFor="tags">{t('restaurants.form.tags')} </label>
                                <Controller
                                    name="tags"
                                    control={control}
                                    // rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <Select
                                            open={openTag}
                                            onOpen={handleOpenTag}
                                            onClose={handleCloseTag}
                                            disabled={auth?.role !== 'SUPER_ADMIN'}
                                            labelId="tags-chip-select-label"
                                            id="tagsChipSelect"
                                            multiple
                                            className="w-full mb-1 disabled:bg-transparent"
                                            value={tags || []}
                                            onChange={(e) => {
                                                if(e?.target?.value?.length <= 30){
                                                    handleTagsChipSelectChange(e);
                                                    onChange(e);
                                                } else{
                                                    toast.error('Maximum Tags Selection is 30')
                                                }
                                            }}
                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled' : {textFillColor : '#fff'}}}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        value ? <Chip key={value?.id} label={value?.name} className="bg-[#464E5F] text-white font-light rounded-lg disabled:text-white disabled:bg-white" /> : null
                                                    ))}
                                                </Box>
                                            )}
                                            // MenuProps={MenuProps}
                                            >
                                            <MenuItem>
                                                <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCloseTag();
                                                }}>
                                                    Confirm
                                                </Button>
                                            </MenuItem>
                                            {tagsList?.map((name,index) => (
                                                <MenuItem
                                                    key={name?.id}
                                                    value={name}
                                                    selected = {index === 0}
                                                >
                                                {name?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div className="[&>div]:bg-[#f3f6f9] col-span-2">
                                <label htmlFor="cuisinesSelect">{t('restaurants.form.cuisine')} <Astrisks /></label>
                                <Controller
                                    name="cuisines"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <Select
                                            open={openCuisine}
                                            onOpen={handleOpenCuisine}
                                            onClose={handleCloseCuisine}
                                            disabled={auth?.role !== 'SUPER_ADMIN'}
                                            labelId="cuisines-chip-select-label"
                                            id="cuisinesChipSelect"
                                            multiple
                                            className="w-full mb-1"
                                            value={cuisines || []}
                                            onChange={(e) => {
                                                if(e?.target?.value?.length <= 8){
                                                    handleCuisinesChipSelectChange(e);
                                                    onChange(e);    
                                                } else{
                                                    toast.error('Maximum Cuisines Selection is 8')
                                                }
                                            }}
                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled' : {textFillColor : '#fff'}}}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    value ? <Chip key={value?.id} label={localStorage.getItem('lang') === 'ar' ? value?.nameAr : value?.name} className="bg-[#464E5F] text-white font-light rounded-lg" /> : null
                                                ))}
                                                </Box>
                                            )}
                                            // MenuProps={MenuProps}
                                            >
                                            <MenuItem>
                                                <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCloseCuisine();
                                                }}>
                                                    Confirm
                                                </Button>
                                            </MenuItem>
                                            {cuisinesList?.map((name) => (
                                                <MenuItem
                                                    key={name?.id}
                                                    value={name}
                                                >
                                                {localStorage.getItem('lang') === 'ar' ? name?.nameAr : name?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div className="[&>div]:bg-[#f3f6f9] col-span-2">
                                <label htmlFor="mealtypesSelect">{t('restaurants.form.meal_type')} <Astrisks /></label>
                                <Controller
                                    name="mealTypes"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <Select
                                            open={openMeals}
                                            onOpen={handleOpenMeals}
                                            onClose={handleCloseMeals}
                                            disabled={auth?.role !== 'SUPER_ADMIN'}
                                            labelId="mealtypes-chip-select-label"
                                            id="mealtypesChipSelect"
                                            multiple
                                            className="w-full mb-1"
                                            value={meals || []}
                                            onChange={(e) => {
                                                handleMealsChipSelectChange(e);
                                                onChange(e);

                                                // if(e?.target?.value?.length <= 8){
                                                //     handleMealsChipSelectChange(e);
                                                //     onChange(e);    
                                                // } else{
                                                //     toast.error('Maximum Meals Type Selection is 8')
                                                // }
                                            }}
                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled' : {textFillColor : '#fff'}}}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected?.map((value) => (
                                                    value ? <Chip key={value?.id} label={localStorage.getItem('lang') === 'ar' ? value?.nameAr : value?.name} className="bg-[#464E5F] text-white font-light rounded-lg" /> : null
                                                ))}
                                                </Box>
                                            )}
                                            // MenuProps={MenuProps}
                                            >
                                            <MenuItem>
                                                <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCloseMeals();
                                                }}>
                                                    Confirm
                                                </Button>
                                            </MenuItem>
                                            {mealsList?.map((name) => (
                                                <MenuItem
                                                    key={name?.id}
                                                    value={name}
                                                >
                                                {localStorage.getItem('lang') === 'ar' ? name?.nameAr : name?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.meal_type_helper')}</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div>
                                <label>{t('restaurants.form.takein_share')} <Astrisks /></label>
                                <input type="text" id="takeinSharePerOrder"
                                    {...register('takeinSharePerOrder',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value: /^[0-9#]+(\.[0-9]*)?$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                        },
                                        max:{
                                            value : shareType === 'PERCENTAGE' ? 100 : 99999999,
                                            message : shareType === 'PERCENTAGE' ? localStorage.getItem('lang') === 'ar' ? 'اقصى نسبة مئوية 100%' : 'Max Percentage Value is 100%' : localStorage.getItem('lang') === 'ar' ? 'قيمة غير صحيحه' : 'Invalid Value'
                                        }
                                    })} 
                                />

                                {errors?.takeinSharePerOrder?.message ? <p className="capitalize text-sm text-danger">{errors?.takeinSharePerOrder?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.takein_share_helper')}</p>}
                            </div>

                            <div className="[&>div]:bg-[#f3f6f9]">
                                <label> {t('restaurants.form.takein_type')} <Astrisks /></label>
                                <FormControl sx={{width: '100%' }}>
                                    <Controller
                                        control={control}
                                        name=""
                                        value={discountType}
                                        rules={{required:t('common.required')}}
                                        {...register('takeinSharePerOrderType',{
                                            required:{
                                                value : true,
                                                message: t('common.required')
                                            }
                                        })}
                                        render={({field,fieldState: { error }})=>(
                                            <>
                                                <Select {...field}
                                                    displayEmpty
                                                    inputRef={{...register('takeinSharePerOrderType')}}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="takeinSharePerOrderType"
                                                    value={discountType}
                                                    onChange={handleOfferTypeSelectChange}
                                                    // renderValue={(selected) => selected.join(', ')}
                                                    sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'11px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}, '& .css-tzsjye' : {backgroundColor : '#f3f6f9'}}}
                                                >

                                                    <MenuItem value={'AMOUNT'}>
                                                        <ListItemText primary={localStorage.getItem('lang') === 'ar' ? 'قيمة' : 'Amount'} />
                                                    </MenuItem>

                                                    <MenuItem value={'PERCENTAGE'}>
                                                        <ListItemText primary={localStorage.getItem('lang') === 'ar' ? 'نسبة مئوية' : 'Percentage'} />
                                                    </MenuItem>
                                                </Select>                                                    
                                            </>
                                        )}
                                        
                                    />
                                    
                                </FormControl>

                                {errors?.takeinSharePerOrderType?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.takeinSharePerOrderType?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('restaurants.form.takein_type_helper')}</p>}
                            </div>

                            {/* <div>
                                <label htmlFor="deliveryTypes">Select Delivery Type <Astrisks /></label>
                                <Controller
                                    name=""
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <Select
                                                disabled={auth?.role !== 'SUPER_ADMIN'}
                                                labelId="Delivery-Types-label"
                                                id="DeliveryTypeSelect"
                                                multiple
                                                className="w-full mb-1"
                                                value={deliveryType || []}
                                                onChange={handleDeliveryTypeChipSelectChange}
                                                sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled' : {textFillColor : '#fff'}}}
                                                // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value?.id} label={value?.name} className="bg-[#464E5F] text-white font-light rounded-lg" />
                                                    ))}
                                                    </Box>
                                                )}
                                                // MenuProps={MenuProps}
                                                >
                                                <MenuItem
                                                    value=''
                                                    disabled
                                                >
                                                    Select An Option
                                                </MenuItem>
                                                {deliveryList?.map((name) => (
                                                    <MenuItem
                                                        key={name?.id}
                                                        value={name}
                                                    >
                                                    {name?.name}
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div> */}

                            <div className="col-span-2 text-lg font-semibold">
                                <h2 className="text-center"><span className="text-mainColor block w-full text-center m-auto relative after:block after:h-1 after:bg-mainColor after:absolute after:w-[30%] after:left-[25%]  after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2  before:block before:h-1 before:bg-mainColor before:absolute before:w-[30%] before:left-[75%]  before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 capitalize">{t('restaurants.form.bank_details')}</span></h2>
                            </div>

                            <div>
                                <label>{t('restaurants.form.bank_name')} <Astrisks /></label>
                                <input type="text" id="bankName"
                                    {...register('bankName',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value:/^[a-zA-Z\s]{1,50}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه ' : 'Only English letters are allowed'
                                        },
                                        maxLength : {
                                            value : 60,
                                            message : 'max length is 60 characters'
                                        }
                                    })} 
                                    maxLength={60}
                                />

                                {errors?.bankName?.message ? <p className="capitalize text-sm text-danger">{errors?.bankName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.bank_name_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.bank_number')} <Astrisks /></label>
                                <input type="text" id="bankAccountNumber"
                                    {...register('bankAccountNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        validate: value => isValidIBAN(value) ? null : localStorage.getItem('lang')==='ar' ? 'خطأ برقم ال IBAN' : 'Invalid IBAN'
                                    })} 
                                />

                                {errors?.bankAccountNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.bankAccountNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.bank_number_helper')}</p>}
                            </div>
                            <div className="relative mb-8">
                                <label>{t('restaurants.form.commerical_letter')} <Astrisks /></label>
                                <div className="restaurant-commerical-image-preview w-full h-[200px] bg-[rgba(0,0,0,.3)] left-6 rounded-lg z-50">
                                    <div className="defaultImage w-full h-full z-10 absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center rounded-lg">
                                    {(commericalPreview && (getFileType(commericalPreview) !== 'pdf')) || commericalPreview?.startsWith('data:image/') ?
                                        <img src={previewIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" /> 
                                        :
                                        <img src={pdfIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                                        }
                                    </div>
                                    <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full">
                                        <div className="absolute top-0 right-0 w-full h-full rounded-lg ">
                                            <input type="file" id="file" title={t('common.commericalFile')} disabled={auth?.role !== 'SUPER_ADMIN'} accept=".pdf,image/*" onChange={handleCommericalLetterChange} className="absolute right-0 cursor-pointer opacity-1 z-10 opacity-0 w-full h-full" />
                                            {auth?.role !== 'SUPER_ADMIN' ? null : <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span> }
                                        </div>
                                        {(commericalPreview && (getFileType(commericalPreview) !== 'pdf')) || commericalPreview?.startsWith('data:image/') ? 
                                            <img src={commericalPreview} alt="Commerical Letter" className="w-full h-full rounded-lg object-cover" />
                                            :
                                            <a href={commericalPreview}> </a>
                                        }
                                    </div>
                                    <Button className="capitalize text-white bg-mainColor w-full" ><a className="w-full" href={commericalPreview} target="_blank" rel="noreferrer" download> {t('common.download')} </a> </Button>

                                </div>
                            </div>
                            {/* {console.log(ibanPreview && ((getFileType(ibanPreview) !== 'pdf')))} */}

                            <div className="relative mb-8">
                                <label>{t('restaurants.form.iban_letter')} <Astrisks /></label>
                                <div className="restaurant-iban-image-preview w-full h-[200px] bg-[rgba(0,0,0,.3)] left-6 rounded-lg z-50">
                                    <div className="defaultImage w-full h-full z-10 absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center rounded-lg">
                                        {/* <img src={previewIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" /> */}
                                        {ibanPreview && ((getFileType(ibanPreview) !== 'pdf') || ibanPreview?.startsWith('data:image/')) ?
                                        <img src={previewIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" /> 
                                        :
                                        <img src={pdfIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" /> 
                                        }
                                    </div>
                                    <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full">
                                        <div className="absolute top-0 right-0 w-full h-full rounded-lg ">
                                            <input type="file" id="file" title={t('common.ibanFile')} disabled={auth?.role !== 'SUPER_ADMIN'} accept=".pdf,image/*" onChange={handleIbanLetterChange} className="absolute right-0 cursor-pointer opacity-1 z-10 opacity-0 w-full h-full" />
                                            {auth?.role !== 'SUPER_ADMIN' ? null : <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span> }
                                        </div>
                                        {/* {ibanPreview && <img src={ibanPreview} alt="IBAN Letter" className="w-full h-full rounded-lg object-cover" />} */}
                                        {(ibanPreview && (getFileType(ibanPreview) !== 'pdf')) || ibanPreview?.startsWith('data:image/') ? 
                                            <img src={ibanPreview} alt="Iban Letter" className="w-full h-full rounded-lg object-cover" />
                                            :
                                            <a href={ibanPreview}> </a>
                                        }
                                    </div>
                                    <Button className="capitalize text-white bg-mainColor w-full" ><a className="w-full" href={ibanPreview} target="_blank" rel="noreferrer" download> {t('common.download')} </a> </Button>
                                </div>
                            </div>

                            <div className="relative mb-6">
                                <label>{t('restaurants.form.vat_letter')} <Astrisks /></label>
                                <div className="restaurant-Vat-image-preview w-full h-[200px] bg-[rgba(0,0,0,.3)] left-6 rounded-lg z-50">
                                    <div className="defaultImage w-full h-full z-10 absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center rounded-lg">
                                        {(vatPreview && (getFileType(vatPreview) !== 'pdf')) || vatPreview?.startsWith('data:image/') ?
                                            <img src={previewIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" /> 
                                            :
                                            <img src={pdfIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" /> 
                                        }
                                    </div>
                                    <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full">
                                        <div className="absolute top-0 right-0 w-full h-full rounded-lg ">
                                            <input type="file" id="file" title={t('common.vatFile')} disabled={auth?.role !== 'SUPER_ADMIN'} accept=".pdf,image/*" onChange={handleVatLetterChange} className="absolute right-0 cursor-pointer opacity-1 z-10 opacity-0 w-full h-full" />
                                            {auth?.role !== 'SUPER_ADMIN' ? null : <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span> }
                                        </div>
                                        {/* {vatPreview && <img src={vatPreview} alt="VAT Letter" className="w-full h-full rounded-lg object-cover" />} */}
                                        {(vatPreview && (getFileType(vatPreview) !== 'pdf')) || vatPreview?.startsWith('data:image/') ? 
                                            <img src={vatPreview} alt="Vat Letter" className="w-full h-full rounded-lg object-cover" />
                                            :
                                            <a href={vatPreview}> </a>
                                        }
                                    </div>
                                    <Button className="capitalize text-white bg-mainColor w-full" ><a className="w-full" href={vatPreview} download target="_blank" rel="noreferrer"> {t('common.download')} </a> </Button>
                                </div>
                            </div>

                            {auth?.role !== 'SUPER_ADMIN' ? null
                                : 
                                <div className="restaurant-submit-btn-wrapper col-span-2">
                                    {/* <label className="text-left" htmlFor="submit">Apply Changes</label> */}
                                    <Button type="submit" disabled={restaurants?.addLoading || file?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed block ml-auto w-full lg:w-[30%] p-0 bg-mainGreen text-white text-center text-sm font-semibold rounded-md px-5 py-[18px] font-playfair">Save</Button>
                                </div> 
                            }
                        </div>

                    </form>
                </section>
                {auth?.role === 'SUPER_ADMIN' ? 
                    <div className="mt-5 flex justify-between items-center p-4 bg-white w-full [&>div>button]:font-playfair">
                        {singleRestaurant?.data?.status === 'DEACTIVATED' ? <p>{localStorage.getItem('lang') === 'ar' ? 'هذا المطعم' : 'This restaurant has been'} <span className={`text-mainRed font-semibold`}>{localStorage.getItem('lang') === 'ar' ? 'غير نشط' : 'Deactivated'}</span>.{localStorage.getItem('lang') === 'ar' ? 'هل تريد' : 'Would you like to'} <span className="text-mainGreen font-semibold">{localStorage.getItem('lang') === 'ar' ? 'اعادة تنشيطه' : 'Re-activate'}</span></p> : <p>{localStorage.getItem('lang') === 'ar' ? 'هل تريد' : 'Would you like to'} <span className="text-mainRed font-semibold">{localStorage.getItem('lang') === 'ar' ? 'جعله غير نشط' : 'Deactivate'}</span>?</p>}
                        <div className="[&>button]:px-5 [&>button]:py-[16px] [&>button]:w-[100%] text-right res-btn">
                            {singleRestaurant?.data?.status === 'DEACTIVATED' ?
                                <Button title={auth?.role !== 'SUPER_ADMIN' ? 'Unauthorized to take this action' : null} onClick={()=>toggleRestaurantHandler('ACTIVATED')} disabled={singleRestaurant?.loading || auth?.role !== 'SUPER_ADMIN'} className={`disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-auto justify-center  p-0 border border-mainGreen text-mainGreen hover:text-white hover:bg-mainGreen text-sm font-semibold rounded-md`}>{localStorage.getItem('lang') === 'ar' ? 'تنشيطه' : 'Activate'}</Button>
                                :
                                <Button onClick={()=>toggleRestaurantHandler('DEACTIVATED')} disabled={singleRestaurant?.loading} className={`disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed  justify-center  p-0 border border-mainRed text-mainRed hover:text-white hover:bg-mainRed text-sm font-semibold rounded-md`}>{localStorage.getItem('lang') === 'ar' ? 'غير نشط' : 'Deactivate'}</Button>
                            }
                        </div>
                    </div>
                : null }
            </div>
            {restaurants?.addLoading || file?.loading || restaurants?.loading ? <Overlay /> : null}
        </article>
    );
}

export default EditRestaurant;