import { Button, ListItemButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openHandler } from "../Redux/Slice/common/overlaySlice";
// import OrderNotification from "./OrdersNotifications/orderNotify";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import RestaurantRequest from "./ResturantRequest/resturantRequest";
import { useEffect, useState } from "react";
import { getRestaurantRequests } from "../Redux/Slice/Restaurants/restaurantsSlice";
import { Link } from "react-router-dom";
import { tabHandler } from "../Redux/Slice/common/tabSlice";

const Managerestaurants = ({t}) => {
    const {restaurantRequest} = useSelector(state=>state.overlay)
    const dispatch = useDispatch()

    const showOrdersHandler = ()=>{
        // setshowOrders(!showOrders)
        dispatch(openHandler({restaurantRequest:true}))
    }

    const changeTabHandler = ()=>{
        // setshowOrders(!showOrders)
        dispatch(tabHandler({tabs:'REQUESTS',index:1}))
    }

    const restaurants = useSelector(state=>state.restaurants)
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)

    useEffect(() => {
        dispatch(getRestaurantRequests({page,size}))
    }, [dispatch])

    // console.log(restaurants?.restaurantRequests?.data);
    return ( 
        <div className="manage-restaurants h-full">
            <section className="manage-restaurants-title">
                <h2 className="capitalize text-[#464E5F] text-lg font-semibold">{t("dashboard.restaurant_requests.title")}</h2>
            </section>

            <section className="manage-order mt-5 w-full">
                <div className={`flex justify-between items-center w-full text-sm px-1 py-4 bg-[#e3f1e9] rounded-md ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <div className={`left-section flex items-center gap-x-3 capitalize ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        <div className="counter">
                            <span className="w-10 h-9 block text-center leading-9 bg-mainGreen rounded-md text-white font-bold">{restaurants?.restaurantRequests?.meta?.total}</span>
                        </div>
                        <div className="left-sec-title capitalize text-[#3F4254] font-semibold">
                            {t("dashboard.restaurant_requests.new_requests")}
                        </div>
                    </div>

                    <div className="right-section relative">
                        {/* <ListItemButton className="flex items-center capitalize" onClick={showOrdersHandler}>manage requests <FaAngleRight /> </ListItemButton> */}
                        <Link to='/dashboard/restaurant'><Button className="flex items-center capitalize text-[#442B7E] font-semibold" onClick={changeTabHandler}>{t("dashboard.restaurant_requests.manage_requests")} {localStorage.getItem('lang') === 'ar' ? <FaAngleLeft /> : <FaAngleRight />} </Button></Link>

                        {/* {restaurantRequest ? <div className="restaurant-request-notify absolute -top-24 left-[80%] z-[9999] bg-white min-w-[350px] max-h-[400px] h-[400px] overflow-hidden overflow-y-scroll p-3 shadow-md rounded-md">
                            {[...Array(10)]?.map(()=> <OrderNotification /> )}
                        </div> : null} */}
                    </div>
                </div>
            </section>

            <section className="restaurant-requests-wrapper lg:h-[750px]  overflow-hidden overflow-y-auto mt-8">
                {/* {[...Array(12)].map(()=><restaurantRequest />)} */}
                {restaurants?.restaurantRequests?.data?.map((restaurant,index)=><RestaurantRequest key={index} data={restaurant} t={t} />)}
            </section>
        </div>
    );
}

export default Managerestaurants;