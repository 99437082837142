import commingsoon from '../Assets/imgs/commingsoon.svg'
const CommingSoon = () => {
    return ( 
        <article className="not-found bg-white w-full h-[82vh] rounded-xl relative overflow-hidden">
            <div className="w-full h-full flex justify-center items-center">
                <div className='-mt-6 relative'>
                    <img src={commingsoon} alt='notfound-404' className='w-[300px] md:w-[400px] lg:w-[500px] h-[250px] m-auto' />
                    <p className='text-sm sm:text-3xl text-[#333] mt-6 font-semibold capitalize text-center'>Launching Soon</p>
                    <p className='w-[44%] m-auto text-center mt-5 leading-6 text-[#707070]'>
                        This feature is not available right now but will be launched soon. <span className='text-mainColor font-[500]'>Stay tuned!</span>
                    </p>
                </div>
            </div>
        </article>
     );
}

export default CommingSoon;