import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";
import { toast } from "react-toastify";

export const getRestaurantOrders = createAsyncThunk("getRestaurantOrdersFunc", async({page,size,col,dir,resId,orderType,searchValue,selectedDate}, {rejectWithValue,dispatch})=>{
    // console.log(orderType);
    let from_date = null,
        adjustedFromDate = null,
        to_date = null,
        adjustedToDate = null

    if(selectedDate){
        from_date = new Date(selectedDate?.startDate)
        adjustedFromDate = new Date(from_date.getTime() + (from_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);

        to_date = new Date(selectedDate?.endDate)
        adjustedToDate = new Date(to_date.getTime() + (to_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);
    }

    // console.log(selectedDate);

    // let url = selectedDate ? `/ratings?page=${page}&size=${size}&from_date=${adjustedFromDate}&to_date=${adjustedToDate}` : `/ratings?page=${page}&size=${size}`

    let colCheck;

    if (col) {
        // colCheck = col === 'Created at' ? 'created_at' : col === 'Delieverd at' ? 'delivered_at' : col === 'Updated at' ? 'updated_at' : col === 'Restaurant Name' ? 'restaurant_name' : col === 'Courier Name' ? 'courier_name' : col === 'Courier Rating' ? 'courier_rating' : col === 'Courier Number' ? 'courier_mobile_number' : col === 'Customer Name' ? 'customer_name' : col === 'Customer Number' ? 'customer_mobile_number' : col === 'Customer Address' ? 'customer_address' : col === 'Order Rating' ? 'order_rating' : col === 'Branch Name' ? 'branch_name' : col === 'Branch Number' ? 'branch_number' : col === 'Branch Address' ? 'branch_address' : col === 'Min Charge' ? 'minimum_charge' : col === 'Avg Delivery Time' ? 'average_delivery_time' : col === 'Avg Rating' ? 'average_rating' : col === 'Total Orders' ? 'total_orders' : col === 'Delivery Charges' ? 'delivery_charges' : col === 'Pickup Schedule' ? 'pickup_schedule' : col === 'Vehicle Plate' ? 'vehicle_plate' : col === 'Original Price' ? 'original_price' : col === 'Final Price' ? 'final_price' : col.replace(/\s+/g, '');
        colCheck = 
            (col === 'Created at' || col === 'تم انشاءه فى') ? 'created_at' 
          : (col === 'Delieverd at' || col === 'تم التوصيل في') ? 'delivered_at' 
          : (col === 'Restaurant Name' || col === 'اسم المطعم') ? 'restaurant_name' 
          : (col === 'Restaurant Arabic Name' || col === 'اسم المطعم بالعربية') ? 'restaurant_name_ar' 
          : (col === 'Courier Name' || col === 'اسم السائق') ? 'courier_name' 
          : (col === 'Courier Number' || col === 'هاتف السائق') ? 'courier_mobile_number' 
          : (col === 'Courier Rating' || col === 'تقييم السائق') ? 'courier_rating' 
          : (col === 'Order Rating' || col === 'تقييم الطلب') ? 'order_rating' 
          : (col === 'Pickup Schedule' || col === 'وقت الاستلام') ? 'pickup_schedule' 
          : (col === 'Vehicle Plate' || col === 'لوحة المركبة') ? 'vehicle_plate' 
          : (col === 'Customer Name' || col === 'اسم العميل') ? 'customer_name' 
          : (col === 'Customer Number' || col === 'هاتف العميل') ? 'customer_mobile_number' 
          : (col === 'Customer Address' || col === 'عنوان العميل') ? 'customer_address' 
          : (col === 'Original Price' || col === 'السعر الاساسى') ? 'original_price' 
          : (col === 'Final Price' || col === 'السعر النهائى') ? 'final_price' 
          : (col === 'Branch Name' || col === 'اسم الفرع') ? 'branch_name' 
          : (col === 'Branch Number' || col === 'هاتف الفرع') ? 'branch_number' 
          : (col === 'Branch Address' || col === 'عنوان الفرع') ? 'branch_address' 
          : col.replace(/\s+/g, '').toLowerCase();
          //   : (col === 'Avg Rating' || col === 'متوسط التقييم') ? 'average_rating' 
          //   : (col === 'Total Orders' || col === 'اجمالى الطلبات') ? 'total_orders' 
          //   : (col === 'Open Time' || col === 'وقت الفتح') ? 'opening_time' 
          //   : (col === 'Close Time' || col === 'وقت الإغلاق') ? 'closing_time' 
          //   : (col === 'Order Deadline Time' || col === 'وقت اخر طلب') ? 'order_deadline' 
          //   : (col === 'Restaurant Phone' || col === 'هاتف المطعم') ? 'phone_number' 
    }
    
    const url = !col ? `/orders?page=${page ? page : 1}&size=${size ? size : 10}${orderType ?`&orderType=${orderType}`: ''}${searchValue ?`&search=${searchValue}`: ''}${selectedDate ? `&fromDate=${adjustedFromDate}&toDate=${adjustedToDate}`:''}${resId ? `&restaurantId=${resId}` : '' }` : `/orders?page=${page ? page : 1}&size=${size ? size : 10}${orderType ?`&orderType=${orderType}`: ''}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${searchValue ?`&search=${searchValue}`: ''}${selectedDate ? `&fromDate=${adjustedFromDate}&toDate=${adjustedToDate}`:''}${resId ? `&restaurantId=${resId}` : '' }`;
    
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getOrderById = createAsyncThunk("getOrderByIdFunc", async(id, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await axios.get(`/orders/${id}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getOrdersTypes = createAsyncThunk("getOrdersTypesFunc", async(_, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await axios.get(`/order-types/list`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const completeOrder = createAsyncThunk("completeOrder", async(id, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await axios.put(`/orders/${id}/completed`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const cancelOrder = createAsyncThunk("cancelOrder", async(id, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await toast.promise(axios.put(`/orders/${id}/cancel`),{
            pending : 'Loading...',
            success : 'Order Cancelled Successfully'
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getInvoice = createAsyncThunk("getInvoiceFunc", async(id, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await axios.get(`/orders/${id}/invoice`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getAssignHistory = createAsyncThunk("getAssignHistoryFunc", async(id, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await axios.get(`/orders/${id}/assignee-history`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

const initialState = {
    loading: false,
    resOrders : [],
    singleOrder : [],
    ordersType : [],
    assignHistory : [],
    invoice : null,
    error : null,
}
export const OrderSlice = createSlice({ 
    name: "restaurantSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getRestaurantOrders.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getRestaurantOrders.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.resOrders = action.payload
            // console.log(action.payload);
        })

        .addCase(getRestaurantOrders.rejected, (state,action)=>{
            state.loading = false;
            state.resOrders = ""
            state.error = action.payload
        })

        .addCase(getOrderById.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getOrderById.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.singleOrder = action.payload
            // console.log(action.payload);
        })

        .addCase(getOrderById.rejected, (state,action)=>{
            state.loading = false;
            state.singleOrder = ""
            state.error = action.payload
        })
        .addCase(getOrdersTypes.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getOrdersTypes.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.ordersType = action.payload
            // console.log(action.payload);
        })

        .addCase(getOrdersTypes.rejected, (state,action)=>{
            state.loading = false;
            state.ordersType = []
            state.error = action.payload
        })

        .addCase(completeOrder.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(completeOrder.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // state.ordersType = action.payload
            // console.log(action.payload);
        })

        .addCase(completeOrder.rejected, (state,action)=>{
            state.loading = false;
            // state.ordersType = []
            state.error = action.payload
        })

        .addCase(cancelOrder.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(cancelOrder.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // state.ordersType = action.payload
            // console.log(action.payload);
        })

        .addCase(cancelOrder.rejected, (state,action)=>{
            state.loading = false;
            // state.ordersType = []
            state.error = action.payload
        })

        .addCase(getInvoice.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getInvoice.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.invoice = action.payload
            // console.log(action.payload);
        })

        .addCase(getInvoice.rejected, (state,action)=>{
            state.loading = false;
            state.invoice = null
            state.error = action.payload
        })

        .addCase(getAssignHistory.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getAssignHistory.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.assignHistory = action.payload
            // console.log(action.payload);
        })

        .addCase(getAssignHistory.rejected, (state,action)=>{
            state.loading = false;
            state.assignHistory = null
            state.error = action.payload
        })

    }
});

// export const {logout} = OrderSlice.actions

export default OrderSlice.reducer

