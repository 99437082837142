import {Routes,Route, useLocation, useNavigate } from 'react-router-dom';
import Login from '../../Pages/login';
import { useEffect, useState } from 'react';
import MainRoutes from './mainRoutes';
import ForgetPassword from '../../Pages/forgetPassword';
import VerificationCode from '../ForgetPassword-Steps/verificationCode';
import NewPassword from '../ForgetPassword-Steps/newPassword';
import Terms from '../../Pages/terms'
import AboutUs from '../../Pages/aboutus'
import FirstChangePassword from '../../Pages/firstChangePassword';
import { useSelector } from 'react-redux';
import Privacy from '../../Pages/privacy';
import SelectRestaurant from '../../Pages/AccountManager/selectRestaurant';

// const Terms = lazy(() => import("../../Pages/terms"));
// const AboutUs = lazy(() => import("../../Pages/aboutus"));

const Routing = () => {
    const path = useLocation()
    const [navCollapse, setnavCollapse] = useState(false)
    const navigate = useNavigate()
    const toggleNavHanlder = ()=>{
      let toggle = navCollapse
      setnavCollapse(!toggle)
    }
    const state = useSelector(state => state)

    useEffect(() => {
        // if(localStorage.getItem('atk') === 'undefined' || !localStorage.getItem('atk') ){
        //     navigate('/login',{ replace: true })
        // }
        if(((localStorage.getItem('atk') === 'undefined' || !localStorage.getItem('atk'))  && (path.pathname.indexOf('dashboard') === 1))){
            navigate('/login',{ replace: true })
        }
    }, [])

    return (
        <>
        {/* since login page contain no header nor side nav, the pages has been splitted into to sections first to check if the pathname contain login then we render the login page only other than this render the main layout with the page routes inside */}

            {path.pathname.indexOf('dashboard') === -1 ?
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/selectrestaurant' element={<SelectRestaurant />} />
                    <Route path='/forgetpassword' element={<ForgetPassword />} />
                    <Route path='/firstnewpassword' element={<FirstChangePassword />} />
                    <Route path='/verificationcode' element={<VerificationCode />} />
                    <Route path='/reset-password' element={<NewPassword />} />
                    <Route path='/newpassword' element={<NewPassword />} />
                    <Route path='/termsconditions' element={<Terms />} />
                    <Route path='/privacypolicies' element={<Privacy />} />
                    <Route path='/aboutus' element={<AboutUs />} />
                </Routes>

                :
                <>
                    <MainRoutes />
                </>
            }
        </>
    );
}

export default Routing;