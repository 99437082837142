import { useState } from "react";
import { useSelector } from "react-redux";

import AllTickets from "./tickets/alltickets";
import MyTickets from "./tickets/myTickets";
import { useTranslation } from "react-i18next";

const Troubleshoot = () => {
    const {t, i18n} = useTranslation()
    //Filter Tabs
    const [activeTab, setActiveTab] = useState(0);
    const [tab, settab] = useState('ALL');

    const btns = [
        {labelAr:'الكل', label:'all',tab:`ALL`},
        {labelAr:'شكاوى خاصه', label:'my Tickets' ,tab:`MyTickets`},
    ]

    const filterBtn = (item,index)=>{
        setActiveTab(index)
        let tab = item?.tab
        settab(tab)
    }

    // console.log(allTickets);

    const {auth} = useSelector(state=>state)
    // console.log(auth?.role);
    return ( 
        <>
            <article className="troubleshoot-wrapper pb-5">
                <section className="order-title mb-3">
                    <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('trouble_tickets.title')}</h1>
                </section>
                
                {auth?.role === 'BACK_OFFICE' ?
                    <>
                        <div className='w-fit tabs-wrapper'>
                            <div className="px-2 py-1 bg-[#ffffff] mb-2 rounded-md">
                                {btns?.map((item,index)=>{
                                    return(
                                        <button key={index} className={`capitalize text-sm font-bold text-[#333] px-4 py-2 lg:px-2 xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC]' : null}`}
                                            onClick={()=>filterBtn(item,index)}
                                        >
                                            {localStorage.getItem('lang') === 'ar' ? item.labelAr : item.label}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>

                        {tab === 'ALL' ? 
                            <AllTickets t={t} />

                            : tab === 'MyTickets' ?
                            <MyTickets t={t} />
                            
                        : null }


                    </>

                    : 
                                
                    <>
                        <AllTickets t={t} />
                    </>
                }
            </article>
        </>
    );
}

export default Troubleshoot;