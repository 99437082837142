import notfound from '../Assets/imgs/404.svg'
import notfoundShape from '../Assets/imgs/404Shape.svg'
import linth from '../Assets/imgs/Linth.svg'
const NotFound = () => {
    return ( 
        <article className="not-found bg-white w-full h-[82vh] rounded-xl relative overflow-hidden">
            <div className="w-full h-full flex justify-center items-center">
                <div className='-mt-6 relative'>
                    <img src={notfound} alt='notfound-404' className='w-[300px] md:w-[400px] lg:w-[500px] h-auto m-auto' />
                    <p className='text-sm sm:text-lg text-[#333] mt-6 font-semibold capitalize text-center'>This page is not found</p>

                    <div className='absolute -top-6 left-[55%] -translate-x-1/2'>
                        <img src={notfoundShape} alt='notfound-shape' className=' w-[50px] md:w-[55px] lg:w-[70px]' />
                    </div>
                </div>
            </div>

            <section className='linth-banner absolute bottom-0 left-0'>
                <img src={linth} alt='banner' className='w-[300px] md:w-[350px] lg:w-[450px]' />
            </section>
        </article>
     );
}

export default NotFound;