import unauth from '../Assets/imgs/unauth.png'

const UnAuthorized = () => {
    return ( 
        <div className='w-full bg-white rounded-md text-center h-full min-h-[550px]'>
            <div>
                <img src={unauth} alt="Unauthorized" className='m-auto' />
            </div>

            <div className='mt-5 py-3'>
                <p className='text-2xl text-mainColor font-semibold'>You aren't authorized to view this page.</p>
            </div>
        </div>
    );
}

export default UnAuthorized;