import { GoogleMap,Marker,DirectionsRenderer } from '@react-google-maps/api';
import './mapStyle.scss'
import { useEffect, useMemo, useRef, useState } from 'react';
import scotterIcon from '../../Assets/imgs/scotter.svg'
import branchIcon from '../../Assets/imgs/branch.svg'
import userIcon from '../../Assets/imgs/user.svg'
import { useGoogleMaps } from '../Common/mapLoader';
import { io } from 'socket.io-client';
import { useTranslation } from 'react-i18next';

const MapV2 = ({data}) => {
    const {t} = useTranslation()
    const [directions, setDirections] = useState(null);
    let id = data?.id

    const { isLoaded, loadError } = useGoogleMaps();

    const center = useMemo(() => ({
        lat: data?.branchCoordinates?.coordinates[0],
        lng: data?.branchCoordinates?.coordinates[1]
    }), [data]);

    const updateDirections = (origin, destination) => {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin,
                destination,
                travelMode: window.google.maps.TravelMode.DRIVING
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    };


    useEffect(() => {
        if (isLoaded && data) {
            const directionsService = new window.google.maps.DirectionsService();
            const origin = {
                lat: data?.branchCoordinates?.coordinates[0],
                lng: data?.branchCoordinates?.coordinates[1]
            };
            const destination = {
                lat: data?.customerCoordinates?.coordinates[0],
                lng: data?.customerCoordinates?.coordinates[1]
            };
            directionsService.route(
                {
                    origin,
                    destination,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
            updateDirections(origin, destination);
        }
    }, [isLoaded, data]);

    // socket implementation    

    const [driverPosition, setdriverPosition] = useState({lat:0,lng:0})
    const [clientPosition, setclientPosition] = useState({lat:0,lng:0})

    const handleLocationUpdate = (driverInfo) => {
        // console.log(driverInfo);
        // setres(driverInfo)
        if (driverInfo && driverInfo !=='no one has taken that order yet') {
            setdriverPosition({lat: driverInfo?.Driver_Location[0] || 0, lng: driverInfo?.Driver_Location[1] || 0})
            setclientPosition({lat: driverInfo?.Order_Destination[0] || 0, lng: driverInfo?.Order_Destination[1] || 0,})

            const newDriverPosition = {lat: driverInfo?.Driver_Location[0] || 0, lng: driverInfo?.Driver_Location[1] || 0};
            const newClientPosition = {lat: driverInfo?.Order_Destination[0] || 0, lng: driverInfo?.Order_Destination[1] || 0};
            updateDirections(newDriverPosition, newClientPosition);

            // count.current = 0;
            // setdirectonOpt((prevState) => ({
            //     ...prevState,
            //         origin: {
            //         lat: driverInfo?.Driver_Location[1] || 0,
            //         lng: driverInfo?.Driver_Location[0] || 0,
            //     },
            //     destination : {
            //         lat: driverInfo?.Order_Destination[1] || 0,
            //         lng: driverInfo?.Order_Destination[0] || 0,
            //     }
            // }));
        }
    };


    let token
    try {
        token = localStorage.getItem('atk');
    } catch (error) {
        console.error('Failed to retrieve token:', error);
    }

    const SocketToken = `${token}`;


    const socket = useRef();
    let url = 'delivery.takein.sa'

    // this for production
    if(window.location.hostname === 'admin.takein.sa'){
        url = 'delivery.takein.sa'

        // this for staging
    } else if(window.location.hostname === 'admin-staging.takein.sa'){
    url = 'https://delivery-staging.takein.sa'

    // below for development environment
    } else if(window.location.hostname === 'admin-develop.takein.sa'){
    url = 'https://delivery-develop.takein.sa'
    } else if(window.location.hostname === 'localhost'){
    url = 'https://delivery-develop.takein.sa'
    }


    useEffect(() => {        
        if (id && SocketToken && data?.orderType === 'DELIVERY' && (data?.orderStatus === 'PICKED_UP' || data?.orderStatus === 'OUT_FOR_DELIVERY' )) {
            socket.current = io(url, {
            auth: { token: SocketToken }
            });
            socket.current.emit('admin-track', id);
            socket.current.on('connection-status', (e) => console.log(e));
            socket.current.on('tracking-info', (driverInfo) => handleLocationUpdate(driverInfo));
            return () => {
            socket.current.disconnect();
            };
        }
    }, [id, SocketToken]);
    
    if (loadError) {
        return <div>Error loading map</div>;
    }

    return ( 
        <div className='w-full h-full [&>div]:w-full [&>div]:h-full' >
            {isLoaded ?
                !data?.customerCoordinates || !data?.branchCoordinates ? <span className='flex capitalize justify-center items-center w-full h-full'>{t('order_details.right_section.no_map')}</span> :
                (data?.orderType === 'DELIVERY' && (data?.orderStatus === 'PICKED_UP' || data?.orderStatus === 'OUT_FOR_DELIVERY' ) ) ?
                    <GoogleMap
                        center={center}
                        zoom={10}
                    >
                        <Marker
                            position={driverPosition}
                            icon={{
                                url: scotterIcon,
                                scaledSize: new window.google.maps.Size(40, 40) // Adjust the size as needed
                            }}
                        />
                        <Marker
                            position={clientPosition}
                            icon={{
                                url: userIcon,
                                scaledSize: new window.google.maps.Size(40, 40) // Adjust the size as needed
                            }}
                        />

                        {directions && (
                            <DirectionsRenderer directions={directions} options={{suppressMarkers : true}} />
                        )}
                    </GoogleMap>
                :

                data?.orderType !== 'DELIVERY' || (data?.orderType === 'DELIVERY' && (data?.orderStatus !== 'PICKED_UP' || data?.orderStatus !== 'OUT_FOR_DELIVERY' )) ?
                    <GoogleMap
                        center={center}
                        zoom={10}
                    >
                        <Marker
                            position={directions?.routes[0]?.legs[0]?.start_location}
                            icon={{
                                url: branchIcon,
                                scaledSize: new window.google.maps.Size(40, 40) // Adjust the size as needed
                            }}
                        />
                        <Marker
                            position={directions?.routes[0]?.legs[0]?.end_location}
                            icon={{
                                url: userIcon,
                                scaledSize: new window.google.maps.Size(40, 40) // Adjust the size as needed
                            }}
                        />

                        {directions && (
                            <DirectionsRenderer directions={directions} options={{suppressMarkers : true}} />
                        )}
                    </GoogleMap>
                : <span>{t('order_details.right_section.no_map')}</span>

                : <div>Loading...</div>
            }    
        </div>
    );
}

export default MapV2;