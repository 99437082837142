import { CgSpinnerTwoAlt } from "react-icons/cg"; 
import { AiOutlineCloseCircle } from "react-icons/ai"; 
import { Box, Button, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, Modal, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { isValidIBAN } from "../../../Components/Common/ibanValidation";
import { useDispatch, useSelector } from "react-redux";
import { editContractor, getContractors } from "../../../Components/Redux/Slice/DeliveryContractors/deliveryContractor";
import { useEffect, useState } from "react";

const style = {
    position: 'absolute',
    top: '49%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    minWidth : '500px',
    height: '95%',
    // minHeight: '600px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 2,
    py: 3,
    px:2,
    borderRadius : 5,
};

const EditProviderModal = ({open,close,t,data,type}) => {
    const dispatch = useDispatch()
    const contractor = useSelector(state=>state?.deliveryContractor)
    const form = useForm({
        defaultValues : {
            companyName : data?.companyName,
            companyNameAr : data?.companyNameAr,
            numberOfAgents : data?.numberOfAgents,
            payoutMethod : data?.payoutMethod,
            payoutAmountType: data?.payoutAmountType,
            payoutFrequency : data?.payoutFrequency,
            payoutPercentageValue : data?.payoutPercentageValue,
            payoutFlatFeesValue : data?.payoutFlatFeesValue,
            bankName : data?.bankName,
            ibanNumber : data?.ibanNumber,
        }
    })
    const {register,getValues,formState,handleSubmit,watch,control, reset, setValue} = form
    const {errors} = formState

    let id = data?.id
    const page = 1,
        size = 10
        
    const handleProviderSubmit = async (values)=>{
        await dispatch(editContractor({values,id})).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                close()
                dispatch(getContractors({page,size}))
            }
        } )

    }

    useEffect(() => {
        if (open) {
            reset({
                companyName : data?.companyName,
                companyNameAr : data?.companyNameAr,
                numberOfAgents : data?.numberOfAgents,
                payoutMethod : data?.payoutMethod,
                payoutAmountType: data?.payoutAmountType,
                payoutFrequency : data?.payoutFrequency,
                payoutPercentageValue : data?.payoutPercentageValue,
                payoutFlatFeesValue : data?.payoutFlatFeesValue,
                bankName : data?.bankName,
                ibanNumber : data?.ibanNumber,
            });
        }
    }, [open, reset, data]);


    watch('payoutMethod')
    watch('payoutAmountType')

    const [feesType, setfeesType] = useState(data?.payoutMethod)
    
    const handleRadioChangeHandler = (e) =>{
        const value = e.target.value;
        
        if (value === "FLAT_FEES_PER_TIME") {
            setValue('payoutPercentageValue',null)
        } else if (value === "PERCENTAGE_OF_DELIVERY_FEES") {
            setValue('payoutFlatFeesValue',null)
            setValue('payoutFrequency',null)
        } else if (value === "PERCENTAGE_OF_ORDER_AMOUNT") {
            setValue('payoutFlatFeesValue',null)
            setValue('payoutFrequency',null)
        }
    }

    const handlePayoutMethodRadioChangeHandler = (e) =>{
        const value = e.target.value;
        
        if ((value === "CASH" || value === "CHEQUE")) {
            setValue('ibanNumber',null)
            setValue('bankName',null)
        }
    }
    const feesTimeFrequancy = [
        {id:1,name:'payout per week',value:"PAYOUT_PER_WEEK"},
        {id:2,name:'payout per month',value:"PAYOUT_PER_MONTH"},
        {id:3,name:'payout per year',value:"PAYOUT_PER_YEAR"},
    ]
    return ( 
        <>
            <Modal
                open={open}
                onClose={()=>{
                    close()
                }}
                aria-labelledby="modal-3pl-provider"
                aria-describedby="modal-3pl-provider-description"
                dir
            >
            <Box sx={style} className='overflow-auto' dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                <div className="flex justify-between items-center border-b pb-2 mb-3">
                    <h1 className="text-xl text-[#333] capitalize font-semibold">{type === 'view' ? t('deliveryContractors.view_btn') : t('deliveryContractors.edit_btn')}</h1>

                    <IconButton 
                        onClick={()=>{
                            close()
                        }} 
                        className="[&>svg]:text-mainRed text-xl">
                        <AiOutlineCloseCircle />
                    </IconButton>
                </div>

                <form noValidate onSubmit={handleSubmit(handleProviderSubmit)} className="mt-4">
                    <section className="add-provider-form-wrapper grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2 [&>div>p]:text-sm [&>div>p]:text-mainRed [&>div>p]:capitalize [&>div>label]:block [&>div>label]:font-semibold [&>div>label]:text-sm [&>div>label]:text-[#353535] [&>div>label]:capitalize [&>div>label]:mb-1 [&>div>input]:text-mainColor [&>div>input]:p-3 [&>div>input]:w-full [&>div>input]:rounded-md [&>div>input]:bg-[#f3f6f9]">
                        <div>
                            <label>{t('deliveryContractors.columnsName.companyName')}</label>
                            <input type="text" 
                                className="placeholder:text-[#999] placeholder:capitalize placeholder:text-sm" 
                                placeholder={t('deliveryContractors.placeHolders.coName')} 
                                {...register('companyName',{
                                    required : {
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z0-9&\s]+$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                    }
                                })}

                                readOnly={type === 'view'}
                                disabled={type === 'view'}
                            />

                            {errors && <p>{errors?.companyName?.message}</p> }
                        </div>

                        <div>
                            <label>{t('deliveryContractors.columnsName.companyNameAr')}</label>
                            <input type="text" 
                                className="placeholder:text-[#999] placeholder:capitalize placeholder:text-sm" 
                                placeholder={t('deliveryContractors.placeHolders.coNameAr')} 
                                {...register('companyNameAr',{
                                    required : {
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern: {
                                        value: /^[\u0621-\u064A\s&0-9]+$/,
                                        message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & والارقام فقط' : 'Only Arabic letters, spaces, & sign and numbers are allowed'
                                    },
                                })}
                                readOnly={type === 'view'}
                                disabled={type === 'view'}
                            />
                            {errors && <p>{errors?.companyNameAr?.message}</p> }
                        </div>

                        <div className="col-span-2">
                            <label>{t('deliveryContractors.columnsName.payoutMethod')}</label>
                            <FormControl>
                            <Controller
                                name="payoutMethod"
                                control={control}
                                defaultValue=""
                                rules={
                                    {required : {
                                        value : true,
                                        message : t('common.required')
                                    }}
                                }
                                render={({ field}) => (
                                    <RadioGroup row aria-labelledby="row-radio-buttons-group-label" className="capitalize"
                                        {...field}
                                        onChange={(e)=>{
                                            field.onChange(e)
                                            handlePayoutMethodRadioChangeHandler(e)
                                        }}
                                    >
                                        <FormControlLabel disabled={type === 'view'} value="BANK_TRANSFER" control={<Radio />} label={t('deliveryContractors.form.bankTransfer')} />
                                        <FormControlLabel disabled={type === 'view'} value="CASH" control={<Radio />} label={t('deliveryContractors.form.cash')} />
                                        <FormControlLabel disabled={type === 'view'} value="CHEQUE" control={<Radio />} label={t('deliveryContractors.form.cheques')} />
                                    </RadioGroup>
                                )}
                            />

                            </FormControl>
                            
                            {errors && <p>{errors?.payoutMethod?.message}</p>}
                        </div>

                        {getValues('payoutMethod') === 'BANK_TRANSFER' ?
                            <div>
                                <label>{t('deliveryContractors.columnsName.bankName')}</label>
                                <input type="text" 
                                    className="placeholder:text-[#999] placeholder:capitalize placeholder:text-sm" 
                                    placeholder={t('deliveryContractors.placeHolders.bankName')} 
                                    {...register('bankName',{
                                        required : {
                                            value : true,
                                            message : t('common.required')
                                        }
                                    })}

                                    readOnly={type === 'view'}
                                    disabled={type === 'view'}
                                />
                                {errors && <p>{errors?.bankName?.message}</p>}
                            </div>

                            : null
                        
                        }

                        {getValues('payoutMethod') === 'BANK_TRANSFER' ?
                            <div>
                                <label>{t('deliveryContractors.columnsName.ibanNumber')}</label>
                                <input type="text" 
                                    className="placeholder:text-[#999] placeholder:capitalize placeholder:text-sm" 
                                    placeholder={t('deliveryContractors.placeHolders.ibanNumber')} 
                                    {...register('ibanNumber',{
                                        required : {
                                            value : true,
                                            message : t('common.required')
                                        },
                                        validate: value => isValidIBAN(value) || 'Invalid IBAN'
                                    })}

                                    readOnly={type === 'view'}
                                    disabled={type === 'view'}
                                />
                                {errors && <p>{errors?.ibanNumber?.message}</p>}
                            </div> 

                            : null 
                        }

                        <div className="col-span-2">
                            <label>{t('deliveryContractors.form.payoutCalcMethod')}</label>
                            <FormControl>
                                <Controller
                                    name="payoutAmountType"
                                    control={control}
                                    defaultValue=""
                                    rules={
                                        {required : {
                                            value : true,
                                            message : t('common.required')
                                        }}
                                    }
                                    render={({ field }) => (
                                        <RadioGroup row aria-labelledby="row-radio-buttons-group-label" className="capitalize"
                                            {...field}
                                            onChange={(e)=>{
                                                field.onChange(e)
                                                handleRadioChangeHandler(e)
                                            }}
                                        >
                                            <FormControlLabel disabled={type === 'view'} value="FLAT_FEES_PER_TIME" control={<Radio />} label={t('deliveryContractors.form.flatFeesPerTime')} />
                                            <FormControlLabel disabled={type === 'view'} value="PERCENTAGE_OF_DELIVERY_FEES" control={<Radio />} label={t('deliveryContractors.form.PERCENTAGE_OF_DELIVERY_FEES')} />
                                            {/* <FormControlLabel disabled={type === 'view'} value="DELIVERY_FEES" control={<Radio />} label={t('deliveryContractors.form.deliveryFees')} /> */}
                                            <FormControlLabel disabled={type === 'view'} value="PERCENTAGE_OF_ORDER_AMOUNT" control={<Radio />} label={t('deliveryContractors.form.percentOfOrder')} />
                                        </RadioGroup>
                                    )}
                                />

                            </FormControl>
                            {errors && <p>{errors?.payoutAmountType?.message}</p>}
                        </div>

                        {getValues('payoutAmountType') === 'FLAT_FEES_PER_TIME' ?

                            <>
                                <div className={`[&>div]:bg-[#f3f6f9] [&>div]:rounded-md`}>
                                    <label>{t('deliveryContractors.form.flatFeesTimeFreqancy')}</label>
                                    <FormControl sx={{width: '100%' }}>
                                        {feesType ? null : <InputLabel shrink={false} id="demo-simple-select-label" className="text-[#8a8a8a] text-sm">{t('deliveryContractors.form.flatFeesTimeFreqancyPlaceholder')}</InputLabel>}
                                        <Controller
                                            control={control}
                                            name="payoutFrequency"
                                            value={feesType}
                                            rules={{required:t('common.required')}}
                                            render={({field,fieldState: { error }})=>(
                                                <>
                                                    <Select {...field}
                                                        // label={t('deliveryContractors.form.flatFeesTimeFreqancyPlaceholder')}
                                                        displayEmpty
                                                        readOnly={type === 'view'}
                                                        disabled={type === 'view'}
                                                        // inputRef={{...register('payoutFrequency')}}
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="payoutFrequency"
                                                        // value={feesType}
                                                        onChange={(e)=>{
                                                            field.onChange(e)
                                                            setfeesType(e?.target?.value)
                                                        }}
                                                        // renderValue={(selected) => selected.join(', ')}
                                                        sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'8px 14px'},'& fieldset':{border : 0,},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9',},'& .css-zx5rfr' : {backgroundColor : '#f3f6f9'}}}
                                                    >
                                                        {feesTimeFrequancy?.map( (item)=>(
                                                            <MenuItem key={item?.id} value={item?.value}>
                                                                <ListItemText className='capitalize' primary={item?.name?.toLowerCase()?.replace(/_/g," ")} />
                                                            </MenuItem> 
                                                        ) )}
                                                    </Select>                                                    
                                                </>
                                            )}
                                            
                                        />
                                        
                                    </FormControl>

                                    {errors?.payoutFrequency && <p className="capitalize text-sm text-danger mt-1">{errors?.payoutFrequency?.message}</p>}
                                </div>
                                
                                <div>
                                    <label>{t('deliveryContractors.form.flatFeesAmount')}</label>
                                    <TextField
                                        className="w-full bg-[#f3f6f9]"
                                        id="payoutFlatFeesValue"
                                        {...register('payoutFlatFeesValue',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            max:{
                                                value:60000,
                                                message : t('common.max_value',{value : '60000'})
                                            },
                                            valueAsNumber : true
                                        })}
                                        // label="With normal TextField"
                                        // sx={{ m: 1, width: '25ch', }}
                                        sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
                                        }}
                                        inputProps={{
                                            min:0
                                        }}
                                        readOnly={type === 'view'}
                                        disabled={type === 'view'}
                                    />
                                    {errors && <p>{errors?.payoutFlatFeesValue?.message}</p>}
                                </div>
                            </>

                            :

                            (getValues('payoutAmountType') === 'PERCENTAGE_OF_DELIVERY_FEES' || getValues('payoutAmountType') === 'PERCENTAGE_OF_ORDER_AMOUNT' ) ?
                                <div className="col-span-2">
                                    <label>{t('deliveryContractors.form.payoutPercentageValue')}</label>
                                    <input type="text" 
                                        className="placeholder:text-[#999] placeholder:capitalize placeholder:text-sm" 
                                        placeholder={t('deliveryContractors.form.payoutPercentageValue')} 
                                        {...register('payoutPercentageValue',{
                                            required : {
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern : {
                                                value: /^[0-9]*$/,
                                                message: t('common.onlyNumbers')
                                            },
                                            valueAsNumber : true,
                                            max : {
                                                value : 100,
                                                message : t('common.max_value',{value : '100'})
                                            }
                                        })}
                                        readOnly={type === 'view'}
                                        disabled={type === 'view'}
                                    />
                                    {errors && <p>{errors?.payoutPercentageValue?.message}</p>}
                                </div>
                                :
                                
                                null
                        }

                        <div className="col-span-2">
                            <label>{t('deliveryContractors.columnsName.agentNumber')}</label>
                            <input type="number" 
                                className="placeholder:text-[#999] placeholder:capitalize placeholder:text-sm" 
                                placeholder={t('deliveryContractors.placeHolders.agentNumber')} 
                                {...register('numberOfAgents',{
                                    required : {
                                        value : true,
                                        message : t('common.required')
                                    },
                                    valueAsNumber : true
                                })}
                                min={0}
                                readOnly={type === 'view'}
                                disabled={type === 'view'}
                            />
                            {errors && <p>{errors?.numberOfAgents?.message}</p>}
                        </div>
                    </section>

                        <div className="mt-6 text-right absolute bottom-4 right-4">
                            {type === 'view' ?

                                <Button onClick={close} className="bg-gray-300 capitalize text-[#333]">{t('common.cancel')}</Button>
                                : 
                                <Button disabled={contractor?.loading} type="submit" className="disabled:bg-opacity-30 disabled:pointer-events-auto disabled:cursor-not-allowed text-white bg-mainColor min-w-[180px] text-sm capitalize py-3">{contractor?.loading ? <CgSpinnerTwoAlt className="animate-spin" /> : t('deliveryContractors.edit_btn')}</Button>
                            }
                        </div>
                </form>

            </Box>
        </Modal>
        </>
    );
}

export default EditProviderModal;