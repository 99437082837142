export function isValidIBAN(iban) {
    // Remove any spaces from the IBAN
    const normalizedIBAN = iban.replace(/\s/g, '');

    // IBAN format check (ISO 13616-1)
    const ibanPattern = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;

    if (!ibanPattern.test(normalizedIBAN)) {
        return false;
        // alert('Wrong IBAN Number')
    }

    // Move the first four characters to the end
    const rearrangedIBAN = normalizedIBAN.substring(4) + normalizedIBAN.substring(0, 4);

    // Convert letters to numbers (A = 10, B = 11, ..., Z = 35)
    const numericIBAN = rearrangedIBAN.replace(/[A-Z]/g, (char) => {
    return (char.charCodeAt(0) - 55).toString();
    });

    // Calculate the remainder of the numeric IBAN when divided by 97
    const remainder = BigInt(numericIBAN) % 97n;

    // If the remainder is 1, the IBAN is valid
    return remainder === 1n ? true : 'Invalid IBAN';
}