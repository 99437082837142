import { useTranslation } from "react-i18next";
import defImg from '../../../../../Assets/imgs/MenuItem.png'
const TopBuyingItem = ({item}) => {
    // console.log(item);
    const {t,i18n} = useTranslation()

    return ( 
        <div className="best-buying-item mb-6">
            <div className={`flex ${ localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} justify-between items-center`}>
                <div className={`flex items-center capitalize left-section-item-top ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                    <div className={`w-[70px] h-[70px] rounded-lg overflow-hidden ${localStorage?.getItem('lang') === 'ar' ? 'ml-3' : 'mr-3'} `}>
                        <img src={item?.image ? item?.image : defImg} alt={item?.name} className='w-full h-full' />
                    </div>

                    <div className='text-left'>
                        <p className='text-[#464E5F] font-semibold'>{localStorage?.getItem('lang') === 'ar' ? item?.nameAr : item?.name}</p>
                        <p className='text-sm text-mainYellow'>{localStorage?.getItem('lang') === 'ar' ? item?.category?.nameAr : item?.category?.name}</p>
                        <p className='text-sm text-[#333]'>{item?.price} SAR</p>
                    </div>
                </div>

                <div className='right-section-item-top font-semibold text-mainColor text-sm'>
                    <span className='block px-3 py-[6px] bg-[#442b7e1c] rounded-md text-center min-w-[50px] max-w-[100px]'>{item?.count}</span>
                </div>
            </div>
        </div>
    );
}

export default TopBuyingItem;