import { Link } from "react-router-dom";

const AltFooter = () => {
    return ( 
        <article className="alt-header-wrapper min-h-[60px] w-full sticky flex justify-center bottom-0 bg-mainColor">
            <ul className="flex items-center gap-x-12 capitalize text-white">
                <li>
                    <Link to='/termsconditions'>terms & conditions</Link>
                </li>

                <li>
                    <Link to='/privacypolicies'>Privacy & Policies</Link>
                </li>

                <li>
                    <Link to='/aboutus'>About us</Link>
                </li>
            </ul>
        </article>
    );
}

export default AltFooter;