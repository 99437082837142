import logo from '../../Assets/imgs/logo.svg'

const AltHeader = () => {
    return ( 
        <article className="alt-header-wrapper min-h-[60px] w-full sticky bg-mainColor">
            <div className="relative pl-4 p-2">
                <img src={logo} alt='takein-logo' />
            </div>
        </article>
    );
}

export default AltHeader;