
import { AiOutlineCloseCircle } from "react-icons/ai"; 
import { Box, Button, IconButton, Modal } from "@mui/material";
import { DndContext } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import PriorityItem from "./priortyModalItem";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    minWidth: "400px",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 3,
    p: 4,
    borderRadius : 5,
};

const PriorityModal = ({open,close,data,t,move}) => {
    
    const handleDragGroup = (e)=>{
        const {active,over} = e
        if (over && active.id !== over.id) {
            const oldIndex = data?.findIndex((field) => field.id === active.id);
            const newIndex = data?.findIndex((field) => field.id === over.id);
            move(oldIndex, newIndex);
            // console.log(oldIndex);
            
        }
        
    }
        
    return ( 
        <>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                dir
            >
                <Box sx={style} className='overflow-auto' dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                    <section className="priority-modal-header flex items-center justify-between">
                        <div>
                            <h2 className="text-lg  font-semibold">{t('common.editPiriorty')}</h2>
                        </div>

                        <div className="text-xl w-fit cursor-pointer">
                            <IconButton onClick={close}><AiOutlineCloseCircle  className="text-mainRed"/></IconButton>
                        </div>
                    </section>

                    <section className="priority-content mt-6">
                        <DndContext
                            onDragEnd={handleDragGroup}
                            // modifiers={[restrictToVerticalAxis]}
                        >
                            <div className="max-h-[500px] overflow-y-auto">
                                <SortableContext items={data}>
                                    {data?.map( (group,index)=>(
                                        <PriorityItem key={group?.id} group={group} t={t} />
                                    ) )}
                                </SortableContext>
                            </div>
                        </DndContext>
                    </section>

                    {/* <section className="mt-6 flex items-center justify-end">
                        <Button onClick={close} className="capitalize text-white bg-mainColor min-w-[180px]">{t('common.save_changes')}</Button>
                    </section> */}
                </Box>

            </Modal>
        </>
    );
}

export default PriorityModal;