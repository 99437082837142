import { Box, Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { dateFormate } from "../../../Components/Common/dateFormate";
import { useForm } from "react-hook-form";
import { getOrderTickets, toggleTicket } from "../../../Components/Redux/Slice/Tickets/tickets";
import { TableStyles } from "../../../Components/Common/tableStyle";

function ActionCell({ data,orderID }) {

    let id = data?.id
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,reset} = form
    const {errors} = formState
    const {auth} = useSelector(state=>state)

    const {tickets} = useSelector(state=>state)
    const dispatch = useDispatch()

    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth : '500px',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        py: 4,
        px:2,
        borderRadius : 5,
    };

    const [open, setopen] = useState(false);
    const [type, settype] = useState('');
    
    useEffect(() => {
        reset()
    }, [type])
    
    const handleOpen = (e) => {
        setopen(true)
        settype(e);
        // console.log(e);
    };
    const handleClose = () => {
        setopen(false)
        reset()
    };

    const submitHandler = async (values)=>{        
        await dispatch(toggleTicket({values,id,type})).then( (e)=>{
            if(e?.payload?.message === "SUCCESS"){
                dispatch(getOrderTickets(orderID))
                handleClose()
                reset()
            }
        } )
    }

    return (
        <>
            <div className='action-wrapper relative'>
                {
                    (data?.status === 'OPEN' || data?.status === 'REOPENED')
                        ? 
                            <div className="flex gap-x-3">
                                {auth?.role === 'BACK_OFFICE' ? <Button disabled={tickets?.loading} onClick={()=>handleOpen('resolve')} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainGreen text-white text-sm capitalize">Resolve</Button> : null}
                                {auth?.role === 'BACK_OFFICE' || auth?.role === 'CUSTOMER_SERVICE'}<Button disabled={tickets?.loading} onClick={()=>handleOpen('close')} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainColor text-white text-sm capitalize">Close</Button>
                            </div>
                        
                        : data?.status === 'RESOLVED' && auth?.role === 'CUSTOMER_SERVICE'  ? 

                        <div>
                            <Button disabled={tickets?.loading} onClick={()=>handleOpen('re-open')} className="disabled:opacity-50 disabled:cursor-not-allowed bg-[#c88256] text-white text-sm capitalize">Re-Open</Button>
                        </div>

                        : null
                }
            </div>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="flex justify-between items-center border-b pb-2 mb-3">
                    <h1 className="text-xl text-[#333] capitalize font-semibold">
                        {type === 'resolve' ? 'Ticket Resolution' : type === 'close' ? 'Close Ticket' : type === 're-open' ? 'Re-open' : ''}
                        
                    </h1>

                    {/* <div>
                        <FaTimesCircle onClick={handleClearanceClose} className="text-mainRed text-xl cursor-pointer" />
                    </div> */}
                </div>

                <form noValidate onSubmit={handleSubmit(submitHandler)}>
                    <div className=" [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>textarea]:bg-[#f3f6f9] [&>div>textarea]:w-full [&>div>textarea]:p-3 [&>div>textarea]:focus:outline-slate-400 [&>div>textarea]:rounded-md [&>div>textarea]:text-mainColor [&>div>textarea]:mb-1 [&>div>textarea]:resize-none">
                        
                        <div>
                            <label> {type === 'resolve' ? 'summary' : type === 'close' ? 'Reason' : type === 're-open' ? 'Reason' : '' } </label>
                            <textarea
                                id={`${type === 'resolve' ? 'resolution' : type === 'close' ? 'reason' : type === 're-open' ? 'reason' : ''}`}
                                {...register(`${type === 'resolve' ? 'resolution' : type === 'close' ? 'reason' : type === 're-open' ? 'reason' : ''}`,{
                                    required:{
                                        value : true,
                                        message : 'This field is required'
                                    }
                                })}
                            />
                            
                            { 
                                type === 'resolve' ? 
                                    <p className="capitalize text-sm text-danger">{errors?.resolution?.message}</p>
                                :
                                type === 'close' ? 
                                    <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p>
                                :
                                type === 're-open' ? 
                                    <p className="capitalize text-sm text-danger">{errors?.reason?.message}</p>
                                : null
                            }
                        </div>
                        
                        <div className="flex justify-end item-center gap-x-4 mt-6 mb-0 capitalize text-sm [&>button]:text-white [&>button]:rounded-lg [&>button]:px-4">
                            <Button className="bg-gray-400" onClick={handleClose}>Cancel</Button>
                            <Button className="bg-mainColor disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed" type="submit" disabled={''}>submit</Button>
                        </div>
                    </div>
                </form>
            </Box>
        </Modal>
        </>
    );
}

const ExpandedComponent = ({ data }) => {
    return(
        <div className="mb-2">
            <p className="bg-[#dfdfdf96] py-5 px-3"><span className="font-semibold text-base text-[#555] mr-2">{localStorage.getItem('lang') === 'ar' ? 'الوصف' : 'Descrption'}: </span> <span className="text-sm text-[#4343439e]">{data?.description}</span></p>
        </div>
    )
};


const OrderTicket = ({tickets,orderID,t}) => {
    const dispatch = useDispatch()
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)

    // console.log(tickets);
    const customStyles = {
        headRow: {
            style: {
            border: 'none',
            backgroundColor : '#eee'
            },
        },
        headCells: {
            style: {
                color: '#202124',
                fontSize: '14px',
                position : 'relative'
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: '#442b7e12',
                borderBottomColor: '#FFFFFF',
                borderRadius: '5px',
                outline: '1px solid #FFFFFF',
            },
        },
        pagination: {
            style: {
                border: 'none',
            },
        },
        cells: {
            style:{
                padding : '5px 12px',
                fontSize : '12px'
            }
        }
    };

    const columns = [
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'الرقم التعريفى' : 'ID'}`,
            selector: row => row.id,
            width:'80px'
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'تاريخ الانشاء' : 'Created Date'}`,
            cell: row => dateFormate(row.createdAt),
            sortable: false,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
            selector: row => <div className={` capitalize font-bold ${row?.status === 'OPEN' ? 'text-mainRed' : row?.status === 'CLOSED' ? 'text-mainColor' : row?.status === 'RESOLVED' ? 'text-mainGreen' : row?.status === 'REOPENED' ? 'text-[#c88256]' : ''}`}>{row?.status?.toLowerCase()}</div>,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'النوع' : 'Type'}`,
            selector: row => <span className="capitalize">{row?.type?.toLowerCase()?.replace(/_/g,' ')}</span>,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            cell: row=>(
                <>
                    <ActionCell data={row} orderID = {orderID}/>
                </>
            )
                
        },
    ];

    const data = tickets?.orderTickets?.data

    // useEffect(() => {
    //     dispatch(getCouriers({page,size}))
    // }, [dispatch])

    // const handlePageChange = page => {
    //     setpage(page);
    // };

    // const handleRowChange = rows => {
    //     setsize(rows);
    // };

    // useEffect(() => {
    //     dispatch(getCouriers({page,size,col,dir,searchValue}))
    // }, [dispatch, page,size])


    return ( 
        <>
            <section className="datatable-wrapper mt-5 bg-white relative">
                <DataTable
                    direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                    columns={columns}
                    data={data}
                    // pagination
                    // paginationPerPage = {size}
                    // paginationRowsPerPageOptions = {[10,50,100]}
                    // paginationServer
                    // paginationTotalRows={couriers?.couriers?.meta?.total}
                    // onChangePage={handlePageChange}
                    // onChangeRowsPerPage={handleRowChange}
                    customStyles={TableStyles}
                    highlightOnHover
                    // selectableRows
                    // selectableRowsHighlight
                    sortServer
                    // onSort={handleRemoteSort}
                    // onSelectedRowsChange={(e)=>console.log(e)}
                    expandableRows 
                    expandableRowsComponent={ExpandedComponent}
                    striped
                />

                {/* {couriers?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null} */}
            </section>
        </>
    );
}

export default OrderTicket;