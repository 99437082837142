import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getDeliveryValues = createAsyncThunk("deliveryFunc", async(_, {rejectWithValue,dispatch})=>{
    
    const url = `/driver-billing-config`;
    
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const updateDeliveryValues = createAsyncThunk("updateDeliveryValuesFuncs", async(values, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await toast.promise(axios.post(`/driver-billing-config`,values),
        {
            pending: 'loading...',
            success: 'Charges Updated Successfully 👌',
            // error: 'Promise rejected 🤯'
        },{toastId : "ChargesUpdateToast"})
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const calcCharges = createAsyncThunk("calcChargesFuncs", async(values, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    try {
        const res = await axios.post(`/delivery-calculator`,values)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    deliveryValues : [],
    calculatedCharges : 0,
    restaurantAddress : null,
    customerAddress : null,
    error : null
}
export const DeliverySlice = createSlice({ 
    name: "DeliverySlice-Func", 
    initialState,
    reducers: {
        getResAddress: (state, action) => {state.restaurantAddress = action.payload},
        getCustAddress: (state, action) => {state.customerAddress = action.payload},
        resetCalcCharges : (state,action) => {state.calculatedCharges = 0}
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getDeliveryValues.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getDeliveryValues.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.deliveryValues = action.payload
            // console.log(action.payload);
        })

        .addCase(getDeliveryValues.rejected, (state,action)=>{
            state.loading = false;
            state.deliveryValues = ""
            state.error = action.payload
        })
        .addCase(updateDeliveryValues.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(updateDeliveryValues.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.deliveryValues = action.payload
            // console.log(action.payload);
        })

        .addCase(updateDeliveryValues.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(calcCharges.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(calcCharges.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.calculatedCharges = action?.payload
            // console.log(action.payload);
        })

        .addCase(calcCharges.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
            state.calculatedCharges = null
        })

    }
});

export const {getResAddress,getCustAddress,resetCalcCharges} = DeliverySlice.actions

export default DeliverySlice.reducer

