import AltFooter from "./altFooter";
import AltHeader from "./altHeader";

const AltLayout = (props) => {
    return ( 
        <>
            <AltHeader/>
            {props.children}
            <AltFooter />
        </>
     );
}
 
export default AltLayout;