import { AiOutlineLoading3Quarters } from "react-icons/ai"; 
import { Box, Button, Chip, FormControl, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {MdOutlineModeEditOutline} from 'react-icons/md'
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import previewIcon from '../../../Assets/imgs/previewIcon.svg'
import { useLocation, useNavigate } from "react-router-dom";
import { addrestaurant, getDeliveryTypes } from "../../../Components/Redux/Slice/Restaurants/restaurantsSlice";
import { getTags } from "../../../Components/Redux/Slice/Tags/tags";
import { getcuisines } from "../../../Components/Redux/Slice/Cuisines/cuisines";
import { getMealsType } from "../../../Components/Redux/Slice/MealsType/mealsType";
import {addFile} from '../../../Components/Redux/Slice/Upload/file'
import Overlay from "../../../Components/Common/overlay";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BreadCrumb from "../../../Components/BreadCrumb/breadCrumb";
import Astrisks from "../../../Components/Common/astrisk";
import dayjs from "dayjs";
import { isValidIBAN } from "../../../Components/Common/ibanValidation";
import { useTranslation } from "react-i18next";
import Compressor from "compressorjs";


const AddRestaurant = () => {
    const {t, i18n} = useTranslation()
    const MySwal = withReactContent(Swal);
    const {singleRestaurant} = useSelector(state=>state?.restaurants)
    const dispatch = useDispatch()
    const location = useLocation()
    const [img, setimg] = useState(null);
    const [preview, setPreview] = useState();
    const [profilePreview,setprofilePreview] = useState()
    const [isImage, setisImage] = useState(null)
    const [isBanner, setisBanner] = useState(null)
    const [isCommerical, setisCommerical] = useState(null)
    const [isIban, setisIban] = useState(null)
    const [isVat, setisVat] = useState(null)
    const [opentime, setopentime] = useState(null)
    const [closetime, setclosetime] = useState(null)
    const [ordertime, setordertime] = useState(null)
    const [discountType, setdiscountType] = useState([]);

    const navigate = useNavigate()
    const resTags = useSelector(state=>state?.tags) // all tags available
    const resCuisines = useSelector(state=>state?.cuisines) // all cuisine available
    const resMealsType = useSelector(state=>state?.mealsType) // all cuisine available
    const resDeliveryType = useSelector(state=>state?.restaurants?.deliveryTypes) // all delivery type available


    useEffect(() => {
        dispatch(getTags())
        dispatch(getcuisines())
        dispatch(getMealsType())
        dispatch(getDeliveryTypes())
    }, [dispatch])

    // console.log(defMealsType);
    

    // useEffect(() => {

    //     setPreview(singleRestaurant?.data?.bannerImage)
    //     setprofilePreview(singleRestaurant?.data?.logoUrl)
    // }, [dispatch, location.pathname, location?.state?.id, singleRestaurant?.data?.bannerImage, singleRestaurant?.data?.logoUrl])

    // console.log(singleRestaurant);

    const form = useForm({
        defaultValues: {
            commericalRegFile: null,
            tags : [],
            cuisines : [],
            mealTypes : [],
        },
    })
    const {register,control,handleSubmit,formState,setValue,getValues,watch,trigger} = form
    const {errors, isDirty} = formState
    // console.log(errors);
    const maxSize = 1024*1024*5
    const compressionLimit = 55
    const acceptedImageTypes = ['image/jpeg', 'image/png'];
    const acceptedFiles = ['image/jpeg', 'image/png','application/pdf'];

    const logoRef = useRef()
    const bannerRef = useRef()
    const commericalRef = useRef()
    const ibanRef = useRef()
    const vatRef = useRef()

    const [loadBannerCompress,setloadBannerCompress] = useState(false)

    const handleBannerImageChange = async (e)=> {
        const file = e.target.files[0];
        
        if(file){
            // setValue('bannerImage',e.target.files[0])            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                bannerRef.current.value = ""
                setisBanner(null)
                setPreview(null)
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الصورة يجب ان تكون (jbg, jbeg, png) فقط' : 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                bannerRef.current.value = ""
                setisBanner(null)
                setPreview(null)
            } else {
                if((file.size / 1024) > compressionLimit){
                    setloadBannerCompress(true)
                    new Compressor(file, {
                        quality: 0.6,
                        maxWidth : 600,
                        maxHeight : 600,


                        success: (compressedResult) => {
                            setloadBannerCompress(false)
                            setisBanner(compressedResult)                            
                        },
                    });
                } else {
                    setisBanner(file)
                }
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    // setValue('bannerImage', reader.result);
                    setPreview(reader.result);
                }
            }

            // console.log('file',file);
            // console.log('compressed',compressedFile);
        } else {
            setisBanner()
        }
    }

    // console.log(isBanner?.name);

    const [loadImgCompress,setloadImgCompress] = useState(false)

    const handleProfileImageChange = (e)=> {
        const file = e.target.files[0];
        
        if(file){
            // setValue('logo',e.target.files[0])
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                logoRef.current.value = ""
                setisImage(null)
                setprofilePreview(null)
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الصورة يجب ان تكون (jbg, jbeg, png) فقط' : 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                logoRef.current.value = ""
                setisImage(null)
                setprofilePreview(null)
            } else {
                if((file.size / 1024) > compressionLimit){
                    setloadImgCompress(true)
                    new Compressor(file, {
                        quality: 0.6,
                        maxWidth : 600,
                        maxHeight : 600,


                        success: (compressedResult) => {
                            setloadImgCompress(false)
                            setisImage(compressedResult)                            
                        },
                    });
                } else {
                    setisImage(file)
                }
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    // setValue('logo', reader.result);
                    setprofilePreview(reader.result);
                }
            }
        }
    }

    const handleCommericalImageChange = (e)=> {
        const file = e.target.files[0];
        // console.log(file.type);
        if(file){
            // setValue('bannerImage',e.target.files[0])            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                commericalRef.current.value = ""
                setisCommerical(null)
            } else if(!acceptedFiles.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الملف يجب ان تكون (jbg, jbeg, png, pdf) فقط' : 'File must be in type of (jpg, jpeg, png, pdf) only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                commericalRef.current.value = ""
                setisCommerical(null)
            } else {
                setisCommerical(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                setValue('commericalRegFile',file,{shouldValidate:true});
                // reader.onload = ()=>{
                //     // setValue('bannerImage', reader.result);
                //     setPreview(reader.result);
                // }
            }
        }
    }

    const handleibanImageChange = (e)=> {
        const file = e.target.files[0];

        if(file){
            // setValue('bannerImage',e.target.files[0])            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                ibanRef.current.value = ""
                setisIban(null)
            } else if(!acceptedFiles.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الملف يجب ان تكون (jbg, jbeg, png, pdf) فقط' : 'File must be in type of (jpg, jpeg, png, pdf) only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                ibanRef.current.value = ""
                setisIban(null)
            } else {
                setisIban(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                setValue('ibanFile',file,{shouldValidate:true});
                // reader.onload = ()=>{
                //     // setValue('bannerImage', reader.result);
                //     setPreview(reader.result);
                // }
            }
        }
    }

    const handleVatImageChange = (e)=> {
        const file = e.target.files[0];

        if(file){
            // setValue('bannerImage',e.target.files[0])            
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                vatRef.current.value = ""
                setisVat(null)
            } else if(!acceptedFiles.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'صيغة الملف يجب ان تكون (jbg, jbeg, png, pdf) فقط' : 'File must be in type of (jpg, jpeg, png, pdf) only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                vatRef.current.value = ""
                setisVat(null)
            } else {
                setisVat(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                setValue('vatFile',file,{shouldValidate:true});
                // reader.onload = ()=>{
                //     // setValue('bannerImage', reader.result);
                //     setPreview(reader.result);
                // }
            }
        }
    }

    useEffect(() => {
        // console.log(img);       
    }, [img])

    const [shareType,setshareType] = useState('')
    const handleOfferTypeSelectChange = (event) => {
        const { target: { value },} = event;
        setdiscountType(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
        setValue('takeinSharePerOrderType', value,{shouldValidate:true})
        setshareType(value)
    }; 

    // handle tags select
    const [openTag, setOpenTag] = useState(false);

    const handleCloseTag = () => {
        setOpenTag(false);
    };
    const handleOpenTag = () => {
        setOpenTag(true);
    };

    const [tags, settags] = useState([]); // used to handle fetched 
    const [tagid, settagid] = useState([]);

    const tagsList = resTags?.tags?.data?.map( item=> item )

    // console.log(defaultTagsList);
    
    const handleTagsChipSelectChange = (event) => {
        const selectedTags = event.target.value;
        if(selectedTags){
            const tagID = selectedTags?.map((tag) => ({ id: tag.id }));
            let tagsIDJson = JSON.stringify(tagID)
            settags(selectedTags);
            setValue('tags', tagID,{shouldValidate:true});
            // setValue('tags', tagsIDJson);
        }
    };
    
    useEffect(() => {
        // console.log(getValues())
        // console.log(tags);
        // console.log(tagid);
    }, [getValues, tagid, tags, tagsList]);


    // handle cuisines select

    const [openCuisine, setopenCuisine] = useState(false);

    const handleCloseCuisine = () => {
        setopenCuisine(false);
    };
    const handleOpenCuisine = () => {
        setopenCuisine(true);
    };
    
    const [cuisines, setcuisines] = useState([]);
    const [cuisinesid, setcuisinesid] = useState([]);

    const cuisinesList = resCuisines?.cuisines?.data?.map( item=> item )

    const handleCuisinesChipSelectChange = (event) => {
        const selectedCuisines = event.target.value;
        if (selectedCuisines) { // Check if selectedCuisines is not undefined
            const CuisinesID = selectedCuisines?.map((cuisine) => cuisine ? { id: cuisine.id } : null);
            let CuisinesIDJson = JSON.stringify(CuisinesID)
            setcuisines(selectedCuisines);
            setValue('cuisines', CuisinesID,{shouldValidate:true});
        }
    };


    // handle MealsType select

    const [openMeals, setopenMeals] = useState(false);

    const handleCloseMeals = () => {
        setopenMeals(false);
    };
    const handleOpenMeals = () => {
        setopenMeals(true);
    };

    const [meals, setmeals] = useState([]); // used to handle fetched 
    const [mealsid, setmealsid] = useState([]);

    const mealsList = resMealsType?.mealsType?.data?.map( item=> item )

    // console.log(defaultTagsList);
    
    
    const handleMealsChipSelectChange = (event) => {
        const selectedMeals = event.target.value;
        if(selectedMeals){
            const mealsID = selectedMeals?.map((meal) => ({ id: meal.id }));
            let mealsIDJson = JSON.stringify(mealsID)
            setmeals(selectedMeals);
            setValue('mealTypes', mealsID,{shouldValidate:true});
            // setValue('mealTypes', mealsIDJson);
        }
    };

    
    const [deliveryType, setdeliveryType] = useState([]);
    
    const deliveryList = resDeliveryType?.data?.map( item=> item )

    const handleDeliveryTypeChipSelectChange = (event) => {
        const selectedDeliveryType = event.target.value;
        const deliveryTypeID = selectedDeliveryType?.map((type) => ({ id: type.id }));
        let DeliveryIDJson = JSON.stringify(deliveryTypeID)

        setdeliveryType(selectedDeliveryType);
        setValue('deliveryTypes', deliveryTypeID,{shouldValidate:true});
        // setValue('deliveryTypes', DeliveryIDJson);
    };

    const {restaurants} = useSelector(state=>state)

    // console.log(restaurants?.addLoading);
    // console.log(getValues());
    const submitHandler = async (data)=>{

        let updatedData // Create a new object with all original data

        let ImageResponse = null,
            bannerResponse = null,
            commericalResponse = null,
            ibanResponse = null,
            vatResponse = null

            const image = new FormData()
            image.append('file',isImage)

            const banner = new FormData()
            banner.append('file',isBanner)

            const commerical = new FormData()
            commerical.append('file',isCommerical)

            const iban = new FormData()
            iban.append('file',isIban)

            const vat = new FormData()
            vat.append('file',isVat)

            if(isImage !== null){
                ImageResponse = await dispatch(addFile(image));
            } else {
                toast.error('Please Select Image')
            }
    
            if(isBanner !== null){
                bannerResponse = await dispatch(addFile(banner))   
            }
    
            if(isCommerical !== null){
                commericalResponse = await dispatch(addFile(commerical))
            } else {
                toast.error('Please Select Commerical Letter Image')
            }
    
            if(isIban !== null){
                ibanResponse = await dispatch(addFile(iban))
            } else {
                toast.error('Please Select IBAN Letter Image')
            }
    
            if(isVat !== null){
                vatResponse = await dispatch(addFile(vat))
            } else {
                toast.error('Please Select Vat Letter Image')
            }

            updatedData = {
                ...data, // include all other form data
                logoUrl : ImageResponse?.payload?.data?.url,
                bannerImage : bannerResponse?.payload?.data?.url,
                commercialRegisterationLetter : commericalResponse?.payload?.data?.url,
                ibanLetter : ibanResponse?.payload?.data?.url,
                vatCertificate : vatResponse?.payload?.data?.url,
            }

            if(isImage && isCommerical && isVat){
                await dispatch(addrestaurant(updatedData)).then((e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/restaurant') : null)
            } else {
            toast.error(t('common.missing_values'))
            }


        // if(isImage && isCommerical && isVat){
        //     const image = new FormData()
        //     image.append('file',isImage)

        //     const banner = new FormData()
        //     banner.append('file',isBanner)

        //     const commerical = new FormData()
        //     commerical.append('file',isCommerical)

        //     const iban = new FormData()
        //     iban.append('file',isIban)

        //     const vat = new FormData()
        //     vat.append('file',isVat)

        //     bannerResponse = await dispatch(addFile(banner))
        //     ImageResponse = await dispatch(addFile(image));
        //     commericalResponse = await dispatch(addFile(commerical))
        //     ibanResponse = await dispatch(addFile(iban))
        //     vatResponse = await dispatch(addFile(vat))

        //     if(ImageResponse && bannerResponse && commericalResponse && ibanResponse){
        //         updatedData = {
        //             ...data, // include all other form data
        //             logoUrl : ImageResponse?.payload?.data?.url,
        //             bannerImage : bannerResponse?.payload?.data?.url,
        //             commercialRegisterationLetter : commericalResponse?.payload?.data?.url,
        //             ibanLetter : ibanResponse?.payload?.data?.url,
        //             vatCertificate : vatResponse?.payload?.data?.url,
        //         }
        //     }

        //     const keysToUpdate = [
        //         "acceptsCurbsidePickup",
        //         "acceptsDelivery",
        //         "acceptsOnlinePayment",
        //         "acceptsPromocode",
        //         "alwaysOpen"
        //     ];
                
        //     keysToUpdate.forEach((key) => {
        //         if (data.hasOwnProperty(key)) {
        //         updatedData[key] = data[key] === "true"; // Convert the string value to boolean
        //         }
        //     });

        //     await dispatch(addrestaurant(updatedData)).then((e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/restaurant') : null)
        // }else {
        //     toast.error('Please Select Image')
        // }


        // const formData = new FormData();
        // formData.append('acceptsCurbsidePickup',data.acceptsCurbsidePickup)
        // formData.append('acceptsDelivery',data.acceptsDelivery)
        // formData.append('acceptsOnlinePayment',data.acceptsOnlinePayment)
        // formData.append('alwaysOpen',data.alwaysOpen)
        // formData.append('bannerImage',data.bannerImage[0])
        // formData.append('cuisines',data.cuisines)
        // formData.append('email',data.email)
        // formData.append('hotline',data.hotline)
        // formData.append('logo',data.logo[0])
        // formData.append('mealTypes',data.mealTypes)
        // formData.append('minimumCharge',data.minimumCharge)
        // formData.append('name',data.name)
        // formData.append('phoneNumber',data.phoneNumber)
        // formData.append('tags',data.tags)

        // dispatch(addrestaurant(data)).then((e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/restaurant') : null)
        
        // console.log(data);

        // for (let [key, value] of formData.entries()) {
        //     console.log(key, value);
        //   }
    }

    useEffect(() => {
        // console.log(opentime);
        // let openingTime = opentime ? opentime?.$H + ":" + opentime?.$m + ":" + opentime?.$s : null
        let openingTime = opentime ? new Date(opentime) : null
        let closingTime = closetime ? new Date(closetime) : null
        let orderTime = ordertime ? new Date(ordertime) : null
        setValue('openingTime',openingTime?.toTimeString()?.slice(0,5))
        setValue('closingTime',closingTime?.toTimeString()?.slice(0,5))
        setValue('orderDeadline',orderTime?.toTimeString()?.slice(0,5))
    }, [opentime,closetime,ordertime])

    const {file} = useSelector(state=>state)

    // console.log(errors);
    const paths = [
        {path:'/dashboard/restaurant',name:'restaurants', nameAr:"المطاعم"},
        {path:'',name:'add restaurant', nameAr:"اضف مطعم"},
    ]

    return ( 
        <article className="profile-wrapper mt-6">
            <div className=" relative flex flex-wrap md:flex-nowrap  justify-between gap-4 items-start [&>section]:basis-full [&>section]:rounded-md mb-32">
                <section className="profile-left-section bg-white p-5">
                    <div className="profile-title text-[#333333] font-semibold capitalize text-xl">
                        <h2 className="font-playfair">{t('restaurants.form.add_title')}</h2>
                    </div>

                    <div><BreadCrumb paths={paths} seperator='/' /></div>
                    
                    <form className="profile-image-wrapper mt-5" onSubmit={handleSubmit(submitHandler)} noValidate>

                        <div className="restaurant-preview-wrappe w-full h-[200px] relative">
                            <div className="defaultImage w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,.3)] flex items-center justify-center">
                                <img src={previewIcon} alt="default" className="w-[150px] h-[150px] m-auto object-cover" />
                            </div>
                            
                            <div className="banner-preview-wrapper w-full h-full relative">
                                <div className="absolute top-0 right-0 cursor-pointer w-full h-full">
                                    <div className="w-full h-full ml-auto relative cursor-pointer">
                                        <input ref={bannerRef} type="file" id="file" accept="image/*" onChange={handleBannerImageChange} className="w-full h-full absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0 z-10 opacity-1" />
                                        {/* <span className="block absolute right-0 text-sm bg-[rgba(22,22,22,0.38)] p-1 text-white cursor-pointer capitalize w-full h-full rounded-md text-center">edit banner image</span> */}
                                        <span className="block absolute right-0 text-sm capitalize bg-[#26262749] p-2 rounded-sm text-white cursor-pointer">{t('restaurants.form.edit_banner_lable')}</span>

                                    </div>
                                </div>
                                {/* <div className="absolute top-2 right-2 cursor-pointer w-full">
                                    <div className="w-[150px] h-[30px] ml-auto relative">
                                        <input type="file" id="file" name="bannerImage" accept="image/*" onChange={handleBannerImageChange} className="w-full h-full absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0 z-10 opacity-1" />
                                        <span className="block absolute right-0 text-sm bg-[rgba(22,22,22,0.38)] p-1 text-white cursor-pointer capitalize w-full h-full rounded-md text-center">edit banner image</span>
                                    </div>
                                </div> */}
                                {preview && <img src={preview} alt="Preview" className="w-full h-full object-cover" />}
                                
                                {loadBannerCompress ? <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,.4)] text-white flex items-center justify-center">
                                    <p className="w-fit animate-spin text-3xl"><AiOutlineLoading3Quarters /></p>
                                </div> : null}
                            </div>

                            <div className="restaurant-profile-image-preview absolute -bottom-[33%] w-[150px] h-[150px] bg-[rgba(0,0,0,.3)] left-6 rounded-lg">
                                <div className="defaultImage w-full h-full z-10 absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center rounded-lg">
                                    <img src={previewIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                                </div>

                                <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full">
                                    <div className="absolute top-0 right-0 w-full h-full rounded-lg ">
                                        <input ref={logoRef} type="file" id="file" accept="image/*" onChange={handleProfileImageChange} className="absolute right-0 cursor-pointer opacity-1 z-10 opacity-0 w-full h-full" />
                                        <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span>
                                    </div>
                                    {profilePreview && <img src={profilePreview} alt="restaurant Profile" className="w-full h-full rounded-lg object-cover" />}
                                    
                                    {loadImgCompress ? <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,.4)] text-white flex items-center justify-center">
                                        <p className="w-fit animate-spin text-3xl"><AiOutlineLoading3Quarters /></p>
                                    </div> : null}
                                </div>
                                
                                {/* <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full">
                                    <div className="absolute top-0 right-0 w-full h-full rounded-lg ">
                                        <input type="file" id="file2" name="logo" accept="image/*" onChange={handleProfileImageChange} className="absolute right-0 cursor-pointer opacity-1 w-[100px] z-10 opacity-0" />
                                        <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span>
                                    </div>
                                    {profilePreview && <img src={profilePreview} alt="restaurant Profile" className="w-full h-full rounded-lg object-cover" />}
                                </div> */}
                            </div>

                        </div>

                        <div className="form-input-wrapper mt-24 grid grid-cols-1 md:grid-cols-2 gap-8 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div className="col-span-2 text-lg font-semibold">
                                <h2 className="text-center"><span className="text-mainColor block w-full text-center m-auto relative after:block after:h-1 after:bg-mainColor after:absolute after:w-[30%] after:left-[25%]  after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2  before:block before:h-1 before:bg-mainColor before:absolute before:w-[30%] before:left-[75%]  before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2">{t('restaurants.form.personal_info')}</span></h2>
                            </div>

                            <div>
                                <label>{t('restaurants.form.first_name')} <Astrisks /></label>
                                <input type="text" id="firstName"
                                    {...register('firstName',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value:/^[a-zA-Z\s]{1,50}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه ' : 'Only English letters are allowed'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : t('common.min_length',{length : '3'})
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : t('common.max_length',{length : '50'})
                                        },
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.firstName?.message ? <p className="capitalize text-sm text-danger">{errors?.firstName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.first_name_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.last_name')} <Astrisks /></label>
                                <input type="text" id="lastName"
                                    {...register('lastName',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value:/^[a-zA-Z\s]{1,50}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه ' : 'Only English letters are allowed'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : t('common.min_length',{length : '3'})
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : t('common.max_length',{length : '50'})
                                        },
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.lastName?.message ? <p className="capitalize text-sm text-danger">{errors?.lastName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.last_name_helper')}</p>}
                            </div>                      

                            <div className="col-span-2">
                                <label>{t('restaurants.form.nid')} <Astrisks /></label>
                                <input type="text" id="nationalId"
                                    {...register('nationalId',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value: /^\d{10}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'الرقم القومى يجب ان يكون ارقام فقط ' : 'National ID Must be digits'
                                        },
                                        minLength : {
                                            value : 10,
                                            message : t('common.min_length_digits',{length : '10'})
                                        },
                                        maxLength : {
                                            value : 10,
                                            message : t('common.max_length_digits',{length : '10'})
                                        },
                                    })}
                                    minLength={10}
                                    maxLength={10}
                                />

                                {errors?.nationalId?.message ? <p className="capitalize text-sm text-danger">{errors?.nationalId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.nid_helper')}</p>}
                            </div>

                            <div className="col-span-2 text-lg font-semibold">
                                <h2 className="text-center"><span className="text-mainColor block w-full text-center m-auto relative after:block after:h-1 after:bg-mainColor after:absolute after:w-[30%] after:left-[25%]  after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2  before:block before:h-1 before:bg-mainColor before:absolute before:w-[30%] before:left-[75%]  before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2">{t('restaurants.form.restaurant_info')}</span></h2>
                            </div>

                            <div>
                                <label>{t('restaurants.form.name_en')} <Astrisks /></label>
                                <input type="text" id="name"
                                    {...register('name',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value: /^[a-zA-Z+\s#&]{1,50}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزية والارقام والمسافات وعلامه (+, &, #) فقط' : 'Only English letters, numbers, (+, &, #) or spaces allowed'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : t('common.min_length',{length : '3'})
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : t('common.max_length',{length : '50'})
                                        },
                                    })}
                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.name_en_helper')}</p>}
                            </div>

                            <div className="[&>input]:w-full">
                                <label>{t('restaurants.form.name_ar')} <Astrisks /></label>
                                <input type="text" id="nameAr"
                                    {...register('nameAr',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern: {
                                            value: /^[\u0621-\u064A\s&0-9]+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والارقام والمسافات وعلامه & فقط' : 'Only Arabic letters, numbers, spaces, & sign are allowed'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : t('common.min_length',{length : '3'})
                                        },
                                        maxLength : {
                                            value : 50,
                                            message : t('common.max_length',{length : '50'})
                                        },
                                    })}

                                    minLength={3}
                                    maxLength={50}
                                />

                                {errors?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.name_ar_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.manage_phone')} <Astrisks /></label>
                                <input type="text" id="phoneNumber"
                                    {...register('phoneNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 10,
                                            message : t('common.min_length',{length : '10'})
                                        },
                                        maxLength : {
                                            value : 16,
                                            message : t('common.max_length',{length : '16'})
                                        },                           
                                        pattern:{
                                            value: /^(\+)?\d+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف بالارقام وعلامه + بالبداية فقط' :  'Only numbers and (+) sign in the beginning allowed'
                                        }
                                    })} 
                                    minLength={10}
                                    maxLength={16}
                                />

                                {errors?.phoneNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.phoneNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.manage_phone_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.manage_email')} <Astrisks /></label>
                                <input type="email" id="email"
                                    {...register('email',{
                                        pattern:{
                                            value : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'خطا بصيغه الايميل' : 'Wrong Email Format'
                                        },
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        maxLength : {
                                            value : 60,
                                            message : t('common.max_length',{length : '60'})
                                        },  
                                    })} 
                                    maxLength={60}
                                />

                                {errors?.email?.message ? <p className="capitalize text-sm text-danger">{errors?.email?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.manage_email_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.markerting_phone')}<Astrisks /></label>
                                <input type="text" id="marketingPhoneNumber"
                                    {...register('marketingPhoneNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        minLength : {
                                            value : 10,
                                            message : t('common.min_length',{length : '10'})
                                        },
                                        maxLength : {
                                            value : 16,
                                            message : t('common.max_length',{length : '16'})
                                        },                           
                                        pattern:{
                                            value: /^(\+)?\d+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف بالارقام وعلامه + بالبداية فقط' :  'Only numbers and (+) sign in the beginning allowed'
                                        }
                                    })}
                                    minLength={10}
                                    maxLength={16}
                                />

                                {errors?.marketingPhoneNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.marketingPhoneNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.markerting_phone_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.markerting_email')} <Astrisks /></label>
                                <input type="email" id="marketingEmail"
                                    {...register('marketingEmail',{
                                        pattern:{
                                            value : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'خطا بصيغه الايميل' : 'Wrong Email Format'
                                        },
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        maxLength : {
                                            value : 60,
                                            message : t('common.max_length',{length : '60'})
                                        },  
                                    })}
                                    maxLength={60}
                                />

                                {errors?.marketingEmail?.message ? <p className="capitalize text-sm text-danger">{errors?.marketingEmail?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.markerting_email_helper')}</p>}
                            </div>                     

                            {/* <div>
                                <label>hotline</label>
                                <input type="text" id="hotline"
                                    {...register('hotline',{
                                        // required:{
                                        //     value : true,
                                        //     message : t('common.required')
                                        // },
                                        minLength : {
                                            value : 5,
                                            message : 'min length is 5 digits'
                                        },
                                        maxLength : {
                                            value : 7,
                                            message : 'max length is 7 digits'
                                        },                                   
                                        pattern:{
                                            value: /^\+?\d+$/,
                                            message: 'Only numbers are allowed'
                                        }
                                    })} 
                                    minLength={5}
                                    maxLength={7}
                                />

                                {errors?.hotline?.message ? <p className="capitalize text-sm text-danger">{errors?.hotline?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter restaurant hotline</p>}
                            </div> */}

                            <div>
                                <label>{t('restaurants.form.commerical_no')} <Astrisks /></label>
                                <input type="text" id="commercialRegisterationNumber"
                                    {...register('commercialRegisterationNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value: /^[0-9#]{1,10}$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                        },
                                        minLength : {
                                            value : 10,
                                            message : t('common.min_length',{length : '10'})
                                        },
                                        maxLength : {
                                            value : 10,
                                            message : t('common.max_length',{length : '10'})
                                        },       
                                    })} 
                                    minLength={10}
                                    maxLength={10}
                                />

                                {errors?.commercialRegisterationNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.commercialRegisterationNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.commerical_no_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('restaurants.form.vat')} <Astrisks /></label>
                                <input type="text" id="vatNumber"
                                    {...register('vatNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        // pattern:{
                                        //     value: /^[0-9#]{1,10}$/,
                                        //     message: 'Only Digits Allowed'
                                        // },
                                    })} 
                                    // minLength={10}
                                    // maxLength={10}
                                />

                                {errors?.vatNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.vatNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.vat_helper')}</p>}
                            </div>
                            
                            <div className="col-span-2">
                                <label>{t('restaurants.form.commerical_letter')} <Astrisks /></label>
                                {/* <input ref={commericalRef} type="file" id="file" accept="image/*" onChange={handleCommericalImageChange} className="w-full" /> */}
                                <div className="relative min-h-[45px] w-full">
                                    <input 
                                        {...register('commericalRegFile',{
                                            required : {
                                                value : true,
                                                message : t('common.required')
                                            }
                                        })}
                                        ref={commericalRef}
                                        type="file" 
                                        id="file"
                                        accept=".pdf,image/*"
                                        title={t('common.commericalFile')}
                                        className="w-full h-full absolute top-0 z-10 opacity-0 cursor-pointer"
                                        onChange={handleCommericalImageChange}
                                    />

                                    <Button className="capitalize absolute top-0 p-2 w-full bg-[#f3f6f9] text-[#555] font-semibold text-lg">{isCommerical ? isCommerical?.name : t('common.choose_file')}</Button>
                                </div>
                                {/* <Controller
                                    name="commericalRegFile"
                                    control={control}
                                    rules={{ required: 'This Fields Is Required' }}
                                    render={({ field }) => (
                                    <input 
                                        {...field} 
                                        type="file"
                                        ref={commericalRef} 
                                        // onChange={(e) => {
                                        //     // handle file validation and setting file
                                        //     handleCommericalImageChange(e);
                                        //     // update the field with file
                                        //     // field.onChange(e.target.files[0]);
                                        // }}
                                        onChange={handleCommericalImageChange}
                                    />
                                    )}
                                /> */}
                                {errors.commericalRegFile && <span className="capitalize text-sm text-danger">{t('common.required')}</span>}
                            </div>

                            <div className="time-section col-span-2 flex gap-x-1 justify-between items-center [&>div]:basis-[33%] [&>div>label]:font-semibold [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:mb-2 [&>div>label]:block [&>div>label]:capitalize">
                                <div className="openTime [&>div]:bg-[#f3f6f9] [&>div]:focus-within:outline-none [&>div]:w-full">
                                    <label>{t('restaurants.form.open_time')} <Astrisks /></label>
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker views={['hours', 'minutes']}
                                            // label="Opening Time"
                                            {...register('openingTime',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                            })}
                                            value={opentime || { hour: 0, minute: 0}}
                                            onChange={(e)=>{
                                                setopentime(e)
                                                setValue('','',{shouldDirty:true})
                                            }}
                                            name='openingTime'
                                            className="w-full"
                                            id='openingTime'
                                            sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                        />
                                    </LocalizationProvider> */}

                                    <Controller
                                        name={'openingTime'}
                                        control={control}
                                        defaultValue={null}
                                        rules={{required:t('common.required')}}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                views={["hours", "minutes"]}
                                                // label="Opening Time"
                                                value={value ? dayjs(value, "HH:mm") : null}
                                                sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                                onChange={(newValue) => {
                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                    // console.log(timeString);
                                                    onChange(timeString);
                                                }}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                />
                                                )}
                                            />
                                            </LocalizationProvider>
                                        )}
                                        />


                                    {errors?.openingTime?.message ? <p className="capitalize text-sm text-danger">{errors?.openingTime?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.open_time_helper')}</p>}
                                </div>

                                <div className=" [&>div]:bg-[#f3f6f9] [&>div]:focus-within:outline-none [&>div]:w-full">
                                    <label>{t('restaurants.form.close_time')} <Astrisks /></label>
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker 
                                            views={['hours', 'minutes']}
                                            // label="Closing Time"
                                            {...register('closingTime',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                            })}
                                            value={closetime || { hour: 0, minute: 0}}
                                            onChange={(e)=>{
                                                setclosetime(e)
                                                setValue('','',{shouldDirty:true})
                                            }}
                                            name='closingTime'
                                            className="w-full"
                                            id='closingTime'
                                            sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                        />
                                    </LocalizationProvider> */}

                                    <Controller
                                        name={'closingTime'}
                                        control={control}
                                        defaultValue={null}
                                        rules={{required:t('common.required')}}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                views={["hours", "minutes"]}
                                                // label="Opening Time"
                                                value={value ? dayjs(value, "HH:mm") : null}
                                                sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                                onChange={(newValue) => {
                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                    // console.log(timeString);
                                                    onChange(timeString);
                                                }}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                />
                                                )}
                                            />
                                            </LocalizationProvider>
                                        )}
                                        />

                                    {errors?.closingTime?.message ? <p className="capitalize text-sm text-danger">{errors?.closingTime?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.close_time_helper')}</p>}
                                </div>

                                <div className=" [&>div]:bg-[#f3f6f9] [&>div]:focus-within:outline-none [&>div]:w-full">
                                    <label>{t('restaurants.form.last_order_time')} <Astrisks /></label>
                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            views={['hours', 'minutes']}
                                            // label="Last Order Time"
                                            {...register('orderDeadline',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                            })}
                                            value={ordertime || { hour: 0, minute: 0}}
                                            onChange={(e)=>{
                                                setordertime(e)
                                                setValue('','',{shouldDirty:true})
                                            }}
                                            name='orderDeadline'
                                            className="w-full"
                                            id='orderDeadline'
                                            sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                        />
                                    </LocalizationProvider> */}

                                    <Controller
                                        name={'orderDeadline'}
                                        control={control}
                                        defaultValue={null}
                                        rules={{required:t('common.required')}}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                views={["hours", "minutes"]}
                                                // label="Opening Time"
                                                value={value ? dayjs(value, "HH:mm") : null}
                                                sx={{'& fieldset':{border : 0,borderColor:'transparent'},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                                onChange={(newValue) => {
                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                    // console.log(timeString);
                                                    onChange(timeString);
                                                }}
                                                renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                />
                                                )}
                                            />
                                            </LocalizationProvider>
                                        )}
                                        />

                                    {errors?.orderDeadline?.message ? <p className="capitalize text-sm text-danger">{errors?.orderDeadline?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.last_order_time_helper')}</p>}
                                </div>
                            </div>
                            <div className="[&>div]:bg-[#f3f6f9] col-span-2">
                                <label htmlFor="tags">{t('restaurants.form.tags')} </label>
                                <Controller
                                    name="tagsChipSelect"
                                    control={control}
                                    defaultValue={[]}
                                    // rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <Select
                                            open={openTag}
                                            onOpen={handleOpenTag}
                                            onClose={handleCloseTag}
                                            labelId="tags-chip-select-label"
                                            id="tagsChipSelect"
                                            multiple
                                            className="w-full mb-1"
                                            value={tags || []}
                                            onChange={(e) => {
                                                if(e?.target?.value?.length <= 30){
                                                    handleTagsChipSelectChange(e);
                                                    onChange(e);
                                                } else{
                                                    toast.error('Maximum Tags Selection is 30')
                                                }
                                            }}
                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        value ? <Chip key={value.id} label={value.name} className="bg-[#464E5F] text-white font-light rounded-lg" /> : null
                                                    ))}
                                                </Box>
                                            )}
                                            // MenuProps={MenuProps}
                                            >
                                            <MenuItem
                                                // value={undefined}
                                                // disabled
                                            >
                                                <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCloseTag();
                                                }}>
                                                    Confirm
                                                </Button>
                                            </MenuItem>
                                            {tagsList?.map((name,index) => (
                                                <MenuItem
                                                    key={name?.id}
                                                    value={name}
                                                    selected = {index === 0}
                                                >
                                                {name?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.tags_helper')}</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div className="[&>div]:bg-[#f3f6f9] col-span-2">
                                <label htmlFor="cuisinesSelect">{t('restaurants.form.cuisine')} <Astrisks /></label>
                                <Controller
                                    name="cuisinesChipSelect"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <Select
                                            open={openCuisine}
                                            onOpen={handleOpenCuisine}
                                            onClose={handleCloseCuisine}
                                            labelId="cuisines-chip-select-label"
                                            id="cuisinesChipSelect"
                                            multiple
                                            className="w-full mb-1"
                                            value={cuisines || []}
                                            onChange={(e) => {
                                                if(e?.target?.value?.length <= 8){
                                                    handleCuisinesChipSelectChange(e);
                                                    onChange(e);    
                                                } else{
                                                    toast.error('Maximum Cuisines Selection is 8')
                                                }
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                    '& .MuiMenuItem-root': 
                                                        localStorage.getItem('lang') === 'ar' ? {'flexDirection' : 'row-reverse'} : {'flexDirection' : 'row'},
                                                    },
                                                },
                                                }}
                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                    value ? <Chip key={value?.id} label={localStorage.getItem('lang') === 'ar' ? value?.nameAr : value?.name} className="bg-[#464E5F] text-white font-light rounded-lg" /> : null
                                                ))}
                                                </Box>
                                            )}
                                            // MenuProps={MenuProps}
                                            >
                                            <MenuItem
                                                // value=''
                                                // disabled
                                            >
                                                <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCloseCuisine();
                                                }}>
                                                    Confirm
                                                </Button>
                                            </MenuItem>
                                            {cuisinesList?.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                {localStorage.getItem('lang') === 'ar' ? name?.nameAr : name?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.cuisine_helper')}</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div className="[&>div]:bg-[#f3f6f9] col-span-2">
                                <label htmlFor="mealtypesSelect">{t('restaurants.form.meal_type')} <Astrisks /></label>
                                <Controller
                                    name="mealtypesChipSelect"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <Select
                                            open={openMeals}
                                            onOpen={handleOpenMeals}
                                            onClose={handleCloseMeals}
                                            labelId="mealtypes-chip-select-label"
                                            id="mealtypesChipSelect"
                                            multiple
                                            className="w-full mb-1"
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                    '& .MuiMenuItem-root': 
                                                        localStorage.getItem('lang') === 'ar' ? {'flexDirection' : 'row-reverse'} : {'flexDirection' : 'row'},
                                                    },
                                                },
                                                }}
                                            value={meals}
                                            onChange={(e) => {
                                                handleMealsChipSelectChange(e);
                                                onChange(e);

                                                // if(e?.target?.value?.length <= 8){
                                                //     handleMealsChipSelectChange(e);
                                                //     onChange(e);    
                                                // } else{
                                                //     toast.error('Maximum Meals Type Selection is 8')
                                                // }
                                            }}
                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected?.map((value) => (
                                                    value ? <Chip key={value?.id} label={localStorage.getItem('lang') === 'ar' ? value?.nameAr : value?.name} className="bg-[#464E5F] text-white font-light rounded-lg" /> : null
                                                ))}
                                                </Box>
                                            )}
                                            // MenuProps={MenuProps}
                                            >
                                            <MenuItem
                                                // value=''
                                                // disabled
                                            >
                                                <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCloseMeals();
                                                }}>
                                                    Confirm
                                                </Button>
                                            </MenuItem>
                                            {mealsList?.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                {localStorage.getItem('lang') === 'ar' ? name?.nameAr : name?.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.meal_type_helper')}</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div>
                                <label>{t('restaurants.form.takein_share')} <Astrisks /></label>
                                <input type="text" id="takeinSharePerOrder"
                                    {...register('takeinSharePerOrder',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value: /^[0-9#]+(\.[0-9]*)?$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                        },
                                        max:{
                                            value : shareType === 'PERCENTAGE' ? 100 : 99999999,
                                            message : shareType === 'PERCENTAGE' ? localStorage.getItem('lang') === 'ar' ? 'اقصى نسبة مئوية 100%' : 'Max Percentage Value is 100%' : localStorage.getItem('lang') === 'ar' ? 'قيمة غير صحيحه' : 'Invalid Value'
                                        }
                                    })} 
                                />

                                {errors?.takeinSharePerOrder?.message ? <p className="capitalize text-sm text-danger">{errors?.takeinSharePerOrder?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.takein_share_helper')}</p>}
                            </div>

                            <div className="[&>div]:bg-[#f3f6f9]">
                                <label> {t('restaurants.form.takein_type')} <Astrisks /></label>
                                <FormControl sx={{width: '100%' }}>
                                    <Controller
                                        control={control}
                                        name=""
                                        value={discountType}
                                        rules={{required:t('common.required')}}
                                        {...register('takeinSharePerOrderType',{
                                            required:{
                                                value : true,
                                                message: t('common.required')
                                            }
                                        })}
                                        render={({field,fieldState: { error }})=>(
                                            <>
                                                <Select {...field}
                                                    displayEmpty
                                                    inputRef={{...register('takeinSharePerOrderType')}}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="takeinSharePerOrderType"
                                                    value={discountType}
                                                    onChange={handleOfferTypeSelectChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                            '& .MuiMenuItem-root': 
                                                                localStorage.getItem('lang') === 'ar' ? {'textAlign' : 'right'} : {'textAlign' : 'left'}
                                                            ,
                                                            },
                                                        },
                                                        }}
                                                    // renderValue={(selected) => selected.join(', ')}
                                                    sx={{'& .MuiMenu-list' : localStorage.getItem('lang') === 'ar' ? {'textAlign' : 'right'} : {'textAlign' : 'left'},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'11px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}, '& .css-tzsjye' : {backgroundColor : '#f3f6f9'}}}
                                                >
                                                    <MenuItem value={'AMOUNT'}>
                                                        <ListItemText primary={localStorage.getItem('lang') === 'ar' ? 'قيمة' : 'Amount'} />
                                                    </MenuItem>

                                                    <MenuItem value={'PERCENTAGE'}>
                                                        <ListItemText primary={localStorage.getItem('lang') === 'ar' ? 'نسبة مئوية' : 'Percentage'} />
                                                    </MenuItem>
                                                </Select>                                                    
                                            </>
                                        )}
                                        
                                    />
                                    
                                </FormControl>

                                {errors?.discountType?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.discountType?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('restaurants.form.takein_type_helper')}</p>}
                            </div>

                            <div className="col-span-2 text-lg font-semibold">
                                <h2 className="text-center"><span className="text-mainColor block w-full text-center m-auto relative after:block after:h-1 after:bg-mainColor after:absolute after:w-[30%] after:left-[25%]  after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2  before:block before:h-1 before:bg-mainColor before:absolute before:w-[30%] before:left-[75%]  before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 capitalize">{t('restaurants.form.bank_details')}</span></h2>
                            </div>

                            <div className="col-span-2">
                                <label>{t('restaurants.form.bank_name')} <Astrisks /></label>
                                <input type="text" id="bankName"
                                    {...register('bankName',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern:{
                                            value:/^[a-zA-Z\s]{1,50}$/,
                                            message : localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه ' : 'Only English letters are allowed'
                                        },
                                        maxLength : {
                                            value : 60,
                                            message : 'max length is 60 characters'
                                        }
                                    })} 
                                    maxLength={60}
                                />

                                {errors?.bankName?.message ? <p className="capitalize text-sm text-danger">{errors?.bankName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.bank_name_helper')}</p>}
                            </div>

                            <div className="col-span-2">
                                <label>{t('restaurants.form.bank_number')} <Astrisks /></label>
                                <input type="text" id="bankAccountNumber"
                                    {...register('bankAccountNumber',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        validate: value => isValidIBAN(value) ? null : localStorage.getItem('lang')==='ar' ? 'خطأ برقم ال IBAN' : 'Invalid IBAN'
                                    })} 
                                />

                                {errors?.bankAccountNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.bankAccountNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('restaurants.form.bank_number_helper')}</p>}
                            </div>


                            <div className="">
                                <label>{t('restaurants.form.iban_letter')} <Astrisks /></label>
                                {/* <input ref={ibanRef} type="file" id="file" accept="image/*" onChange={handleibanImageChange} className="w-full" /> */}
                                <div className="relative w-full min-h-[45px]">
                                    <input 
                                        {...register('ibanFile',{
                                            required : {
                                                value : true,
                                                message : t('common.required')
                                            }
                                        })}
                                        ref={ibanRef}
                                        type="file" 
                                        id="file" 
                                        accept=".pdf,image/*"
                                        title={t('common.ibanFile')}
                                        className="w-full h-full absolute top-0 z-10 opacity-0 cursor-pointer"
                                        onChange={handleibanImageChange}
                                    />

                                    <Button className="capitalize absolute top-0 p-2 w-full bg-[#f3f6f9] text-[#555] font-semibold text-lg">{isIban ? isIban?.name : t('common.choose_file')}</Button>
                                </div>

                                {/* <Controller
                                    name="iban"
                                    control={control}
                                    rules={{ required: 'This Fields Is Required' }}
                                    render={({ field }) => (
                                    <input 
                                        {...field} 
                                        type="file" 
                                        onChange={(e) => {
                                        // handle file validation and setting file
                                        handleibanImageChange(e);
                                        // update the field with file
                                        field.onChange(e.target.files[0]);
                                        }}
                                    />
                                    )}
                                />
                                */}
                                {errors.ibanFile && <span className="capitalize text-sm text-danger">{t('common.required')}</span>} 
                            </div>

                            <div className="">
                                <label>{t('restaurants.form.vat_letter')} <Astrisks /></label>
                                {/* <input ref={vatRef} type="file" id="file" accept="image/*" onChange={handleVatImageChange} className="w-full" /> */}
                                <div className="relative w-full min-h-[45px]">
                                    <input 
                                        {...register('vatFile',{
                                            required : {
                                                value : true,
                                                message : t('common.required')
                                            }
                                        })}
                                        ref={vatRef}
                                        type="file" 
                                        id="file" 
                                        accept=".pdf,image/*"
                                        title={t('common.vatFile')}
                                        className="w-full h-full absolute top-0 z-10 opacity-0 cursor-pointer"
                                        onChange={handleVatImageChange}
                                    />

                                    <Button className="capitalize absolute top-0 p-2 w-full bg-[#f3f6f9] text-[#555] font-semibold text-lg">{isVat ? isVat?.name : t('common.choose_file')}</Button>

                                </div>
                                {/* <Controller
                                    name="vat"
                                    control={control}
                                    rules={{ required: 'This Fields Is Required' }}
                                    render={({ field }) => (
                                    <input 
                                        {...field} 
                                        type="file" 
                                        onChange={(e) => {
                                        // handle file validation and setting file
                                        handleVatImageChange(e);
                                        // update the field with file
                                        field.onChange(e.target.files[0]);
                                        }}
                                    />
                                    )}
                                /> */}
                                {errors.vatFile && <span className="capitalize text-sm text-danger">{t('common.required')}</span>}
                            </div>

                            {/* <div>
                                <label>minimum charge</label>
                                <input type="text" id="minimumCharge"
                                    {...register('minimumCharge',{
                                        // required:{
                                        //     value : true,
                                        //     message : t('common.required')
                                        // },
                                        minLength : {
                                            value : 1,
                                            message : 'Min length is 1 digits'
                                        },
                                        maxLength : {
                                            value : 3,
                                            message : 'Max length is 3 digits'
                                        },
                                        max:{
                                            value:300,
                                            message : 'Max value is 300'
                                        },                           
                                        pattern:{
                                            value: /^\d+$/,
                                            message: 'Only numbers are allowed'
                                        }
                                    })} 
                                    min={0}
                                    max={300}
                                    minLength={1}
                                    maxLength={3}
                                />

                                {errors?.minimumCharge?.message ? <p className="capitalize text-sm text-danger">{errors?.minimumCharge?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter minimum charge</p>}
                            </div>

                            <div>
                                <label htmlFor="select">Always Open <Astrisks /></label>
                                <Controller
                                    name="alwaysOpen"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={'true'}>yes</option>
                                            <option value={'false'}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div> */}

                            {/* <div>
                                <label htmlFor="selectCurbside">accepts curbside pickup *</label>
                                <Controller
                                    name="acceptsCurbsidePickup"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={'true'}>yes</option>
                                            <option value={'false'}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div> */}

                            {/* <div>
                                <label htmlFor="acceptsDelivery">accepts delivery *</label>
                                <Controller
                                    name="acceptsDelivery"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={'true'}>yes</option>
                                            <option value={'false'}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div> */}

                            {/* <div>
                                <label htmlFor="onlinePayment">accepts online payment <Astrisks /></label>
                                <Controller
                                    name="acceptsOnlinePayment"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={'true'}>yes</option>
                                            <option value={'false'}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div>

                            <div>
                                <label htmlFor="promoCode">accepts Promo Code <Astrisks /></label>
                                <Controller
                                    name="acceptsPromocode"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                        <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                            <option value="" disabled>
                                                Select an option
                                            </option>
                                            <option value={'true'}>yes</option>
                                            <option value={'false'}>no</option>
                                        </select>
                                        {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                            </div> */}

                            

                            {/* <div>
                                <label htmlFor="mealtypesSelect">Select Delivery Types <Astrisks /></label>
                                <Controller
                                    name="deliveryTypesChipSelect"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: t('common.required') }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                            <Select
                                                labelId="deliveryTypes-chip-select-label"
                                                id="deliveryTypesChipSelect"
                                                multiple
                                                className="w-full mb-1"
                                                value={deliveryType || []}
                                                onChange={(e) => {
                                                    handleDeliveryTypeChipSelectChange(e);
                                                    onChange(e);
                                                }}
                                                sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                                // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected?.map((value) => (
                                                        <Chip key={value?.id} label={value?.name} className="bg-[#464E5F] text-white font-light rounded-lg" />
                                                    ))}
                                                    </Box>
                                                )}
                                                // MenuProps={MenuProps}
                                            >
                                            <MenuItem
                                                value=''
                                                disabled
                                            >
                                                Select An Option
                                            </MenuItem>
                                            {deliveryList?.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                {name?.name}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                        </>
                                    )}
                                />
                                
                            </div> */}

                            

                        </div>

                        <div className="profile-submit-btn-wrapper absolute -bottom-24 right-10 w-[250px]">
                            <Button type="submit" disabled={restaurants?.addLoading || file?.loading} className="disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed justify-center  p-0 bg-mainGreen text-white text-sm font-semibold rounded-md font-playfair px-8 py-3 w-full">{t('restaurants.form.save_btn')}</Button>
                        </div>

                    </form>
                </section>
            </div>

            {restaurants?.addLoading || file?.loading ? <Overlay /> : null}
        </article>
    );
}

export default AddRestaurant;