import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const Roles = () => {
    const location = useLocation()
    const auth = useSelector(state=>state.auth)
   
    return ( 
        (auth?.role === 'SUPER_ADMIN' ) ? <Outlet /> : <Navigate to='/dashboard/unauthorized' state={{from:location}} replace />
    );
}

export default Roles;

export const ManagerAdminRole = () =>{
    const location = useLocation()
    const auth = useSelector(state=>state.auth)

   return(
    ((auth?.role === 'SUPER_ADMIN') || auth?.role === 'ACCOUNT_MANAGER' ) ? <Outlet /> : <Navigate to='/dashboard/unauthorized' state={{from:location}} replace />
   )
}