import { GoogleMap } from "@react-google-maps/api";
import { useGoogleMaps } from "../../../../Components/Common/mapLoader";
import { useCallback, useState } from "react";

const DeliveryMap = ({center,children}) => {
    const [map, setMap] = useState(null)
    const { isLoaded, loadError } = useGoogleMaps();
    const onLoad = useCallback(function callback(map) {
        setMap(map)
        }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    return ( 
        <>                       
            {isLoaded ? 
                <div className="w-full h-full [&>div]:w-full [&>div]:h-[280px]">
                    <GoogleMap
                        center={center}
                        zoom={14}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    > 
                        {children}
                </GoogleMap> 

                </div>
                : 
                'Loading...'
            }
        </>
    );
}

export default DeliveryMap;