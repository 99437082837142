import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";

export const addFile = createAsyncThunk("addFileFunc", async(values, {rejectWithValue,dispatch})=>{
    // console.log(values);
    // for(var pair of values.entries()) {
    //     console.log(pair[0]+ ', '+ pair[1]);
    //  }
    try {
        const res = await axios.post(`files/upload`,values,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response?.data?.message);
        if (error.response && error.response.data) {
            dispatch(errorToast(error.response.data.message === 'Invalid file type.' ? 'Please Select Image' : error.response.data.message))            
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    file : null,
    error : null,
}
export const AddFileSlice = createSlice({ 
    name: "AddFileSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(addFile.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addFile.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.file = action.payload
            // console.log(action.payload);
        })

        .addCase(addFile.rejected, (state,action)=>{
            state.loading = false;
            state.file = null
            state.error = action.payload
        })

    }
});

export default AddFileSlice.reducer

