import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../../Toast-Messages/toastMessage";

export const getMenuItemById = createAsyncThunk("getMenuItemByIdFuncs", async(id, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`/my-restaurant/menu-items/${id}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    menuItem : null,
    error : null
}

export const MenuByIdSlice = createSlice({ 
    name: "menubyid-Func", 
    initialState,
    reducers: {
        resetMenuItem: (state,action) => {
            state.loading = false;
            state.menuItem = null;
            state.error = null;
        },  // Resets the state
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getMenuItemById.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getMenuItemById.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.menuItem = action.payload
            // console.log(action.payload);
        })

        .addCase(getMenuItemById.rejected, (state,action)=>{
            state.loading = false;
            state.menuItem = []
            state.error = action.payload
        })

    }
});

export const {resetMenuItem} = MenuByIdSlice.actions

export default MenuByIdSlice.reducer

