import Revenue from "../Components/Dashboard-Components/revenue";
import Managerestaurants from "../Components/Dashboard-Components/manageResturants";
import DashSummary from "../Components/Dashboard-Components/dashSummary";
import RidersSummary from "../Components/Dashboard-Components/ridersSummary";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
    const {t,i18n} = useTranslation()

    return ( 
        <div className="dashboard-admin"  dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t("dashboard.title")}</h1>
            </section>
            <div className="dashboard-wrapper flex flex-wrap lg:flex-nowrap gap-3">

                <div className="manage-restaurants-wrapper basis-full lg:basis-[35%] bg-white rounded-lg p-3 shadow-sm">
                    <Managerestaurants t={t} />
                </div>
                
                <div className="summary-wrapper basis-full lg:basis-0 flex flex-col flex-wrap w-full grow">
                    <div className=" w-full">
                        <DashSummary t={t} />
                    </div>

                    <div className="flex flex-wrap justify-between items-start mt-4 [&>div]:grow gap-2 w-full">
                        <div className="basis-full">
                            <RidersSummary t={t} />
                        </div>
                        <div className="basis-full">
                            <Revenue t={t} />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default Dashboard;