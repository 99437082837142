import { useTranslation } from "react-i18next";
import DeliveryPackageTable from "./DP-Components/dp-table";
import { useSelector } from "react-redux";
import Overlay from '../../Components/Common/overlay'




const DeliveryPackages = () => {
    const {t} = useTranslation()
    const packages = useSelector(state=>state?.packages)


    return ( 
        <article className="dp-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('dp.title')}</h1>
            </section>

            <section>
                <DeliveryPackageTable t={t} />
            </section>

        {packages?.loading ? <Overlay /> : null}
        </article>
    );
}

export default DeliveryPackages;