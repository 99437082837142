import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Astrisks from "../../../Components/Common/astrisk"
import { Button } from "@mui/material"
import { PiWarningCircleFill } from "react-icons/pi"
import { useEffect, useState } from "react"
import RestaurantMap from "./calcDeliveryMaps/restaurantMap"
import CustomerMap from "./calcDeliveryMaps/customerMap"
import { calcCharges, getCustAddress, getResAddress, resetCalcCharges } from "../../../Components/Redux/Slice/Delivery/delivery"

const CalcDeivery = ({t}) => {
    const dispatch = useDispatch()
    const form = useForm()
    const {register,control,handleSubmit,formState,reset,setValue,trigger,getValues,watch} = form
    const {errors,isDirty} = formState
    const [resetForm, setresetForm] = useState(false)

    //Restaurant Modal
    const [openResModal, setopenResModal] = useState(false)
    const handelResOpen = (type)=>{ setopenResModal(true) }
    const handelResClose = ()=>setopenResModal(false)

    //Customer Modal
    const [openCustModal, setopenCustModal] = useState(false)
    const handelCustOpen = (type)=>{ setopenCustModal(true) }
    const handelCustClose = ()=>setopenCustModal(false)


    const {delivery} = useSelector(state=>state)
    // console.log(delivery?.calculatedCharges);

    useEffect(() => {
        setValue('restaurant',delivery?.restaurantAddress)
        setValue('customer',delivery?.customerAddress)
        
        // console.log(delivery);

    }, [dispatch, delivery])

    useEffect(() => {
        if (delivery?.restaurantAddress) {
            setValue('resAddress', delivery?.restaurantAddress?.address, { shouldValidate: true });
        }
        if (delivery?.customerAddress?.address) {
            setValue('customerAddress', delivery?.customerAddress?.address, { shouldValidate: true });
        }
    }, [delivery?.restaurantAddress, delivery?.customerAddress?.address, setValue]);
    
    
    const submitHandler = async (data)=>{
        // console.log(data);
        const values = {
            branchLatitude : data?.restaurant?.latitude,
            branchLongitude : data?.restaurant?.longitude,
            customerLatitude : data?.customer?.latitude,
            customerLongitude : data?.customer?.longitude,
        }
        await dispatch(calcCharges(values))
        // console.log(values);
    }

    return ( 
        <section className="delivery-charges-section">
            <div className="charges-details-wrapper">
                <section className="charges-details-title">
                    <h2 className="font-semibold text-lg">{t('delivery.calc_charges.title')}</h2>
                    <p className="flex gap-x-2 items-center text-sm mt-2 text-[#999]"><PiWarningCircleFill className="w-5 h-5" /> <span>{t('delivery.calc_charges.tooltip')}</span></p>
                </section>
                <form className="charges-details-form-wrapper mt-3" onSubmit={handleSubmit(submitHandler)} noValidate>
                    <section className="form-inputs-wrapper flex flex-wrap gap-4 [&>div]:basis-full [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1 [&>div>input]:text-sm [&>div>input]:cursor-pointer">
                        <div>
                            <label>{t('delivery.calc_charges.form.res_location')} <Astrisks /></label>
                            <input type="text" 
                                onClick={handelResOpen}
                                id="resAddress"
                                placeholder={t('delivery.calc_charges.form.res_location')}
                                className="placeholder:capitalize"
                                {...register('resAddress',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    // pattern:{
                                    //     value: /^[0-9#]+$/,
                                    //     message: 'only numbers are allowed'
                                    // }
                                })}
                                // value={delivery?.restaurantAddress?.address}
                                readOnly
                            />

                            {errors?.resAddress?.message ? <p className="capitalize text-sm text-danger">{errors?.resAddress?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('delivery.calc_charges.form.res_location')}</p>}
                        </div>

                        <div>
                            <label>{t('delivery.calc_charges.form.customer_location')} <Astrisks /></label>
                            <input type="text" 
                                id="customerAddress"
                                onClick={handelCustOpen}
                                placeholder={t('delivery.calc_charges.form.customer_location')}
                                className="placeholder:capitalize"
                                {...register('customerAddress',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    // pattern:{
                                    //     value: /^[0-9#]+$/,
                                    //     message: 'only numbers are allowed'
                                    // }
                                })} 
                                readOnly
                                value={delivery?.customerAddress?.address}
                            />

                            {errors?.customerAddress?.message ? <p className="capitalize text-sm text-danger">{errors?.customerAddress?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('delivery.calc_charges.form.customer_location')}</p>}
                        </div>

                        <div>
                            <label>{t('delivery.title')}</label>
                            <p className="text-[#999]">{delivery?.calculatedCharges?.data?.deliveryCharges?.toFixed(2) || 0}</p>
                        </div>

                        <div>
                            <label>{t('delivery.calc_charges.form.vatAmount')}</label>
                            <p className="text-[#999]">{delivery?.calculatedCharges?.data?.vatAmount?.toFixed(2) || 0}</p>
                        </div>

                        <div>
                            <label>{t('delivery.calc_charges.form.totalAmount')}</label>
                            <p className="text-[#999]">{delivery?.calculatedCharges?.data?.totalAmount?.toFixed(2) || 0}</p>
                        </div>
                    </section>

                    <section className={`form-inputs w-full mt-6 flex gap-5 justify-end items-center [&>button]:border [&>button]:w-[150px] [&>button]:py-[10px] [&>button]:capitalize [&>button]:text-sm ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        <Button type="button" onClick={()=>{
                            reset({
                                restaurant: '',
                                resAddress: '',
                                customer: '',
                                customerAddress: '',
                            });
                            dispatch(getCustAddress(''))
                            dispatch(getResAddress(''))
                            dispatch(resetCalcCharges())

                        }} variant="outlined" className="border-[2px] border-mainColor text-mainColor bg-transparent transition-all duration-300 hover:bg-mainColor hover:text-white">{t('common.reset_btn')}</Button>
                        <Button type="submit" className="bg-mainColor text-white">{t('delivery.calc_charges.form.calc_btn')}</Button>
                    </section>
                </form>
            </div>
            
            <>
                {/* Modals */}
                <RestaurantMap open={openResModal} close={handelResClose} resetForm={()=>setresetForm(true)} t={t} />
                <CustomerMap open={openCustModal} close={handelCustClose} resetForm={()=>setresetForm(true)} t={t} />
            </>
        </section>
    );
}

export default CalcDeivery;