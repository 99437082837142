import { Box, Button, IconButton, Modal } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { getPackages, subscribePackage } from "../../../../Components/Redux/Slice/Packages/packages";


const style = {
    position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 3,
    p: 2,
    borderRadius : 5
};

const SubscribeModal = ({open,close,t,id}) => {
    const [startDate, setstartDate] = useState(null)
    const [endDate, setendDate] = useState(null)
    const dispatch = useDispatch()

    const {reset,formState,setValue,register,handleSubmit,control} = useForm()
    const {errors} = formState
    
    const page = 1,
        size = 12
    const handleOfferDpSubmit = (data)=>{
        dispatch(subscribePackage({id,data})).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                dispatch(getPackages({page,size}))
                close()
                reset()
                setstartDate(null)
                setendDate(null)
            }
        } )
    }
    
    const handleClose = ()=>{
        close()
        reset()
        setstartDate(null)
        setendDate(null)
    }
    return ( 
        <>
            <Modal
                open={open}
                onClose={handleClose}
                // aria-labelledby="menu-item-delete-modal-title"
                // aria-describedby="menu-item-delete-modal-description"
            >
                <Box 
                    sx={style}
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                >
                    <form onSubmit={handleSubmit(handleOfferDpSubmit)} noValidate className='p-1'>
                        <div className="flex justify-between items-center mb-8 border-b">
                            <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">{t('dp.sub.to.offers')}</h2>

                            <div>
                                <IconButton color="error" className="text-2xl -mt-2" onClick={handleClose}><IoCloseCircleOutline /></IconButton>
                            </div>
                        </div>

                        <section className="modal-form-wrapper [&>div]:mb-5 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>div>div>input]:bg-[#f3f6f9] [&>div>div>div>input]:w-full [&>div>div>div>input]:p-3 [&>div>div>div>input]:focus:outline-slate-400 [&>div>div>div>input]:rounded-md [&>div>div>div>input]:text-mainColor [&>div>div>div>input]:mb-1">
                            <div>
                                <label>{t('dp.sub.start.date')}</label>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name="fromDate"
                                        control={control}
                                        defaultValue={startDate}
                                        rules={{required : t('common.required')}}
                                        render={({ field }) => (
                                            <MobileDatePicker
                                                {...field}
                                                disablePast
                                                className="w-full p-0"
                                                sx={{ '& fieldset': { border: 0, outline: 0 } }}
                                                id="fromDate"
                                                value={dayjs(startDate)}
                                                onChange={(e) => {
                                                    field?.onChange(dayjs(e).format('YYYY-MM-DDTHH:mm:ss'))
                                                    const selectedDate = dayjs(e);
                                                    setstartDate(selectedDate);
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                {errors?.fromDate && <p className="text-xs mt-1 text-mainRed capitalize">{errors?.fromDate?.message}</p>}
                            </div>

                            <div>
                                <label>{t('dp.sub.end.date')}</label>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name="toDate"
                                        control={control}
                                        defaultValue={endDate}
                                        rules={{required : t('common.required')}}
                                        render={({ field }) => (
                                            <MobileDatePicker
                                                {...field}
                                                disablePast
                                                className="w-full p-0"
                                                sx={{ '& fieldset': { border: 0, outline: 0 } }}
                                                id="toDate"
                                                value={dayjs(endDate)}
                                                onChange={(e) => {
                                                    field?.onChange(dayjs(e)?.endOf('day')?.format('YYYY-MM-DDTHH:mm:ss'))
                                                    const selectedDate = dayjs(e);
                                                    setendDate(selectedDate);
                                                    // setValue('endDate', selectedDate.format('YYYY-MM-DDTHH:mm:ss'));
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                {errors?.toDate && <p className="text-xs mt-1 text-mainRed capitalize">{errors?.toDate?.message}</p>}
                            </div>
                        </section>

                        <div className={`flex items-center justify-end ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} gap-x-3 [&>button]:capitalize [&>button]:min-w-[150px] `}>
                            <Button onClick={handleClose} className="bg-transparent text-mainRed border border-solid border-mainRed">{t('common.cancel')}</Button>
                            <Button type="submit" className="bg-mainColor text-white">{t('dp.subscripe')}</Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
     );
}
 
export default SubscribeModal;