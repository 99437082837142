import { FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRestaurantRequests } from "../../../Components/Redux/Slice/Restaurants/restaurantsSlice";
import { Link, useNavigate } from "react-router-dom";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { dateOnlyFormate } from "../../../Components/Common/dateFormate";
import { CiSearch } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import DataTable from "react-data-table-component";
import defLogo from '../../../Assets/imgs/minilogo.svg'
import ColumnSelection from "../../../Components/Common/columnSelection";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import { TableStyles } from "../../../Components/Common/tableStyle";
import { resetPage } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";

function ActionCell({ data, t }) {
    // const [openModal, setopenModal] = useState(false)
    // const handelOpen = ()=>setopenModal(true)
    // const handelClose = ()=>setopenModal(false)
    // console.log(data);

    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // console.log(data); 

    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0 } }}
                className='shadow-md p-0'
            >
                <div className='[&>li]:mb-2 [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                    {/* <MenuItem className='text-mainGreen hover:bg-[#5cac7d42]'><BsTelephone /> +9225621123</MenuItem> */}
                    <MenuItem className='text-[#545151c2] hover:bg-[#442b7e42]'><Link to='details' state={{id:data?.id,type:'request',data}} className={`flex items-center gap-x-3 ${localStorage.getItem('lang')==='ar' ? 'flex-row-reverse' : 'flex-row'}`}>{localStorage.getItem('lang')==='ar' ? <><FaEye className='text-xl' /> عرض المطعم</> : <><FaEye className='text-xl' /> View Details</> }</Link></MenuItem>
                    {/* <MenuItem className='text-[#6993FF] hover:bg-[#6994ff42]'><FaStore />restaurant profile </MenuItem> */}
                    {/* <MenuItem className='text-mainYellow hover:bg-[#d9ad5642]'><FaBarcode />restaurant menu</MenuItem> */}
                    {/* <MenuItem className='text-[#3699FF] hover:bg-[#369bff42]'><Link to='/dashboard/restaurant/orders' state={{id:data?.id, name:data?.name}} className='w-full flex items-center gap-x-3' ><RiMoneyDollarCircleLine className='text-2xl' />completed orders</Link></MenuItem> */}
                    {/* <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><FaStoreAltSlash />deactivate</MenuItem> */}
                </div>
            </Menu>

            {/* <EditrestaurantModal open={openModal} close={handelClose} data={data} img={kfc} /> */}
        </div>
    );
}

const RestaurantRequests = ({t}) => {

    //data table
    const restaurants = useSelector(state=>state.restaurants)
    const dispatch = useDispatch()
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')
    const [searchValue, setsearchValue] = useState('')
    const navigate = useNavigate()

    const {currentPage} = useSelector((state) => state?.resetPagination);
    const {toggle} = useSelector((state) => state?.resetPagination);

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])
    // console.log(restaurants?.restaurantRequests?.meta?.total);
    useEffect(() => {
        dispatch(getRestaurantRequests({page,size}))
    }, [])

    const handlePageChange = page => {
        setpage(page);
        // console.log(page);
    };

    const handleRowSizeChange = rows => {
        setsize(rows);
    };
    
    // useEffect(() => {
    //     dispatch(getRestaurantRequests({page,size,col,dir,searchValue}))
    // }, [dispatch, page,size,searchValue])

    // console.log(restaurants?.restaurantRequests);

    const data = restaurants?.restaurantRequests?.data || []
    
    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row?.id,
        //     sortable: true,
        //     width:'80px'
        // },
        {
            id : "resName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم المطعم' : 'Restaurant Name'}`,
            // selector: row => row.name,
            sortable: true,
            // width : 'fit-content',
            cell : (row)=>(
                <div className='flex gap-x-2 items-center grow' title={row?.restaurantName} >
                    <img src={row?.logoUrl ? row?.logoUrl : defLogo} loading="lazy" alt={row?.restaurantName} data-tag="allowRowEvents" className='w-10 h-10 rounded-[50%]' />
                    <p data-tag="allowRowEvents">{row?.restaurantName}</p>
                </div>
            )
        },
        {
            id : "resArName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم المطعم بالعربية' : 'Arabic Name'}`,
            selector: row => <div title={row?.nameAr} data-tag="allowRowEvents">{row?.nameAr}</div>,
            sortable: false,
        },
        {
            id : "email",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الايميل' : 'Email'}`,
            selector: row => <div data-tag="allowRowEvents" title={row?.restaurantEmail}>{row?.restaurantEmail}</div>,
            // sortable: true,
            minWidth:'180px'
        },
        // {
        //     name: 'Total Orders',
        //     selector: row => <div title={row?.totalOrders}>{row?.totalOrders}</div>,
        //     sortable: false,
        //     width : '140px',
        // },
        // {
        //     name: 'Hotline',
        //     selector: row => <div title={row?.hotline}>{row?.hotline}</div>,
        // },
        {
            id : "resPhone",
            name: `${localStorage.getItem('lang') === 'ar' ? 'هاتف المطعم' : 'Restaurant Phone'}`,
            selector: row => <div data-tag="allowRowEvents" title={row?.restaurantPhoneNumber}>{row?.restaurantPhoneNumber}</div>,
        },
        {
            id : "joinDate",
            name: `${localStorage.getItem('lang') === 'ar' ? 'تاريخ التسجيل' : 'Joining Date'}`,
            selector: row => <div data-tag="allowRowEvents" title={dateOnlyFormate(row?.createdAt)}>{dateOnlyFormate(row?.createdAt)}</div>,
            sortable : true
        },
        // {
        //     name: 'Avg Rating',
        //     selector: row => <div title={row?.averageRating}>{row?.averageRating}</div>,
        //     sortable: false,
        //     width: '100px'
        // },
        // {
        //     name: 'Avg Delivery Time',
        //     selector: row => <div title={row?.averageDeliveryTime}>{row?.averageDeliveryTime}</div>,
        //     sortable: false,
        //     width: '130px'
        // },
        // {
        //     name: 'Min Charge',
        //     selector: row => <div title={row?.minimumCharge}>{row?.minimumCharge}</div>,
        //     sortable: false,
        //     width: '100px'
        // },
        {
            id : "status",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
            selector: row => <div data-tag="allowRowEvents" title={row?.status} className={` capitalize font-semibold px-2 py-1 rounded-md bg-opacity-10 bg-mainYellow text-mainYellow min-w-[100px] text-center`}>{t('restaurants.restaurantRequest')}</div>,
            sortable: false,
        },
        {
            id : "actions",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: row=>(
                <>
                    {<ActionCell data={row}  t={t}/>}
                </>
            )
                
        },
    ];

    const handleSearch = ()=>{
        dispatch(getRestaurantRequests({searchValue})).then( ()=>dispatch(resetPage()) )
    }

    const handleReset = ()=>{
        dispatch(getRestaurantRequests({page,size})).then( ()=>dispatch(resetPage()) )
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])

    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };
    
    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     // {name:'id'},
    //     {name:'restaurant Name'},
    //     {name:'Joining Date'},
    //     // {name:'total_orders'},
    //     // {name:'average_rating'},
    //     // {name:'average_delivery_time'},
    //     // {name:'minimum_charge'},
    //     // {name:'email'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,row,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null)
    // }

    const {handleRemoteSort, icon} = useRemoteSort(getRestaurantRequests,dispatch,page,size,searchValue)

    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['resName', 'resArName', 'email', 'joinDate', 'resPhone', 'status', 'actions' ]);
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['resName', 'resArName', 'email', 'joinDate', 'resPhone', 'status', 'actions' ]);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);

    return ( 
        <>
        <div className='all-wrapper relative'>
            <div className="absolute -top-[54px] right-0 flex gap-x-4">
                <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />

                {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}

            </div>

            <section className={`restaurants-control-wrapper flex flex-wrap justify-end items-center mt-2 w-full ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>

                {/* <div className='flex gap-x-3'>
                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>

                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                        
                    </div>

                </div> */}

                <div className='flex gap-x-2 items-center'>
                    <FormControl 
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{ m: 1, width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            placeholder={`${localStorage.getItem('lang') === 'ar' ? 'ابحث في المطاعم...' : 'Search Restaurants...'}`}
                            onChange={(e)=>{
                                setsearchValue(e.target.value.trim())
                                dispatch(resetPage())
                                if (!e.target.value) {
                                    handleReset();
                                }}}
                            startAdornment={
                            <InputAdornment position="start">
                                
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="start"
                                >
                                    <CiSearch />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                    {/* <Button className='capitalize text-white bg-mainGreen rounded-3xl py-1 px-1 pr-2'> <IoMdAddCircle className='w-8 h-8 m-auto rounded-[50%]' /><Link to='addrestaurant' className='w-full'>add restaurant</Link></Button> */}
                </div>

            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                            ))}
                            </Box>
                        )}
                        >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">
                {data?.length === 0 ? 
                    <div className='p-8'><NoDataEmoji /></div>
                    : 
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage = {10}
                            paginationRowsPerPageOptions = {[10,50,100]}
                            paginationServer
                            paginationTotalRows={restaurants?.restaurantRequests?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowSizeChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            sortServer
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage = {true}
                            onRowClicked={(data)=>navigate('details',{state:{id:data?.id,type:'request',data}})}
                            onSort={handleRemoteSort}
                            sortIcon={icon}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText : t('common.paginationRowText'),
                                    rangeSeparatorText : t('common.of')
                                }
                            }
                        />
                    </>
                }
                {restaurants?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>
        </div>
        </>
    );
}

export default RestaurantRequests;