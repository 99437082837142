import { Button, FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput} from '@mui/material';
import {FaEdit} from 'react-icons/fa'
import { useCallback, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import {BiDotsHorizontalRounded} from 'react-icons/bi'
import {IoMdAddCircle, IoMdEye} from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux';
import { openHandler } from '../../../Components/Redux/Slice/common/overlaySlice';
import { getDeals, toggleDealActivation } from '../../../Components/Redux/Slice/AccountManager/Deals/deals';
import { Link, useNavigate } from 'react-router-dom';
import { LuCheckCircle, LuDelete } from 'react-icons/lu';
import { CiSearch } from 'react-icons/ci';
import WarningModal from '../offers/Offers-Components/warningModal';
import ColumnSelection from '../../../Components/Common/columnSelection';
import { useRemoteSort } from '../../../Components/Common/sortHook';
import NoDataEmoji from '../../../Components/Common/nodataEmoje';
import { TableStyles } from '../../../Components/Common/tableStyle';
import { useTranslation } from 'react-i18next';
import defImg from '../../../Assets/imgs/MenuItem.png'
import { resetPage } from '../../Redux/Slice/ResetPagination/resetPagination';
//Custome Cell UI for the data table action column
function ActionCell( row ) {
    // console.log(row);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const {auth} = useSelector(state=>state)

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let page = 1,
        size = 10
    const toggleOfferActivaition = (status)=>{
        dispatch(toggleDealActivation({status,id:row?.id})).then( ()=>{
            dispatch(getDeals({page,size}))
            dispatch(resetPage());
        } ).then( ()=>handleCloseWarningModal() )
    }

    const [openWarningModal, setopenWarningModal] = useState(false);
    const [warningType, setwarningType] = useState(null);

    const handleOpenWarningModal = (type) => {
        setopenWarningModal(true)
        setwarningType(type)
        // console.log(type);
    };

    const handleCloseWarningModal = () => setopenWarningModal(false);

    // const deleteFunc = ()=>{
    //     dispatch(deleteDeal(row?.id)).then( ()=>dispatch(getDeals({page,size}))).then( ()=>handleCloseWarningModal() )
    // }
    return (
        <>
            <div className='action-wrapper relative'>
                <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
                <Menu
                    id="datatable-action-menu"
                    aria-labelledby="datatable-action-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    PaperProps={{ style: { boxShadow: 'none',padding:0} }}
                    className='shadow-md p-0'
                    sx={{'& .css-6hp17o-MuiList-root-MuiMenu-list' : {padding:0}}}
                >
                    <div className={`${localStorage.getItem('lang')=== 'ar' ? '[&>li>svg]:ml-2 [&>li]:flex [&>li]:flex-row-reverse' : '[&>li>svg]:mr-2'} [&>li]:mb-2  [&>li>svg]:text-xl rounded-md overflow-hidden capitalize`}>
                        <MenuItem className='text-mainColor hover:bg-[#b3c0f742]'><Link to={'/dashboard/partner/deals/addnewdeal'} state={{data:row,type:'edit'}} className={`grow flex  items-center [&>svg]:text-xl ${localStorage.getItem('lang')=== 'ar' ? 'flex-row-reverse [&>svg]:ml-2' : 'flex-row [&>svg]:mr-2'}`}>{auth?.role === 'BRANCH_ADMIN' ? localStorage.getItem('lang')=== 'ar' ? <><IoMdEye />  عرض</> : <><IoMdEye />  View</> : localStorage.getItem('lang')=== 'ar' ? <><FaEdit /> تعديل</> : <><FaEdit /> Edit</> }  </Link></MenuItem>

                        {auth?.role === 'BRANCH_ADMIN' ? null  : row?.isActive ? <MenuItem onClick={()=>handleOpenWarningModal('deactivate')} className='text-mainRed hover:bg-[#c6345b42]'>{localStorage.getItem('lang')=== 'ar' ? <><LuDelete />غير نشط</> : <><LuDelete />Deactivate</>}</MenuItem> : <MenuItem onClick={()=>toggleOfferActivaition('reactivate')} className='text-mainGreen hover:bg-[#5cac7d42]'>{localStorage.getItem('lang')=== 'ar' ? <><LuCheckCircle /> نشط</> : <><LuCheckCircle /> Active</>}</MenuItem> }

                        {/* {auth?.role === 'BRANCH_ADMIN' ? null : <MenuItem className='text-[#e93131] hover:bg-[#e931314b]' onClick={()=>handleOpenWarningModal('delete')}>{localStorage.getItem('lang')=== 'ar' ? <><MdOutlineDeleteSweep /> مسح</> : <><MdOutlineDeleteSweep /> Delete</>}</MenuItem>} */}
                    </div>
                </Menu>
            </div>

        <WarningModal open={openWarningModal} close={handleCloseWarningModal} type={warningType} toggleOfferActivaition={toggleOfferActivaition} />
        </>
    );
}

// test component to position an element respective to its distance from window
const TestComp = ()=>{
    const ref = useRef(null)
    const [show, setshow] = useState(false)
    const [topDis, settopDis] = useState(0)

    const distance = ()=>{
        let distance = ref.current.getBoundingClientRect() // to calculate the distance of the component from the window
        // console.log(distance.top);
        settopDis(distance.top)
    }
    const showHandler = ()=>{
        setshow(!show)
    }
    
    return(
        <>
            <div ref={ref} onClick={distance} className='relative'> 
                <ListItemButton onClick={showHandler}>Test</ListItemButton>

                {show ? <div className={`absolute w-20 h-20 shadow-lg bg-slate-700 left-0 top-${topDis} text-white`}>
                                kdfjlsjdf
                </div> : null}
            </div>
        </>
    )
}

const Deals = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(0);
    const [activeRowData, setactiveRowData] = useState(null);
    const handleOpen = ()=> navigate('addnewdeal',{state:{type:'new'}})
    const [searchValue, setsearchValue] = useState('')
    const {auth} = useSelector(state=>state)

    const {currentPage} = useSelector((state) => state?.resetPagination);
    const {toggle} = useSelector((state) => state?.resetPagination);

    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')


    const btns = [
        {label:'active'},
        {label:'Deactivated'},
        {label:'all'},
    ]

    // console.log(selectedDate);
    // console.log(selectedDate.$M+1); display month but add 1 since it start with month 0

    //data table section
    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: false,
        //     width:'80px'
        // },
        {
            id : "dealName",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم الصفقة' : 'Deal Name'}`,
            cell: (row) => (
                <div className='flex items-center gap-x-2 justify-start grow w-full'>
                    <div className='w-10 h-10 rounded-[50%]'>
                        <img className='w-full h-full rounded-[50%]' src={row?.image ? row?.image : defImg} alt={row.name} />
                    </div>
                    <span>{row.name}</span>
                </div>
            ),
            minWidth : '250px',
            sortable: true,
        },
        {
            id : "nameAr",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اسم الصفقة بالعربية' : 'Arabic Name'}`,
            selector: row => row?.nameAr,
            sortable: true,
            // width : '125px'
        },

        {
            id : "price",
            name: `${localStorage.getItem('lang') === 'ar' ? 'السعر' : 'Price'}`,
            selector: row => <span>{row?.price} <span className='text-mainGreen font-semibold'>{t('common.sar')}</span></span>,
            sortable: true,
            // width : '125px'
        },
        {
            id : "priceBefore",
            name: `${localStorage.getItem('lang') === 'ar' ? 'السعر سابقا' : 'Price Before'}`,
            selector: row => <span>{row?.priceBefore} <span className='text-mainGreen font-semibold'>{t('common.sar')}</span></span>,
            sortable: true,
            // width : "150px"
        },
        {
            id : "calories",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الكالورى' : 'Calories'}`,
            selector: row => row.calories,
            sortable: true,
            // width : "150px"
        },
        {
            id : "description",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الوصف' : 'description'}`,
            cell: row => <div className='offers-desc-cont whitespace-[wrap] max-h-[50px] overflow-hidden overflow-y-auto'>{row.description}</div>,
            minWidth : '200px'
        },
        {
            id : "status",
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
            cell: row => <div className='font-semibold capitalize'>{row.isActive? <span className='text-mainGreen bg-mainGreen bg-opacity-10 px-2 py-1 rounded-md min-w-[100px] text-center block'>{t('common.active')}</span> : <span className='text-mainRed bg-mainRed bg-opacity-10 px-2 py-1 rounded-md min-w-[100px] text-center block'>{t('common.deactivated')}</span>}</div>,
            // width : '100px'
        },
        {
            id : "actions",
            name: `${localStorage.getItem('lang') === 'ar' ? 'اوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: ActionCell
                
        },
    ];

    const {deals} = useSelector(state=>state)
    const data = deals?.deals?.data
    // console.log(deals);

    // open calander overlay
    const {dateOverlay}= useSelector(state=>state.overlay)
    const dispatch = useDispatch()

    const handleButton = ()=>{
        dispatch(openHandler({dateShow:!dateOverlay}))
    }

    useEffect(() => {
    //   console.log(activeRowData);
    }, [activeRowData])

    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)

    useEffect(() => {
        dispatch(getDeals({page,size}))
        // dispatch(getOrdersTypes())
    }, [])

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])


    // useEffect(() => {
    //     dispatch(getDeals({page,size,searchValue,col,dir}))
    // }, [dispatch, page,size,searchValue])

    // console.log(offers);
    // console.log(activeRowData);

    // const options = { day: 'numeric', month: 'short', year: 'numeric' };
    // const handleRemoteSort = (col,dir)=>{
    //     dispatch(getDeals({page,size,col,dir}))
    //     // console.log(col);
    // }

    const handleSearch = ()=>{
        dispatch(getDeals({searchValue})).then( ()=> dispatch(resetPage()))
    }

    const handleReset = ()=>{
        dispatch(getDeals({page,size})).then( ()=> dispatch(resetPage()))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])


    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };
    
    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     {name:'id'},
    //     {name:'Deal Name'},
    //     {name:'price'},
    //     {name:'Price Before'},
    //     {name:'Calories'},
    //     // {name:'created at'},
    //     // {name:'Final Amount'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,row,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null)
    // }

    const {handleRemoteSort, icon} = useRemoteSort(getDeals,dispatch,page,size,searchValue)



    // functions to handle hide / display table columns

    const [selectedColumns, setSelectedColumns] = useState(['dealName', 'nameAr', 'price', 'priceBefore','calories', 'description', 'status', 'actions']);
    
    const [openMenu, setopenMenu] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpenMenu = ()=>{
        setopenMenu(true)
    }
    const handleCloseMenu = ()=>{
        setopenMenu(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);
    

    useEffect(() => {
        setSelectedColumns(['dealName', 'nameAr', 'price', 'priceBefore','calories', 'description', 'status', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);

    return ( 
        <article className="offers-wrapper pb-5">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('deals.title')}</h1>
            </section>

            

            <section className={`offers-control-wrapper flex flex-wrap ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} justify-between items-center w-full mt-5`}>
                
                {/* <div className="offers-filter-wrapper flex justify-evenly [&>div]:mr-2">
                    <div className="px-1 py-1 bg-[#ffffff] rounded-sm">
                        {btns?.map((item,index)=>{
                            return(
                                <button key={index} className={`capitalize text-sm text-[#999999] px-4 py-2 lg:px-3 xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC] text-black' : null}`}
                                    onClick={()=>setActiveTab(index)}
                                >
                                    {item.label}
                                </button>
                            )
                        })}
                    </div>

                    <div>
                        
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>

                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>

                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>

                </div> */}

                <div className='flex items-center'>
                    <FormControl 
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{ m: 1, width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            placeholder={localStorage.getItem('lang') === 'ar' ? 'ابحث بالصفقات...' : 'Search Deals...'}
                           onChange={(e)=>{
                                    setsearchValue(e.target.value.trim())
                                    dispatch(resetPage())
                                    if (!e.target.value) {
                                        handleReset();
                                        dispatch(resetPage())
                                    }}}
                            startAdornment={
                            <InputAdornment position="start">
                                
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="start"
                                >
                                    <CiSearch />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>

                <div className={`flex ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center justify-end gap-x-3`}>
                {auth?.role === 'BRANCH_ADMIN' ? null  : 
                    <Button onClick={handleOpen} className={`capitalize text-white bg-mainGreen rounded-md py-[6px] flex items-center gap-x-2 ${localStorage.getItem('lang') === 'ar' ? 'pl-2' : 'pr-2'}`}> <IoMdAddCircle className={`w-7 h-7  rounded-[50%]`} /> <span className='text-sm font-playfair'>{t('deals.add_deal_btn')}</span></Button>
                }
                    <div className="flex">
                        <ColumnSelection columns={columns} handleOpen={handleOpenMenu} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={openMenu} close={handleCloseMenu} />
                        {/* <Button onClick={handleOpenMenu} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                    </div>

                </div>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                ))}
                            </Box>
                            )}
                    >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">
                
                {data?.length === 0 ? 
                    <div className='p-8'><NoDataEmoji /></div>
                : 
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage = {size}
                            paginationRowsPerPageOptions = {[10,50,100]}
                            paginationServer
                            paginationTotalRows={deals?.deals?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            // selectableRows
                            // selectableRowsHighlight
                            // onSelectedRowsChange={(e)=>console.log(e)}
                            onRowClicked={(row)=>setactiveRowData(row)}
                            sortServer
                            onSort={handleRemoteSort}
                            sortIcon={icon}
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage = {true}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText : t('common.paginationRowText'),
                                    rangeSeparatorText : t('common.of')
                                }
                            }
                        />
                    </>
                }

                {deals?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}


            </section>

            <section className='offer-preview-wrapper mt-5'>
                <div className='offer-preview-title font-semibold mb-3 text-[#464E5F] capitalize'>
                    <h2>{t('deals.deals_banner_preview')}</h2>
                </div>

                <div className={`offer-preview w-full flex h-[250px] justify-center items-center rounded-md p-1 ${activeRowData?.banner ? 'bg-[rgba(158,158,158,0.2)]' : 'bg-white'}`}>
                    {/* <div className='relative bg-mainGreen rounded-lg w-[90%] lg:w-[75%] m-auto h-[80px] max-w-[550px]'>
                        <div className='discount absolute top-0 left-3 w-[70px] h-[70px] text-center'>
                            <div className='discount-wrapper relative w-full h-full'>
                                <img src={discount} alt='discount' className='absolute w-full h-full' />
                                <div className='absolute top-0 [&>p]:m-0 [&>p]:p-0 [&>p]:leading-[1] w-full capitalize font-[500] mt-1 h-[90%] flex flex-wrap items-center justify-center [&>p]:basis-full'>
                                    <div>
                                        <p className='text-[17px] text-white'>{activeRowData ? activeRowData?.discountType === 'PERCENTAGE' ? activeRowData?.offerValue + "%" : activeRowData?.offerValue + " SAR" : '--'}</p>
                                        <p className='text-[17px] text-white'>off</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-[83%] ml-auto pt-4'>
                            <p className='font-semibold text-white'>{activeRowData ? activeRowData?.discountType === 'PERCENTAGE' ? activeRowData?.price + "%" : activeRowData?.price + " SAR" : '--'} off, up to {activeRowData ? activeRowData?.discountType === 'PERCENTAGE' ? activeRowData?.offerValue + "%" : activeRowData?.offerValue + " SAR" : '--'} Discount use “{activeRowData ? activeRowData?.couponCode : 'COUPON CODE'}” </p>
                            <p className='text-white font-light text-[13px] mt-1'>Try it now EXP {activeRowData ? activeRowData?.createdAt?.substring(0, 10) : '-/-/--'}</p>
                        </div>
                    </div> */}

                    {activeRowData?.banner ? <img src={activeRowData?.banner} alt={activeRowData?.name} className='w-full h-full rounded-md' /> : <p className='font-semibold text-dark'>{t('deals.no_image_helper')}</p> }
                </div>
            </section>
        </article>
    );
}

export default Deals;