import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getContractors = createAsyncThunk("ContractorsFunc", async({page,size,col,dir,searchValue}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    let colCheck;
    
    if (col) {
        colCheck = 
            (col === 'created At' || col === 'تاريخ الانشاء') ? 'created_at'
          : (col === 'Company Name (EN)' || col === 'اسم الشركة بالانجليزية') ? 'company_name' 
          : (col === 'Company Name (AR)' || col === 'اسم الشركة بالعربية') ? 'company_name_ar' 
          : (col === 'Bank Name' || col === 'اسم البنك') ? 'bank_name' 
          : (col === 'Number Of Agents' || col === 'عدد العملاء') ? 'number_of_agents' 
          : (col === 'Payout Method' || col === 'طريقة الدفع') ? 'payout_method' 
          : (col === 'Payout Frequency' || col === 'تكرار الدفع') ? 'payout_frequency' 
          : (col === 'Payout Amount Type' || col === 'نوع المبلغ المدفوع') ? 'payout_amount_type' 
          : (col === 'Payout Flat Fees' || col === 'قيمة الرسوم الثابته') ? 'payout_flat_fees_value' 
          : (col === 'Payout Percentage Value' || col === 'قيمة النسبة المئوية  للدفع') ? 'payout_percentage_value' 
          : col.replace(/\s+/g, '').toLowerCase();
    }
    
    const url = !col ? `/delivery-contractors?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${encodeURIComponent(searchValue)}`: ''}` : `/delivery-contractors?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${encodeURIComponent(searchValue)}`: ''}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const addContractor = createAsyncThunk("AddContractorsFunc", async(values, {rejectWithValue,dispatch})=>{
    // console.log(page,size);

    const url = `/delivery-contractors`
    try {
        const res = await toast.promise(axios.post(url,values),{
            pending : "Loading...",
            success : "3PL Provider Added Successfully"
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const editContractor = createAsyncThunk("editContractorsFunc", async({values,id}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);

    const url = `/delivery-contractors/${id}`
    try {
        const res = await toast.promise(axios.put(url,values),{
            pending : "Loading...",
            success : "3PL Provider Updated Successfully"
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const toggleContractor = createAsyncThunk("toggleContractorsFunc", async({status,id}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);

    const url = `/delivery-contractors/${status}`
    try {
        const res = await toast.promise(axios.put(url,{
            id:id
        }),{
            pending : "Loading...",
            success : `3PL Provider ${status}d Successfully`
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})



const initialState = {
    loading: false,
    deliveryContractor : [],
    error : null
}
export const deliveryContractor = createSlice({ 
    name: "deliveryContractor-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getContractors.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getContractors.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.deliveryContractor = action.payload
            // console.log(action.payload);
        })

        .addCase(getContractors.rejected, (state,action)=>{
            state.loading = false;
            state.deliveryContractor = ""
            state.error = action.payload
        })

        .addCase(addContractor.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(addContractor.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(addContractor.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(editContractor.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(editContractor.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(editContractor.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(toggleContractor.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(toggleContractor.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(toggleContractor.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })


    }
});

export const {} = deliveryContractor.actions

export default deliveryContractor.reducer

