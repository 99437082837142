import { useEffect, useState } from "react";
// import {FaAngleRight} from 'react-icons/fa'
import '../../Assets/style/style.scss'
// import { ListItemButton } from "@mui/material";
// import OrderNotification from "./OrdersNotifications/orderNotify";
import { useDispatch, useSelector } from "react-redux";
// import { openHandler } from "../Redux/Slice/common/overlaySlice";
import { getOrderStatistics, getStatistics } from "../Redux/Slice/Report/report";
import Overlay from "../Common/overlay";
const DashSummary = ({t}) => {
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch()

    const {report} = useSelector(state=>state)

    useEffect(() => {
        let dateRange = 'last_7_days'
        dispatch(getStatistics({dateRange}))
        dispatch(getOrderStatistics({dateRange}))
    }, [dispatch])

    const btns = [
        {label:'last week',labelAr:"اخر أسبوع", param : 'last_7_days'},
        {label:'last month',labelAr:"اخر شهر", param : 'last_30_days'},
        {label:'last 5 months',labelAr:"اخر 5 أشهر", param : 'last_5_months'},
    ]

    const filterClickHandler = (index,item)=>{
        setActiveTab(index)
        let dateRange = item?.param
        dispatch(getStatistics({dateRange}))
        dispatch(getOrderStatistics({dateRange}))
    }

    // console.log(report);
    const statusAnalytics = [
        {id:1,name:t('dashboard.summary.total_rest'), count:report?.orderStatistics?.data?.totalPartners, color:'#5CAC7D'},
        {id:2,name:t('dashboard.summary.total_couriers'), count:report?.orderStatistics?.data?.totalDrivers, color:'#442B7E'},
        {id:3,name:t('dashboard.summary.total_users'), count:report?.orderStatistics?.data?.totalUsers, color:'#D9AE56'},
        {id:4,name:t('dashboard.summary.total_orders'), count: report?.statistics?.data?.totalOrders, color:'#C6345C'},
        {id:5,name:t('dashboard.summary.total_delivery'), count: report?.statistics?.data?.totalDeliveryOrders, color:'#EF6327'},
        {id:6,name:t('dashboard.summary.total_rest_pickup'), count: report?.statistics?.data?.totalRestaurantPickupOrders, color:'#00A0E9'},
        {id:7,name:t('dashboard.summary.total_curb_pickup'), count: report?.statistics?.data?.totalCurbsidePickupOrders, color:'#8ABE69'},
        // {id:8,name:'Total No. Dine in Orders', count:38, color:'#4A4B68'},
    ]

    // const {orderNotify} = useSelector(state=>state.overlay)

    // const showOrdersHandler = ()=>{
    //     // setshowOrders(!showOrders)
    //     dispatch(openHandler({orderNotification:true}))
    // }
    return ( 
        <article className="order-summary bg-white rounded-lg p-3 shadow-sm">
            <section className="order-summary-title flex justify-between items-center">
                <div>
                    <h2 className='font-bold text-lg text-[#464E5F] capitalize'>{t('dashboard.summary.title')}</h2>
                    <p className="font-medium text-[12px] text-[#B5B5C3]">{report?.statistics?.data?.totalOrders} {t('dashboard.summary.sales')}</p>
                </div>

                <div className="px-1 py-1 bg-[#F4F6FC] rounded-sm">
                    {btns?.map((item,index)=>{
                        return(
                            <button className={`capitalize text-sm font-bold text-[#999999] px-4 py-2 lg:px-2 xl:px-4 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-white text-black' : null}`}
                                onClick={()=>filterClickHandler(index,item)}
                            >
                                {localStorage?.getItem('lang') === 'ar' ? item.labelAr : item.label}
                            </button>
                        )
                    })}
                </div>
            </section>

            <section className="order-status-analytics-wrapper mt-6">
                <div className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-between gap-2 md:gap-3">
                    {statusAnalytics?.map((item,index)=>(
                        <div className={`bg-[#F3F6F9] p-4 rounded-md ${index === 4 ? 'col-span-2' : ''}`} key={item.id}>
                            <p style={{color:item.color}} className="text-3xl mb-3 font-bold">{item.count}</p>
                            <p className="text-[#999999] text-[13px]">{item.name}</p>
                        </div>
                    ))}
                </div>
            </section>

            {report?.loading ? <Overlay /> : null}
        </article>
    );
}
export default DashSummary;