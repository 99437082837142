import { FaMotorcycle } from 'react-icons/fa';
import resBG from '../../../../Assets/imgs/dashboardResBg.svg'
import kfc from '../../../../Assets/imgs/kfc.png'
import {AiFillStar} from 'react-icons/ai'
import motorcycle from '../../../../Assets/imgs/motorcycle.svg'
import car from '../../../../Assets/imgs/car.svg'
import serve from '../../../../Assets/imgs/serve.svg'
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import previewIcon from '../../../../Assets/imgs/previewIcon.svg'
import { getMyRestaurant } from '../../../Redux/Slice/AccountManager/MyRestaurant/myRestaurant';
import { getRating } from '../../../Redux/Slice/AccountManager/Rating/rating';
import { useTranslation } from 'react-i18next';
import defImg from '../../../../Assets/imgs/Banner.svg'

const DashboardResturantInfo = () => {
    const {t,i18n} = useTranslation()
    const {myRestaurant} = useSelector(state=>state?.myRestaurant)
    const {rate} = useSelector(state=>state?.rate)
    const dispatch = useDispatch()

    let page = 1,
        size = 10
    useEffect(() => {
        dispatch(getMyRestaurant())
        dispatch(getRating({page,size}))

    }, [dispatch])

    // console.log(rate?.meta?.total);

    const avgRating = myRestaurant?.data?.averageRating;

    const restIcons = [
        {icon:motorcycle},
        {icon:car},
        {icon:serve},
    ]
    return ( 
        <article className='dashboard-resturnat-info w-full h-full bg-mainColor rounded-md relative' dir='ltr'>
            <div className='flex flex-col items-center p-4 py-6 h-full capitalize relative z-10'>

                <div className='resturant-logo-wrapper w-[100px] h-[100px] rounded-[50%] overflow-hidden relative'>
                    {myRestaurant?.data?.logoUrl ? <img src={myRestaurant?.data?.logoUrl } alt='resturant-logo' className='w-full h-full rounded-[50%] object-cover'  /> : <img src={defImg} alt='resturant-logo' className='w-full h-full rounded-[50%] object-cover'  /> }
                    {/* <div className='overlay absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center w-full h-full rounded-[50%] bg-[rgba(0,0,0,.5)] text-white capitalize'>
                        {myRestaurant?.data?.isOpen && myRestaurant?.data?.isBusy ? 'Busy' : myRestaurant?.data?.isOpen && !myRestaurant?.data?.isBusy ? 'open' : !myRestaurant?.data?.isOpen ? 'Close' : null}
                    </div> */}
                </div>

                <div className='resturant-title-wrapper mt-4'>
                    <p className='text-white font-semibold text-lg tracking-wider'>{myRestaurant?.data?.name}</p>
                </div>

                <div className='resturant-descripton-wrapper mt-2 flex flex-wrap gap-x-2'>
                    {myRestaurant?.data?.tags?.map( (item,index)=>(
                        <p className='text-white text-sm' key={index}>
                            {item?.name + ' .'}
                        </p>
                    ) )}
                </div>

                <div className='rsturant-rating-count-wrapper mt-3 flex items-center [&>span]:text-lg'>
                    {[...Array(5)].map((_,index)=>(
                        index < avgRating ? <span key={index} className='text-mainYellow'><AiFillStar /></span> : <span className='text-[#F4F4F4]'><AiFillStar /></span>
                    ))}
                    <p className='text-[11px] text-white ml-1'>of {rate?.meta?.total} {t("dashboard.restaurant_info.review")}</p>
                </div>

                <div className='resturant-service-icons-wrapper mt-7 w-[60%] m-auto'>
                    <ul className='flex items-center justify-around'>
                        {restIcons?.map((item,index)=>(
                            <li key={index} className='p-2 bg-[rgba(255,255,255,.2)] rounded-[50%]'>
                                <img src={item.icon} className='w-[30px] h-[30px] rounded-[50%]' alt="icon" />
                            </li>
                        ))}
                    </ul>
                </div>

                <div className='resturant-edit-btn-wrapper flex justify-center mt-3 w-full'>
                    <Button className='text-white p-0 rounded-mg bg-mainGreen w-[150px] capitalize'><Link to='/dashboard/partner/settings' className='w-full py-2 px-5 font-playfair' >{t("dashboard.restaurant_info.edit_btn")}</Link></Button>
                </div>
            </div>

            <section className='section-signture-bg absolute bottom-0 right-0 z-[1]'>
                <img src={resBG} alt='signture' />
            </section>
        </article>
    );
}

export default DashboardResturantInfo;