import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getTicketsTypes = createAsyncThunk("getTicketsTypesFunc", async(_, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await axios.get(`/ticket-types`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const sendOrderTickets = createAsyncThunk("sendOrderTicketsFunc", async({id,values}, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await toast.promise(axios.post(`/orders/${id}/tickets`,values),
        {
            pending : 'Loading...',
            success : 'Ticket Submitted Successfully'
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getOrderTickets = createAsyncThunk("getOrderTicketsFunc", async(id, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await axios.get(`/orders/${id}/tickets`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const toggleTicket = createAsyncThunk("toggleTicketFunc", async({type,id,values}, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await toast.promise(axios.put(`/tickets/${id}/${type}`,values),
        {
            pending : 'Loading...',
            success : `Ticket ${type}ed Successfully`
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getAllTickets = createAsyncThunk("getAllTicketsFunc", async({page,size,selectedDate,col,dir,searchValue}, {rejectWithValue,dispatch})=>{

    let from_date = null,
        adjustedFromDate = null,
        to_date = null,
        adjustedToDate = null

    if(selectedDate){
        from_date = new Date(selectedDate?.startDate)
        adjustedFromDate = new Date(from_date.getTime() + (from_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);

        to_date = new Date(selectedDate?.endDate)
        adjustedToDate = new Date(to_date.getTime() + (to_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);
    }

    // console.log(selectedDate);

    let colCheck;

    if (col) {
        colCheck = 
            (col === 'Created At' || col === 'تاريخ الانشاء') ? 'created_at' 
        : (col === 'Assignee Name' || col === 'اسم المحال اليه') ? 'assignee_name' 
        : (col === 'Assignee User Name' || col === 'اسم المستخدم للمحال اليه') ? 'assignee_username' 
        : (col === 'Assignee Email' || col === 'ايميل المحال اليه') ? 'assignee_email' 
        : (col === 'Status' || col === 'الحالة') ? 'status' 
        : (col === 'Restaurant Name' || col === 'اسم المطعم') ? 'restaurant_name' 
        : (col === 'Customer Name' || col === 'اسم العميل') ? 'customer_name' 
        : (col === 'Customer Phone' || col === 'هاتف العميل') ? 'customer_mobile_number' 
        : (col === 'Courier Name' || col === 'اسم السائق') ? 'courier_name' 
        : (col === 'Courier Phone' || col === 'هاتف السائق') ? 'courier_mobile_number' 
        // : (col === 'Avg Rating' || col === '') ? 'average_rating' 
        // : (col === 'Total Orders' || col === '') ? 'total_orders' 
        : col.replace(/\s+/g, '');
    }
    
    const url = !col ? `/tickets?page=${page ? page : 1}&size=${size ? size : 10}${selectedDate ? `&fromDate=${adjustedFromDate}&toDate=${adjustedToDate}`:''}${searchValue ?`&search=${searchValue}`: ''}` : `/tickets?page=${page ? page : 1}&size=${size ? size : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${selectedDate ? `&fromDate=${adjustedFromDate}&toDate=${adjustedToDate}`:''}${searchValue ?`&search=${searchValue}`: ''}`;
    
    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getMyTickets = createAsyncThunk("getMyTicketsFunc", async({page,size,selectedDate,col,dir,searchValue}, {rejectWithValue,dispatch})=>{
    
    let from_date = null,
        adjustedFromDate = null,
        to_date = null,
        adjustedToDate = null

    if(selectedDate){
        from_date = new Date(selectedDate?.startDate)
        adjustedFromDate = new Date(from_date.getTime() + (from_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);

        to_date = new Date(selectedDate?.endDate)
        adjustedToDate = new Date(to_date.getTime() + (to_date?.getTimezoneOffset() * -60000)).toISOString().slice(0,10);
    }

    // console.log(selectedDate);

    let colCheck;

    if (col) {
        // colCheck = col === 'Created At' ? 'created_at' : col === 'Assigned Name' ? 'assignee_name' : col === 'Status' ? 'status' : col === 'Restaurant Name' ? 'restaurant_name' : col === 'Customer Name' ? 'customer_name' : col === 'Driver Name' ? 'courier_name' : col === 'Avg Rating' ? 'average_rating' : col === 'Total Orders' ? 'total_orders' : col.replace(/\s+/g, '');
        colCheck = 
            (col === 'Created At' || col === 'تاريخ الانشاء') ? 'created_at' 
        : (col === 'Assignee Name' || col === 'اسم المحال اليه') ? 'assignee_name' 
        : (col === 'Assignee User Name' || col === 'اسم المستخدم للمحال اليه') ? 'assignee_username' 
        : (col === 'Assignee Email' || col === 'ايميل المحال اليه') ? 'assignee_email' 
        : (col === 'Status' || col === 'الحالة') ? 'status' 
        : (col === 'Restaurant Name' || col === 'اسم المطعم') ? 'restaurant_name' 
        : (col === 'Customer Name' || col === 'اسم العميل') ? 'customer_name' 
        : (col === 'Customer Phone' || col === 'هاتف العميل') ? 'customer_mobile_number' 
        : (col === 'Courier Name' || col === 'اسم السائق') ? 'courier_name' 
        : (col === 'Courier Phone' || col === 'هاتف السائق') ? 'courier_mobile_number' 
        // : (col === 'Avg Rating' || col === '') ? 'average_rating' 
        // : (col === 'Total Orders' || col === '') ? 'total_orders' 
        : col.replace(/\s+/g, '');
    }
    
    const url = !col ? `/my-tickets?page=${page ? page : 1}&size=${size ? size : 10}${selectedDate ? `&fromDate=${adjustedFromDate}&toDate=${adjustedToDate}`:''}${searchValue ?`&search=${searchValue}`: ''}` : `/my-tickets?page=${page ? page : 1}&size=${size ? size : 10}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}${selectedDate ? `&fromDate=${adjustedFromDate}&toDate=${adjustedToDate}`:''}${searchValue ?`&search=${searchValue}`: ''}`;

    try {
        const res = await axios.get(url)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const assignTicket = createAsyncThunk("assignTicketFunc", async({id,userId}, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await toast.promise(axios.put(`/tickets/${id}/assign`,userId),{
            pending : 'Loading...',
            success : 'Ticket Assigned Successfully'
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})



const initialState = {
    loading: false,
    orderTickets : [],
    ticketType : [],
    allTickets : [],
    myTickets : [],
    error : null,
}
export const TicketSlice = createSlice({ 
    name: "TicketSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getTicketsTypes.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getTicketsTypes.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.ticketType = action.payload
            // console.log(action.payload);
        })

        .addCase(getTicketsTypes.rejected, (state,action)=>{
            state.loading = false;
            state.ticketType = ""
            state.error = action.payload
        })

        .addCase(sendOrderTickets.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(sendOrderTickets.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(sendOrderTickets.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(getOrderTickets.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getOrderTickets.fulfilled, (state,action)=>{
            state.loading = false;
            state.orderTickets = action?.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getOrderTickets.rejected, (state,action)=>{
            state.loading = false;
            state.orderTickets = ''
            state.error = action.payload
        })

        .addCase(toggleTicket.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(toggleTicket.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(toggleTicket.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

        .addCase(getAllTickets.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getAllTickets.fulfilled, (state,action)=>{
            state.loading = false;
            state.allTickets = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getAllTickets.rejected, (state,action)=>{
            state.loading = false;
            state.allTickets = action.payload
            state.error = action.payload
        })

        .addCase(getMyTickets.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getMyTickets.fulfilled, (state,action)=>{
            state.loading = false;
            state.myTickets = action.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getMyTickets.rejected, (state,action)=>{
            state.loading = false;
            state.myTickets = action.payload
            state.error = action.payload
        })
    }
});

// export const {logout} = CouriersSlice.actions

export default TicketSlice.reducer

