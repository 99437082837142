import { Box, Button, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import {IoCloseCircleOutline} from 'react-icons/io5'
import '../../../../Assets/style/style.scss'
import { useDispatch, useSelector } from "react-redux";
import { addCategories, getCategories } from "../../../../Components/Redux/Slice/AccountManager/Menu/menu";
import { useEffect, useState } from "react";
import Overlay from "../../../../Components/Common/overlay";
import Astrisks from '../../../../Components/Common/astrisk'

const AddCategoryModal = ({open,close,t}) => {
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,reset} = form
    const {errors, isDirty} = formState
    const dispatch = useDispatch()
    const [textareaCounter, settextareaCounter] = useState(0);
    const [textareaArCounter, settextareaArCounter] = useState(0);

    const {categories} = useSelector(state=>state)
    // console.log(categories)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 4,
        borderRadius : 5,
    };

    const submitHandler = async (data)=>{
        await dispatch(addCategories(data)).then( ()=>dispatch(getCategories()).then( (e)=>e?.payload?.message ==='SUCCESS' ? close() : null ).then( ()=>reset() ) )
    }

    useEffect(() => {
        // console.log(textareaCounter);
    }, [textareaCounter,textareaArCounter])
    
    return ( 
        <>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="menu-modal-title"
                aria-describedby="menu-modal-description"
            >
                <Box sx={style} dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                    <div className="flex justify-between items-center">
                        <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">{t('menu.add_category.title')}</h2>

                        <div>
                            <Button color="error" className={`text-2xl ${localStorage.getItem('lang') === 'ar' ? '-mt-4' : '-mt-2' } `} onClick={()=>{
                                close()
                                reset()
                            }}>
                                <IoCloseCircleOutline />
                            </Button>
                        </div>
                    </div>
                    
                    <form onSubmit={handleSubmit(submitHandler)} noValidate className="menu-modal-form-wrapper mt-4 flex flex-wrap justify-between [&>div]:mb-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                        <div className="basis-full lg:basis-[48%]">
                            <label>{t('menu.add_category.form.name_en')} <Astrisks /></label>
                            <input type="text" id="name"
                                {...register('name',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    minLength : {
                                        value : 3,
                                        message : 'min length is 3 characters'
                                    },
                                    maxLength : {
                                        value : 50,
                                        message : 'max length is 50 characters'
                                    },
                                    pattern: {
                                        value: /^(?!.*\b(deal|offer)\b)[a-zA-Z\s]*$/i,
                                        message: 'Accept English letters only (deal/offer) words not allowed'
                                    }
                                })}

                                minLength={3}
                                maxLength={50}
                            />

                            {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_category.form.name_en_helper')}</p>}
                        </div>

                        <div className="basis-full lg:basis-[48%]">
                            <label>{t('menu.add_category.form.name_ar')} <Astrisks /></label>
                            <input type="text" id="nameAr"
                                {...register('nameAr',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    pattern: {
                                        value: /^[\u0621-\u064A\s&]+$/,
                                        message: 'Only Arabic letters, spaces and & are allowed'
                                    },
                                    minLength : {
                                        value : 3,
                                        message : 'min length is 3 characters'
                                    },
                                    maxLength : {
                                        value : 50,
                                        message : 'max length is 50 characters'
                                    },
                                })}

                                minLength={3}
                                maxLength={50}
                            />

                            {errors?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_category.form.name_ar_helper')}</p>}
                        </div>

                        <div className="basis-full">
                            <label>{t('menu.add_category.form.desc_en')} <Astrisks /></label>
                            
                            <div className="relative">
                                <textarea onKeyUp={(e)=>settextareaCounter(e.target.value.length)} id="description" className="w-full h-auto min-h-[40px] bg-[#f3f6f9] p-3 focus:outline-slate-400 rounded-md resize-none text-mainColor"
                                    {...register('description',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9+\-@_#.*\s]+$/,
                                            message: 'English letters, spaces, [+ - @ _ * # .] signs and numbers are allowed'
                                        },
                                        // minLength : {
                                        //     value : 10,
                                        //     message : 'min length is 10 characters'
                                        // },
                                        // maxLength : {
                                        //     value : 500,
                                        //     message : 'max length is 500 characters'
                                        // }
                                    })}

                                    minLength={10}
                                    maxLength={500}
                                />
                                <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaCounter}</p>
                            </div>

                            {errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_category.form.desc_en_helper')}</p>}
                        </div>

                        <div className="basis-full">
                            <label>{t('menu.add_category.form.desc_ar')} <Astrisks /></label>
                            
                            <div className="relative">
                                <textarea id="descriptionAr" onKeyUp={(e)=>settextareaArCounter(e.target.value.length)} className="w-full h-auto min-h-[40px] bg-[#f3f6f9] p-3 focus:outline-slate-400 rounded-md resize-none text-mainColor"
                                    {...register('descriptionAr',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        
                                        // minLength : {
                                        //     value : 10,
                                        //     message : 'min length is 10 characters'
                                        // },
                                        // maxLength : {
                                        //     value : 500,
                                        //     message : 'max length is 500 characters'
                                        // },
                                        pattern: {
                                            value: /^[\u0621-\u064A\s&0-9+\-@_#.*]+$/,
                                            message: 'Only Arabic letters, spaces, [+ - @ _ * #] signs and numbers are allowed'
                                        },
                                    })}

                                    minLength={10}
                                    maxLength={500}
                                />
                                <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaArCounter}</p>
                            </div>

                            {errors?.descriptionAr?.message ? <p className="capitalize text-sm text-danger">{errors?.descriptionAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_category.form.desc_ar_helper')}</p>}
                        </div>

                        <div className="add-new-category-btn mt-7 text-right basis-full self-end">
                            <Button type="submit" disabled={categories?.loading } className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainColor rounded-md capitalize text-white px-5 py-2 min-w-[100px]">{t('menu.add_category.form.add_btn')}</Button>
                        </div>
                    </form>
                </Box>
            </Modal>
            {categories?.loading ? <Overlay /> : null}
        </>
    );
}
export default AddCategoryModal;