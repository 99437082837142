import { configureStore } from "@reduxjs/toolkit";
import overlaySlice from "./Slice/common/overlaySlice";
import toastMessage from "./Slice/Toast-Messages/toastMessage";
import auth from "./Slice/Auth/auth";
import profileSlice from "./Slice/Profile/profileSlice";
import changePasswordSlice from "./Slice/Profile/changePasswordSlice";
import couriersSlice from "./Slice/Couriers/couriersSlice";
import restaurantsSlice from "./Slice/Restaurants/restaurantsSlice";
import tags from "./Slice/Tags/tags";
import cuisines from "./Slice/Cuisines/cuisines";
import mealsType from "./Slice/MealsType/mealsType";
import accounts from "./Slice/Accounts/accounts";
import orders from "./Slice/Orders/orders";
import report from './Slice/Report/report'
import usersSlice from "./Slice/Users/usersSlice";
import file from "./Slice/Upload/file";
import roles from "./Slice/Roles/roles";
import tickets from "./Slice/Tickets/tickets";
import tabSlice from "./Slice/common/tabSlice";
import delivery from "./Slice/Delivery/delivery";
import refundSlice from "./Slice/Orders/refundSlice";
import mapSlice from "./Slice/common/mapSlice";
import manager from "./Slice/AccountManager/manager";
import branches from "./Slice/AccountManager/Branch/branches";
import menu from "./Slice/AccountManager/Menu/menu";
import menuById from "./Slice/AccountManager/Menu/menuById";
import offers from "./Slice/AccountManager/Offers/offers";
import deals from "./Slice/AccountManager/Deals/deals";
import myRestaurant from "./Slice/AccountManager/MyRestaurant/myRestaurant";
import rating from "./Slice/AccountManager/Rating/rating";
import deliveryContractor from "./Slice/DeliveryContractors/deliveryContractor";
import coupons from "./Slice/Coupons/coupons";
import partnerUsersSlice from './Slice/AccountManager/Users/partnerUsersSlice'
import resetPagination from './Slice/ResetPagination/resetPagination'
import packages from "./Slice/Packages/packages";

export const store = configureStore({
    reducer: {
        overlay : overlaySlice,
        toastMessage : toastMessage,
        auth : auth,
        profile : profileSlice,
        changepassword : changePasswordSlice,
        couriers : couriersSlice,
        restaurants : restaurantsSlice,
        tags : tags,
        cuisines : cuisines,
        mealsType : mealsType,
        accounts : accounts,
        orders : orders,
        report : report,
        users : usersSlice,
        file : file,
        roles : roles,
        tickets : tickets,
        tab:tabSlice,
        delivery : delivery,
        refund : refundSlice,
        map : mapSlice,
        manager : manager,
        branches : branches,
        categories : menu,
        menuItem : menuById,
        offers: offers,
        deals : deals,
        myRestaurant : myRestaurant,
        rate : rating,
        deliveryContractor : deliveryContractor,
        coupons : coupons,
        partnerUsers : partnerUsersSlice,
        resetPagination : resetPagination,
        packages : packages
    }
});