import { useJsApiLoader } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
const libraries = ["places"];

export const useGoogleMaps = () => {
    const {key} = useSelector(state=>state?.map)

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        // googleMapsApiKey : key?.data?.GOOGLE_MAP_KEYS,   
        googleMapsApiKey : localStorage?.getItem('gkey'),   
        libraries
    });

    return { isLoaded, loadError };
};