import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const refundOrder = createAsyncThunk("refund-order", async({id,values}, {rejectWithValue,dispatch})=>{
    
    try {
        const res = await toast.promise(axios.post(`/orders/${id}/refund`,{
            ...values
        }),{
            pending : 'Loading...',
            success : `Refund ${values?.amount} Successfully`
        })
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})
const initialState = {
    loading : false,
    error : null
} 
export const RefundSlice = createSlice({
    name :'Refund-Slice',
    initialState,
    reducers :{},
    extraReducers : (builder)=>{
        builder
        .addCase(refundOrder.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(refundOrder.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
        })

        .addCase(refundOrder.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })
    }
})

// export const {logout} = RefundSlice.actions

export default RefundSlice.reducer