import { Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

const VerificationCode = () => {
    const form = useForm()
    const {register,control,handleSubmit,formState} = form
    const {errors} = formState
    const navigate = useNavigate()


    const submitHandler = async (data)=>{
        // console.log(data);
        if(data){
            navigate('/newpassword')
        }

        let verCode = ""
        for (const [key,value] of Object.entries(data)){
            // console.log(`${key}: ${value}`)
            verCode += value
        }

        // console.log(verCode);
    }

    return ( 
        <article className="forget-password-wrapper">
            <div className="w-full h-screen flex justify-center items-center">
                <div className="min-w-[350px] w-full md:w-[60%] lg:w-1/2 bg-white p-4 rounded-lg shadow-md">
                    <div className="w-full text-center verfication-email-title-wrapper capitalize font-medium text-[#333] text-xl">Verfication Code</div>

                    <form onSubmit={handleSubmit(submitHandler)} className="mt-5">
                        
                        <div className="grid grid-cols-6 gap-5 [&>div>input]:text-center">
                            <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                <input type="text" id="num1"
                                    maxLength='1'
                                    {...register('num1',{
                                        required:{
                                            value : true,
                                            message : 'This field is required'
                                        }
                                    })} 
                                    // defaultValue={profile?.profile?.data?.num1}
                                />
                            </div>

                            <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                <input type="text" id="num2"
                                    maxLength='1'
                                    {...register('num2',{
                                        required:{
                                            value : true,
                                            message : 'This field is required'
                                        }
                                    })} 
                                    // defaultValue={profile?.profile?.data?.num2}
                                />
                            </div>

                            <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                <input type="text" id="num3"
                                    maxLength='1'
                                    {...register('num3',{
                                        required:{
                                            value : true,
                                            message : 'This field is required'
                                        }
                                    })} 
                                    // defaultValue={profile?.profile?.data?.num3}
                                />
                            </div>

                            <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                <input type="text" id="num4"
                                    maxLength='1'
                                    {...register('num4',{
                                        required:{
                                            value : true,
                                            message : 'This field is required'
                                        }
                                    })} 
                                    // defaultValue={profile?.profile?.data?.num4}
                                />
                            </div>

                            <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                <input type="text" id="num5"
                                    maxLength='1'
                                    {...register('num5',{
                                        required:{
                                            value : true,
                                            message : 'This field is required'
                                        }
                                    })} 
                                    // defaultValue={profile?.profile?.data?.num5}
                                />
                            </div>

                            <div className="[&>label]:capitalize [&>label]:text-[#333] [&>label]:text-sm [&>label]:block [&>label]:mb-2 [&>label]:font-semibold [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-3 [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                <input type="text" id="num6"
                                    {...register('num6',{
                                        required:{
                                            value : true,
                                            message : 'This field is required'
                                        }
                                    })} 
                                    // defaultValue={profile?.profile?.data?.num6}
                                />
                            </div>
                        </div>

                        <div className="flex justify-center mt-7">
                            <Button type="submit" className="text-white py-2 px-8 rounded-full bg-mainColor">Send</Button>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    );
}

export default VerificationCode;