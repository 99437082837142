import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getPartners = createAsyncThunk("getPartnersFunc", async(_, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/auth/my-partners`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const managerLogin = createAsyncThunk("postManagerTokenFunc", async(id, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.post(`auth/account-manager-login`,id)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getManagerRestaurants = createAsyncThunk("getManagerRestaurantsFunc", async(searchValue, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/restaurants/list?${searchValue ? `search=${searchValue}` : ''}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    partners : [],
    managerRestaurants : [],
    mrLoading:false,
    newToken : '',
    error : null,
}
export const ManagerSlice = createSlice({ 
    name: "cuisineSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getPartners.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getPartners.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.partners = action.payload
            // console.log(action.payload);
        })

        .addCase(getPartners.rejected, (state,action)=>{
            state.loading = false;
            state.partners = ""
            state.error = action.payload
        })

        .addCase(managerLogin.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(managerLogin.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.newToken = action.payload
            localStorage.setItem('atk',action?.payload?.data?.accessToken)
            // console.log(action.payload);
        })

        .addCase(managerLogin.rejected, (state,action)=>{
            state.loading = false;
            state.newToken = ""
            state.error = action.payload
        })
        .addCase(getManagerRestaurants.pending, (state,action)=>{
            state.mrLoading = true;
            state.error = ""
        })

        .addCase(getManagerRestaurants.fulfilled, (state,action)=>{
            state.mrLoading = false;
            state.error = ""
            state.managerRestaurants = action.payload
            // console.log(action.payload);
        })

        .addCase(getManagerRestaurants.rejected, (state,action)=>{
            state.mrLoading = false;
            state.managerRestaurants = ""
            state.error = action.payload
        })

    }
});

export default ManagerSlice.reducer

