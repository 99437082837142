import { useNavigate } from 'react-router-dom';
import { MenuItem, Select} from "@mui/material";
import logo from '../Assets/imgs/collapse-logo.svg'
import linth from '../Assets/imgs/Linth.svg'
import termsLogo from '../Assets/imgs/terms-content-logo.svg'
import { GrNext, GrPrevious } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';

const Terms = () => {
    const {t,i18n} = useTranslation()
    const navigate = useNavigate();

    const changeLanguage = (lang)=>{
        i18n.changeLanguage(lang?.target.value)
        // console.log(lang?.target.value);
    }

    const termsUseList = [
        {id:1,title:t('terms.termsofuse.termsList.term1.title'),body:t('terms.termsofuse.termsList.term1.body')},
        {id:2,title:t('terms.termsofuse.termsList.term2.title'),body:t('terms.termsofuse.termsList.term2.body')},
        {id:3,title:t('terms.termsofuse.termsList.term3.title'),body:t('terms.termsofuse.termsList.term3.body')},
        {id:4,title:t('terms.termsofuse.termsList.term4.title'),body:t('terms.termsofuse.termsList.term4.body')},
        {id:5,title:t('terms.termsofuse.termsList.term5.title'),body:t('terms.termsofuse.termsList.term5.body')},
        {id:6,title:t('terms.termsofuse.termsList.term6.title'),body:t('terms.termsofuse.termsList.term6.body')},
        {id:7,title:t('terms.termsofuse.termsList.term7.title'),body:t('terms.termsofuse.termsList.term7.body')},
        {id:8,title:t('terms.termsofuse.termsList.term8.title'),body:t('terms.termsofuse.termsList.term8.body')},
        {id:9,title:t('terms.termsofuse.termsList.term9.title'),body:t('terms.termsofuse.termsList.term9.body')},
        {id:10,title:t('terms.termsofuse.termsList.term10.title'),body:t('terms.termsofuse.termsList.term10.body')},
        {id:11,title:t('terms.termsofuse.termsList.term11.title'),body:t('terms.termsofuse.termsList.term11.body')},
        {id:12,title:t('terms.termsofuse.termsList.term12.title'),body:t('terms.termsofuse.termsList.term12.body')},
        {id:13,title:t('terms.termsofuse.termsList.term13.title'),body:t('terms.termsofuse.termsList.term13.body')},
        {id:14,title:t('terms.termsofuse.termsList.term14.title'),body:t('terms.termsofuse.termsList.term14.body')},
        {id:15,title:t('terms.termsofuse.termsList.term15.title'),body:t('terms.termsofuse.termsList.term15.body')},
    ]

    return ( 
        <article className="terms-wrapper h-screen" dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
            <div className='flex w-full h-full'>
                <section className="left-sec-wrapper min-w-[200px] max-w-[300px] h-full relative bg-mainColor">
                    <div className='takeIn-logo-wrapper text-center w-full absolute left-1/2 top-[25%] translate-x-[-50%]'>
                        <img src={logo} alt='takinIN-logo' className='w-28 h-28 m-auto' />
                    </div>

                    <div className='takeIn-linth-wrapper text-center w-full absolute left-0 bottom-0'>
                        <img src={linth} alt='takinIN-logo' className='w-full' />
                    </div>
                </section>

                <section className='right-sec-wrapper grow h-full'>
                    <div className='terms-content-wrapper pt-10 h-full px-6'>
                        <div className='terms-title-wrapper capitalize flex justify-between items-center'>
                            <div className=' flex items-center gap-x-3'>
                                <div className='previous-page-icon text-2xl cursor-pointer' onClick={()=>navigate(-1)}>
                                    {localStorage?.getItem('lang') === 'ar' ? <GrNext /> : <GrPrevious />}
                                </div>
                                <h2 className='font-normal text-xl text-[#464E5F] font-playfair'>{t('terms.title')}</h2>
                            </div>

                            <div>

                                <Select 
                                    className="bg-mainColor text-white [&>div]:p-2 w-10 h-10 text-center [&>svg]:hidden text-sm" 
                                    sx={{'& fieldset': {border:0}}}
                                    defaultValue={(localStorage?.getItem('lang') === 'en-US' || localStorage?.getItem('lang') === 'en' || !localStorage.getItem('lang')) ? 'en' : 'ar'} 
                                    onChange={(lang)=>changeLanguage(lang)}
                                >
                                    <MenuItem value={'en'}>EN</MenuItem>
                                    <MenuItem value={'ar'}>AR</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className='mt-5 bg-white rounded-lg px-3 py-5 flex gap-x-4 w-full h-[90%] items-start'>
                            
                            <section className="content-logo-wrapper basis-[35%] my-auto">
                                <img src={termsLogo} alt='terms-reading' className='w-full' />
                            </section>

                            <section className='content-text-wrapper text-[#333333] basis-[65%]'>
                                <h2 className='capitalize font-bold text-xl tracking-wider'>{t('terms.title')}</h2>

                                <section className="terms-list-wrapper mt-1 h-auto max-h-[500px] overflow-hidden overflow-y-auto">
                                    <section className='mandataory-terms text-xs text-[#333] mb-6'>
                                        <p className='text-sm font-semibold underline mb-2'>{t('terms.date')}</p>
                                        <p>{t('terms.mandatory')}</p>
                                    </section>
                                    <ol type="1" className="list-decimal px-4">
                                        {termsUseList?.map( (item)=>{
                                            return(
                                                <li key={item?.id} className="mb-3">
                                                    <p className="font-semibold text-mainColor text-lg capitalize">{item?.title?.toLowerCase()}</p>
                                                    <p className="text-[#555] text-sm">{item?.body}</p>
                                                </li>
                                            )
                                        } )}
                                    </ol>
                                </section>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </article>
    );
}

export default Terms;