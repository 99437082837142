import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getStatistics = createAsyncThunk("getStatisticsFunc", async({dateRange,fromDate,toDate}, {rejectWithValue,dispatch})=>{
    // console.log(page,size);
    // let colCheck;

    // if (col) {
    //     colCheck = col === 'Created at' ? 'created_at' : col === 'Updated at' ? 'updated_at' : col === 'Deal Name' ? 'name' : col === 'Price Before' ? 'price_before' : col.replace(/\s+/g, '');
    // }
    
    // const url = !col ? `/my-restaurant/deals?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}` : `/my-restaurant/deals?page=${page ? page : 1}&size=${size ? size : 10}${searchValue ?`&search=${searchValue}`: ''}&sortBy=${colCheck}&sortOrder=${dir.toUpperCase()}`;
    

    try {
        const res = await axios.get(`/statistics/orders?dateRange=${dateRange}${dateRange === 'custom_date_range' && fromDate && toDate ? `&fromDate=${fromDate}&toDate=${toDate}` : ''}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getOrderStatistics = createAsyncThunk("getOrderStatisticsFunc", async({dateRange,fromDate,toDate}, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`/statistics/users?dateRange=${dateRange}${dateRange === 'custom_date_range' && fromDate && toDate ? `&fromDate=${fromDate}&toDate=${toDate}` : ''}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getRevenue = createAsyncThunk("getRevenueFunc", async({dateRange,fromDate,toDate}, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`/statistics/revenues?dateRange=${dateRange}${dateRange === 'custom_date_range' && fromDate && toDate ? `&fromDate=${fromDate}&toDate=${toDate}` : ''}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getRidersStatistics = createAsyncThunk("getRidersStatisticsFunc", async({dateRange,fromDate,toDate}, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`/statistics/drivers?dateRange=${dateRange}${dateRange === 'custom_date_range' && fromDate && toDate ? `&fromDate=${fromDate}&toDate=${toDate}` : ''}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getIncome = createAsyncThunk("getIncomeFunc", async({incomeRange}, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`/accounts/takein-income-statement?duration=${incomeRange ? incomeRange : 'LAST_WEEK'}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

// account manager functions
export const getPartnerStatistics = createAsyncThunk("getPartnerStatisticsFunc", async({dateRange,fromDate,toDate}, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`/statistics/my-restaurant-orders?dateRange=${dateRange}${dateRange === 'custom_date_range' && fromDate && toDate ? `&fromDate=${fromDate}&toDate=${toDate}` : ''}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getPartnerRevenue = createAsyncThunk("getPartnerRevenueFunc", async({dateRange,fromDate,toDate}, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`/statistics/my-restaurant-revenues?dateRange=${dateRange}${dateRange === 'custom_date_range' && fromDate && toDate ? `&fromDate=${fromDate}&toDate=${toDate}` : ''}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const getPartnerIncome = createAsyncThunk("getPartnerIncomeFunc", async({incomeRange}, {rejectWithValue,dispatch})=>{

    try {
        const res = await axios.get(`https://api-develop.takein.sa/partner-app/income-statement?duration=${incomeRange ? incomeRange : 'LAST_WEEK'}`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})



const initialState = {
    loading: false,
    statistics : [],
    partnerStatistics : [],
    orderStatistics : [],
    revenues : [],
    partnerRevenue : [],
    drivers:[],
    income : null,
    partnerIncome : null,
    error : null,
}
export const ReportSlice = createSlice({ 
    name: "ReportSlice-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getStatistics.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getStatistics.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.statistics = action.payload
            // console.log(action.payload);
        })

        .addCase(getStatistics.rejected, (state,action)=>{
            state.loading = false;
            state.statistics = []
            state.error = action.payload
        })

        .addCase(getOrderStatistics.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getOrderStatistics.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.orderStatistics = action.payload
            // console.log(action.payload);
        })

        .addCase(getOrderStatistics.rejected, (state,action)=>{
            state.loading = false;
            state.orderStatistics = []
            state.error = action.payload
        })

        .addCase(getRevenue.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getRevenue.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.revenues = action.payload
            // console.log(action.payload);
        })

        .addCase(getRevenue.rejected, (state,action)=>{
            state.loading = false;
            state.revenues = []
            state.error = action.payload
        })

        .addCase(getRidersStatistics.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getRidersStatistics.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.drivers = action.payload
            // console.log(action.payload);
        })

        .addCase(getRidersStatistics.rejected, (state,action)=>{
            state.loading = false;
            state.drivers = []
            state.error = action.payload
        })

        .addCase(getIncome.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getIncome.fulfilled, (state,action)=>{
            state.loading = false;
            state.income = action?.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getIncome.rejected, (state,action)=>{
            state.loading = false;
            state.income = null
            state.error = action.payload
        })

        // account manager functions
        .addCase(getPartnerStatistics.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getPartnerStatistics.fulfilled, (state,action)=>{
            state.loading = false;
            state.partnerStatistics = action?.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getPartnerStatistics.rejected, (state,action)=>{
            state.loading = false;
            state.partnerStatistics = null
            state.error = action.payload
        })

        .addCase(getPartnerRevenue.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getPartnerRevenue.fulfilled, (state,action)=>{
            state.loading = false;
            state.partnerRevenue = action?.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getPartnerRevenue.rejected, (state,action)=>{
            state.loading = false;
            state.partnerRevenue = null
            state.error = action.payload
        })

        .addCase(getPartnerIncome.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getPartnerIncome.fulfilled, (state,action)=>{
            state.loading = false;
            state.partnerIncome = action?.payload
            state.error = ""
            // console.log(action.payload);
        })

        .addCase(getPartnerIncome.rejected, (state,action)=>{
            state.loading = false;
            state.partnerIncome = null
            state.error = action.payload
        })
    }
});

export default ReportSlice.reducer

