import { useTranslation } from 'react-i18next';
import AllContractors from './Contractors-Components/allContractors';


const DeliveryContractors = () => {
    const {t, i18n} = useTranslation()


    return ( 
        <article className="DeliveryContractors-wrapper">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('deliveryContractors.title')}</h1>
            </section>
            
            <section>
                <AllContractors t={t} />
            </section>
            
        </article>
    );
}

export default DeliveryContractors;