import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import previewIcon from '../../../../Assets/imgs/previewIcon.svg'
import { Button, Icon, InputAdornment, ListItemButton, TextField, Tooltip } from "@mui/material";
import { BiEditAlt } from "react-icons/bi";
import { IoMdAdd, IoMdHelpCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFile } from "../../../../Components/Redux/Slice/Upload/file";
import { editDeal, getDealById, getDeals } from "../../../../Components/Redux/Slice/AccountManager/Deals/deals";
import Overlay from "../../../../Components/Common/overlay";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from "react-i18next";

const EditDeal = () => {
    const {t,i18n} = useTranslation()
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate()
    const location = useLocation()
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,watch} = form
    const {errors} = formState
    const [preview, setPreview] = useState(location?.state?.image);
    const [banner, setbanner] = useState(location?.state?.banner);
    const [pageType,setpageType] = useState('edit')
    const [isImage, setisImage] = useState(null)
    const [isBanner, setisBanner] = useState(null)

    const dispatch = useDispatch()
    const {file} = useSelector(state=>state)

    let id = location?.state?.id


    useEffect(() => {

    }, [location?.state])
    
    // console.log(location?.state);
    const maxSize = 1024*1024*5
    const compressionLimit = 55
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    const handleImageChange = (e)=> {
        const file = e.target.files[0];

        if(file){
    
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setisImage(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    setPreview(reader.result);
                }
            }
        }
    }

    const handleBannerChange = (e)=> {
        const file = e.target.files[0];

        if(file){
        
            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('lang') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setisBanner(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                    setbanner(reader.result);
                }
            }
        }
    }

    // console.log(getValues());

    // adds on

    const [options, setoptions] = useState([]);

    const addFieldsHandler = () => {
        setoptions(options => [...options, {}]);
    }

    const [extras, setextras] = useState([]);

    const addExtrasHandler = () => {
        setextras(extras => [...extras, {}]);
    }

    const [loading, setloading] = useState(true)

    const {deals} = useSelector(state=>state)

    // console.log(deals);

    useEffect(() => {

        if(pageType === 'edit'){
            let options = deals?.dealById?.data?.options || [];
            setoptions(options); 
    
            let extras = deals?.dealById?.data?.extras || [];
            setextras(extras);

            options.forEach((option, index) => {
                setValue(`options[${index}].name`, option?.name);
                setValue(`options[${index}].price`, option?.price);
                setValue(`options[${index}].calories`, option?.calories);
            });
            
            extras.forEach((extra, index) => {
            setValue(`extras[${index}].name`, extra?.name);
            setValue(`extras[${index}].price`, extra?.price);
            setValue(`extras[${index}].calories`, extra?.calories);
            });

        } else {
            setoptions([])
            setextras([]);
        }

        // console.log(options);

    }, [deals?.loading])

    watch('options')
    watch('extras')
    watch()

    useEffect(() => {

        dispatch(getDealById(id))

        setValue('name', location?.state?.name); 
        setValue('description', location?.state?.description);
        setValue('price', location?.state?.price);
        setValue('priceBefore', location?.state?.priceBefore);
        setValue('calories', location?.state?.calories);
        setValue('image', location?.state?.image);
        setValue('banner', location?.state?.banner);
        setValue('options', JSON.stringify(deals?.dealById?.data?.options))
        setValue('extras', JSON.stringify(deals?.dealById?.data?.extras))
        
        // const options = menuItem?.menuItem?.data?.options;
        // setoptions(options); 

        // options&&options?.forEach((option, index) => {
        //     setValue(`options[${index}].name`, option?.name);
        //     setValue(`options[${index}].price`, option?.price);
        //     setValue(`options[${index}].calories`, option?.calories);
        // });

        // // const extras = menuItem?.data?.extras ?? [];
        // // setextras(extras); 

        // extras?.forEach((extra, index) => {
        //     setValue(`extras[${index}].name`, extra?.name);
        //     setValue(`extras[${index}].price`, extra?.price);
        //     setValue(`extras[${index}].calories`, extra?.calories);
        // });


        // console.log(options);
    }, [dispatch, location, setValue,getValues,loading])

    let page = 1,
        size = 10;

    let formData = new FormData()

    const submitHandler = async (values)=>{
        // const optionJsonString = JSON.stringify(values.options);
        // const extraJsonString = JSON.stringify(values.extras)

        let ImageResponse = null,
            bannerResponse = null

        const image = new FormData()
        image.append('file',isImage)

        const banner = new FormData()
        banner.append('file',isBanner)


        try {

                if(isImage !== null){
                    ImageResponse = await dispatch(addFile(image));
                }

                if(isBanner !== null){
                    bannerResponse = await dispatch(addFile(banner))
                    
                }
                
                formData = {
                    ...values, // include all other form data
                    image : ImageResponse?.payload?.data?.url,
                    banner : bannerResponse?.payload?.data?.url
                }

                await dispatch(editDeal({formData,id})).then( async (e)=>{
                        if(e?.payload?.message === 'SUCCESS') {
                            await dispatch(getDeals({ page, size })).then( ()=>navigate('/dashboard/partner/deals') )
                        }  
                    } )
                // if(ImageResponse !== null){
                //     ImageResponse = await dispatch(addFile(image));

                //     if (ImageResponse.payload?.data && bannerResponse !== null) {

                //         bannerResponse = await dispatch(addFile(banner))                        
                //     }

                //     if(ImageResponse && bannerResponse){
                //         formData = {
                //             ...values, // include all other form data
                //             // options: optionJsonString, // convert options array to JSON string
                //             // extras: extraJsonString, // convert extras array to JSON string,
                //             image : ImageResponse?.payload?.data?.url,
                //             banner : bannerResponse?.payload?.data?.url
                //         }
    
                //         await dispatch(editDeal({formData,id})).then( async (e)=>{
                //             if(e?.payload?.message === 'SUCCESS') {
                //                 await dispatch(getDeals({ page, size })).then( ()=>navigate('/dashboard/deals') )
                //             }  
                //         } )
                //     }
                // } else {
                //     formData = {
                //         ...values, // include all other form data
                //         // options: optionJsonString, // convert options array to JSON string
                //         // extras: extraJsonString, // convert extras array to JSON string,
                //     }
                //     await dispatch(editDeal({formData,id})).then( async (e)=>{
                //         if(e?.payload?.message === 'SUCCESS') {
                //             await dispatch(getDeals({ page, size })).then( ()=>navigate('/dashboard/deals') )
                //         }  
                //     } )
                // }
                
                
                
            } catch (error) {
                // console.log(error);
            }
            // console.log(values);
    }

    watch('image');
    watch('banner');
    useEffect(() => {
        
    }, [setValue,watch,formData])
    
    return ( 
        <article className="add-new-dish-wrapper mt-5 mb-10">
            <div className="new-dish-wrapper">
                <section className="new-dish-title-wrapper">
                    <h1 className="text-xl font-semibold text-[#464E5F]">{t('deals.edit')}</h1>
                </section>

                <section className="new-dish-grid-wrapper mt-5">
                    <form noValidate onSubmit={handleSubmit(submitHandler)} className="flex flex-wrap justify-center lg:justify-start items-start gap-5 lg:gap-3">

                        <section className="menu-item-image-preview-wrapper basis-full md:basis-[45%] lg:basis-[20%] h-auto">

                            <div className="preview-wrapper shadow-md w-full h-[200px] rounded-xl overflow-hidden relative p-2 bg-[#f7f5fd] ">
                                <input type="file" id="file" accept="image/*" onChange={handleImageChange} className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-1 w-full h-full z-40 opacity-0" />
                                <div className={`absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-full bg-mainYellow ${preview ? 'z-10' : 'z-30'}`}>
                                    <div className="text-center text-white absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-[70%] m-auto flex flex-wrap justify-center items-center [&>*]:basis-full">
                                        <img src={previewIcon} alt="preview" className={`w-[70px] h-[70px]`} />
                                        <p className="lg:text-sm xl:text-base">Upload Deal image</p>
                                    </div>
                                </div>
                                {preview && <img src={preview} alt="Preview" className="w-full h-full absolute top-0 bottom-0 left-0 right-0 z-20" />}
                            </div>

                            <div className="banner-wrapper shadow-md w-full h-[200px] rounded-xl overflow-hidden relative p-2 bg-[#f7f5fd] mt-4">
                                <input type="file" id="banner" accept="image/*" onChange={handleBannerChange} className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-1 w-full h-full z-40 opacity-0" />
                                <div className={`absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-full bg-[#8fa0ec] ${banner ? 'z-10' : 'z-30'}`}>
                                    <div className="text-center text-white absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-[70%] m-auto flex flex-wrap justify-center items-center [&>*]:basis-full">
                                        <img src={previewIcon} alt="preview" className={`w-[70px] h-[70px]`} />
                                        <p className="lg:text-sm xl:text-base">Upload Banner image</p>
                                    </div>
                                </div>
                                {banner && <img src={banner} alt="Preview" className="w-full h-full absolute top-0 bottom-0 left-0 right-0 z-20" />}
                            </div>
                        </section>

                        <section className="upload-item-info-wrapper basis-full lg:basis-[42%] bg-white p-6 rounded-xl">
                            <div className="upload-form-title mb-5">
                                <h2 className="text-lg text-[#333333] font-semibold capitalize">Upload a deal</h2>
                            </div>
                            
                            <div className="form-input-wrapper [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                <div className="[&>input]:w-full">
                                    <label>deal name</label>
                                    <input type="text" id="name"
                                    {...register('name',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        }
                                    })} />

                                    {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter deal name</p>}
                                </div>

                                <div className="flex flex-wrap lg:flex-nowrap [&>div>p]:mt-1 [&>div]:basis-full md:[&>div]:basis-[48%] gap-5 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor">
                                    <div>
                                        <label>deal price</label>

                                        <TextField
                                            id="price"
                                            {...register('price',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                max:{
                                                    value:20000,
                                                    message : 'Max value is 20000'
                                                },
                                                valueAsNumber : true
                                            })}
                                            // label="With normal TextField"
                                            // sx={{ m: 1, width: '25ch', }}
                                            sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
                                            }}
                                            inputProps={{
                                                min:0
                                            }}
                                        />

                                        {errors?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter price</p>}
                                    </div>

                                    <div>
                                        <label>price before</label>

                                        <TextField
                                            id="price"
                                            {...register('priceBefore',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                max:{
                                                    value:20000,
                                                    message : 'Max value is 20000'
                                                },
                                                valueAsNumber : true
                                            })}
                                            // label="With normal TextField"
                                            // sx={{ m: 1, width: '25ch', }}
                                            sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
                                            }}
                                            inputProps={{
                                                min:0
                                            }}
                                        />

                                        {errors?.priceBefore?.message ? <p className="capitalize text-sm text-danger">{errors?.priceBefore?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter price before</p>}
                                    </div>
                                </div>

                                <div className="[&>input]:w-full">
                                    <label>Calories</label>
                                    <input type="number" id="calories"
                                        {...register('calories',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            valueAsNumber : true
                                        })} 
                                        min={1}
                                        />
                                    {errors?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter deal calories</p>}
                                </div>

                                <div className="mt-3">
                                        <label>Description</label>
                                        <textarea name="description" id="description" className="w-full min-h-[100px] h-auto p-3 bg-[#f3f6f9] resize-none rounded-md"
                                            {...register('description',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                }
                                            })}
                                        >
                                        </textarea>

                                        {errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter deal description</p>}
                                </div>

                            </div>
                        </section>

                        <section className="add-ads-on-wrapper basis-full lg:basis-[34%]">
                            <div className="flex flex-col ">
                                <div className="add-ads-on bg-white px-6 py-8 rounded-xl">
                                    <div className="flex justify-between items-start">
                                        <h2 className="flex items-center text-[#333] font-semibold mb-5" >Add Options <span className="ml-2 text-2xl text-mainGreen"><BiEditAlt /></span></h2>

                                        <Tooltip
                                            placement="top"
                                            className="text-lg capitalize"
                                            title={'The way the dish is cooked e.g: well done/ medium/ rare'}    
                                        >
                                            <Icon className="w-auto h-auto">
                                                <IoMdHelpCircle className="text-mainColor text-2xl" />
                                            </Icon>
                                        </Tooltip>
                                    </div>

                                    { options&&options?.map((_, index) => (
                                            <div key={index} className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center [&>div]:grow gap-x-3 mt-1 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                                <div className="[&>input]:w-full">
                                                    <label>Name</label>
                                                    <input type="text" id={`addName${index}`}
                                                        {...register(`options[${index}].name`, {
                                                            required: {
                                                            value: true,
                                                            message: t('common.required')
                                                            }
                                                        })}

                                                        defaultValue={options[index].name}
                                                    />
                                                    {errors?.options?.[index]?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">option name</p>}
                                                </div>

                                                <div className="[&>input]:w-full">
                                                    <label>Price</label>
                                                    <input type="text" id={`addPrice${index}`}
                                                        {...register(`options[${index}].price`, {
                                                            required: {
                                                            value: true,
                                                            message: t('common.required')
                                                            }
                                                        })}
                                                        defaultValue={options[index].price}
                                                    />
                                                    {errors?.options?.[index]?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">option price</p>}
                                                </div>

                                                <div className="[&>input]:w-full">
                                                    <label>Calories</label>
                                                    <input type="text" id={`addCalories${index}`}
                                                        {...register(`options[${index}].calories`, {
                                                            required: {
                                                            value: true,
                                                            message: t('common.required')
                                                            }
                                                        })}
                                                        defaultValue={options[index].calories}
                                                    />
                                                    {errors?.options?.[index]?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">option calories</p>}
                                                </div>
                                            </div>
                                        ))}


                                        {<div className="mt-4">
                                            <ListItemButton onClick={addFieldsHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                                <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">add more</span>
                                            </ListItemButton>
                                        </div> 
                                        }
                                </div>

                                <div className="add-extras bg-white px-6 py-9 rounded-xl mt-9">
                                    <div className="flex justify-between items-start">
                                        <h2 className="flex items-center text-[#333] font-semibold mb-5">Add Extras <span className="ml-2 text-2xl text-mainGreen"><BiEditAlt /></span></h2>

                                        <Tooltip
                                            placement="top"
                                            className="text-lg capitalize"
                                            title={'An extra dish of food, vegetables or salad ...etc, that is served with the main dish'}    
                                        >
                                            <Icon className="w-auto h-auto">
                                                <IoMdHelpCircle className="text-mainColor text-2xl" />
                                            </Icon>
                                        </Tooltip>
                                    </div>
                                    { extras&&extras?.map((_, index) => (
                                            <div key={index} className="w-full flex flex-wrap lg:flex-nowrap justify-between items-center [&>div]:grow gap-x-3 mt-1 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                                <div className="[&>input]:w-full">
                                                    <label>Name</label>
                                                    <input type="text" id={`addName${index}`}
                                                        {...register(`extras[${index}].name`, {
                                                            required: {
                                                            value: true,
                                                            message: t('common.required')
                                                            }
                                                        })}
                                                        defaultValue={extras[index].name}
                                                    />
                                                    {errors?.extras?.[index]?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras name</p>}
                                                </div>

                                                <div className="[&>input]:w-full">
                                                    <label>Price</label>
                                                    <input type="text" id={`addPrice${index}`}
                                                        {...register(`extras[${index}].price`, {
                                                            required: {
                                                            value: true,
                                                            message: t('common.required')
                                                            }
                                                        })}
                                                        defaultValue={extras[index].price}
                                                    />
                                                    {errors?.extras?.[index]?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras price</p>}
                                                </div>

                                                <div className="[&>input]:w-full">
                                                    <label>Calories</label>
                                                    <input type="text" id={`addCalories${index}`}
                                                        {...register(`extras[${index}].calories`, {
                                                            required: {
                                                            value: true,
                                                            message: t('common.required')
                                                            }
                                                        })}
                                                        defaultValue={extras[index].calories}
                                                    />
                                                    {errors?.extras?.[index]?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras calories</p>}
                                                </div>
                                            </div>
                                        ))}

                                    {<div className="mt-4">
                                        <ListItemButton onClick={addExtrasHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                            <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">add more</span>
                                        </ListItemButton>
                                    </div>}
                                </div>
                            </div>
                        </section>

                        <section className="adds-on-submit-btn basis-full flex justify-end mt-4">
                            <Button type="submit" disabled={file?.loading || deals?.loading} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainGreen text-white text-base font-bold capitalize w-[200px] py-3 rounded-lg">Edit item</Button>
                        </section>

                    </form>
                </section>
            </div>
            {file?.loading || deals?.loading ? <Overlay /> : null}
        </article>
    );
}

export default EditDeal;