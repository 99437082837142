import { Link } from "react-router-dom";

const BreadCrumb = ({paths,seperator}) => {
    return ( 
        <>
            <div className="mb-5 flex gap-x-1 items-center text-sm  capitalize mt-2">
                {paths?.map( (item,index)=>(
                    <div key={index} className={`flex gap-x-1 items-center font-[500] ${paths?.length -1 === index ? 'text-gray-400' : 'text-mainColor' }`}>
                        
                        {paths?.length -1 !== index ? 
                            <>
                                <Link to={item?.path} >{localStorage.getItem('lang') === 'ar' ? item?.nameAr : item?.name}</Link> {paths?.length -1 === index ? null : seperator}
                            </>
                        :
                        <span>{localStorage.getItem('lang') === 'ar' ? item?.nameAr : item?.name}</span>
                        }
                    </div>
                ) )}
            </div>
        </>
    );
}

export default BreadCrumb;