import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../Components/Redux/Slice/Toast-Messages/toastMessage";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { Box, Button, Modal } from "@mui/material";
import { FaRegTimesCircle } from "react-icons/fa"
import Astrisks from "../../../../Components/Common/astrisk";
import { getCustAddress } from "../../../../Components/Redux/Slice/Delivery/delivery";
import { useGoogleMaps } from "../../../../Components/Common/mapLoader";
import DeliveryMap from "./deliveryMap";
import Overlay from "../../../../Components/Common/overlay";

const libraries = ["places"];

const CustomerMap = ({open,close,resetForm,t}) => {
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,reset} = form
    const {errors,isDirty} = formState
    const {key} = useSelector(state=>state?.map)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);

    const [map, setMap] = useState(null)

    // const {isLoaded} = useJsApiLoader({
    //     id: 'google-map-script-order',
    //     googleMapsApiKey : key?.data?.GOOGLE_MAP_KEYS,  
    //     libraries 
    // })
    const { isLoaded, loadError } = useGoogleMaps();


    const [center,setcenter] = useState({lat : 24.711237,lng :  46.684593})

    const [markerPosition,setmarkerPosition] = useState({lat : 24.711237,lng :  46.684593})
    const [address,setaddress] = useState(null)
    const [error,seterror] = useState(null)

    const onLoad = useCallback(function callback(map) {
        setMap(map)
        }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const handleMarkerDragEnd = (event) => {
        setLoading(true); 
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
    
        setmarkerPosition({ lat, lng });
        seterror(null)
        const geocoder = new window.google.maps.Geocoder();
        // console.log([lat,lng]);
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
            if (results[0]) {
            setaddress(results[0].formatted_address);
            setselectedAddress(results[0].formatted_address)
            } else {
                dispatch(errorToast('No results found, Choose another location'))
            }
        } else {
            dispatch(errorToast(`Geocoder failed due to: ${status}`))
        }
        setLoading(false); 
        });
    }
    

    // autocomplete service for the google map
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        if (isLoaded) {
        const autocomplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            const location = place.geometry.location;

            setmarkerPosition({ lat: location.lat(), lng: location.lng() });
            setaddress(place.formatted_address);
        });
        }
    }, [isLoaded]);
    
    const [selectedAddress,setselectedAddress] = useState(null)

    const handleSelect = async (selectedAddress) => {
        try {
            const results = await geocodeByAddress(selectedAddress);
            const latLng = await getLatLng(results[0]);
            // console.log(latLng);
            setaddress(selectedAddress);
            setselectedAddress(selectedAddress)
            setmarkerPosition(latLng);
            setcenter(latLng)
            seterror(null)
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const modalSubmitHandler = async (formData)=>{
        // setValue('file',img?.result)
        // console.log(formData);
        if(selectedAddress !== address){
            seterror('select valid address from dropdown or drag and drop marker')
        } else{
            if(error === null && address !== null){
                dispatch(getCustAddress(formData))
                close()
                    // reset()
                    // seterror(null)
                    // setselectedAddress(null)
                    // setaddress(null)
                    // setmarkerPosition(center)
                // .then( ()=>{
                // } )
            } else{
                seterror('Enter a valid address')
            }
        }
    }

    useEffect(() => {
        // console.log(address);
        // console.log(selectedAddress);
        setValue('address',address)
        // setValue('coordinates', {"type": "Point","coordinates": [markerPosition?.lng,markerPosition?.lat],})
        setValue('latitude', markerPosition?.lat)
        setValue('longitude', markerPosition?.lng)
    }, [address, markerPosition, selectedAddress, setValue])
    

    useEffect(() => {
        reset({
            address : '',
            latitude : '',
            longitude : ''
        })
        setaddress('')
        setmarkerPosition(center)
        
    }, [resetForm])

    return ( 
        <article className="restaurant-map-wrapper">
            <Modal
                open={open}
                onClose={()=>{
                    close()
                    // reset()
                    // setselectedAddress(null)
                    // setaddress(null)
                    // seterror(null)
                    // setmarkerPosition(center)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box 
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                    sx={{position:'absolute',top:'11%',left:'50%',transform: 'translate(-50%, -10%)',bgcolor:'background.paper',boxShadow:3,p:4,borderRadius:5,height:'auto',minWidth:'50%',minHeight:'550px'}}>
                    <div className="w-full flex justify-between items-start">
                        <h2 className="text-xl text-mainColor capitalize font-semibold">{t('delivery.customer_map.title')}</h2>

                        <div className="mb-2">
                            <span className="text-mainRed text-xl text-right [&>svg]:ml-auto cursor-pointer" onClick={()=>{
                                close()
                                // reset()
                                // setselectedAddress(null)
                                // setaddress(null)
                                // seterror(null)
                                // setmarkerPosition(center)
                            }}><FaRegTimesCircle /></span>
                        </div>

                    </div>

                    <form className="restaurant-map-form-wrapper w-full h-full" onSubmit={handleSubmit(modalSubmitHandler)} noValidate>
                        
                        <div className="form-input-wrapper max-h-[415px] overflow-hidden overflow-y-auto mt-6 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div className="grid grid-cols-1 gap-3 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-2  [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                <div>
                                    <label>{t('delivery.customer_map.address')} <Astrisks /></label>
                                    {isLoaded ?
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={setaddress}
                                            onSelect={handleSelect}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="relative [&>input]:bg-[#f3f6f9] [&>input]:w-full [&>input]:p-2  [&>input]:focus:outline-slate-400 [&>input]:rounded-md [&>input]:text-mainColor [&>input]:mb-1">
                                                <input 
                                                    {...getInputProps({ placeholder: "" })} 
                                                    value={address} 
                                                />
                                                {error ? (
                                                    <p className="text-sm capitalize mt-1" style={{ color: "red" }}>{error}</p>
                                                    ) : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('delivery.customer_map.address_helper')}</p>}
                                                <div className="shadow-md max-h-[170px] overflow-y-auto absolute z-50 w-full bg-white [&>div]:py-1 px-2">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                    return (
                                                    <div key={suggestion?.id} {...getSuggestionItemProps(suggestion, { className })}>
                                                        {suggestion.description}
                                                    </div>
                                                    );
                                                })}
                                                </div>
                                            </div>
                                            )}
                                        </PlacesAutocomplete> : null  
                                    }
                                </div>
                            </div>

                            <div className="w-full mt-4">
                                <div className="map">
                                    <DeliveryMap center={center}>
                                        <Marker
                                            position={markerPosition}
                                            draggable={true}
                                            onDragEnd={handleMarkerDragEnd}
                                            // icon={{
                                            //     url: marker1, // Path to the custom marker image
                                            //     scaledSize: new window.google.maps.Size(30, 30), // Set the size of the marker
                                            // }}
                                        />
                                    </DeliveryMap>

                                    {/* {isLoaded ? 
                                        <div className="w-full h-full [&>div]:w-full [&>div]:h-[280px]">
                                            <GoogleMap
                                                center={center}
                                                zoom={14}
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                            > 
                                                <Marker
                                                    position={markerPosition}
                                                    draggable={true}
                                                    onDragEnd={handleMarkerDragEnd}
                                                    // icon={{
                                                    //     url: marker1, // Path to the custom marker image
                                                    //     scaledSize: new window.google.maps.Size(30, 30), // Set the size of the marker
                                                    // }}
                                                />
                                        </GoogleMap> 

                                        </div>
                                        : 
                                        'Loading...'
                                    } */}
                                </div>
                            </div>
                        </div>

                        <div className={`profile-submit-btn-wrapper text-white flex gap-x-5 [&>div]:w-fit [&>div]:grow-0 justify-end [&>div]:p-0 mt-6 ${localStorage?.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                            {/* <ListItemButton onClick={close} className="justify-center bg-[#442b7e4f]  text-sm font-semibold rounded-md"><button  className="w-full py-3 px-8">Close</button></ListItemButton> */}
                            <Button type="submit" className="justify-center bg-mainColor text-white capitalize w-[150px] py-2 text-sm font-semibold rounded-md disabled:opacity-50 disabled:cursor-not-allowed" disabled={loading}>{t('common.save_btn')}</Button> 
                        </div>

                    </form>
                </Box>
            </Modal>
            {loading ? <Overlay /> : null}
        </article>
    );
}

export default CustomerMap;