import { AiFillStar } from "react-icons/ai";
import { BsFillEmojiHeartEyesFill, BsFillEmojiSmileFill, BsFillEmojiNeutralFill, BsFillEmojiFrownFill } from "react-icons/bs";
import {GiVomiting} from 'react-icons/gi'
import { fullDate } from "../../../Common/dateFormate";

const Reviews = ({data,t}) => {

    // console.log(data);
    return ( 
        <article className="review-component-wrapper mb-4 border-b pb-4 border-[#ddd]">
            <div className="flex justify-between items-center gap-4">
                <section className="review-left-wrapper min-w-[72%] max-w-[72%]">
                    <div className="review-title-wrapper capitalize flex justify-between items-center">
                        <div>
                            <p className="text-[#333] text-lg font-bold">{data?.customerName}</p>
                            <p className="text-sm font-semibold text-mainColor my-1">{data?.orderFeedback ? data?.orderFeedback : <span className="italic">{t('common.no_comment')}</span>}</p>
                            <p className="text-[10px] text-[#999999]">{fullDate(data?.createdAt)}</p>
                        </div>

                        <div className={`capitalize [&>div]:flex [&>div]:items-center [&>div]:gap-x-3 [&>div>svg]:text-xl ${localStorage.getItem('lang')==='ar' ? '[&>div]:flex-row-reverse' : '[&>div]:flex-row'}`}>
                            {
                                data?.orderRating ?
                                    data?.orderRating >= 5 ?
                                    <div className="text-[#5CAC7D]">
                                        <span className="text-sm">{t('dashboard.dash_rating.rating.awesome')}</span>
                                        <BsFillEmojiHeartEyesFill />
                                    </div> :

                                    data?.orderRating >= 4 && data?.orderRating < 5 ?
                                    <div className="text-[#D9AE56]">
                                        <span className="text-sm">{t('dashboard.dash_rating.rating.good')}</span>
                                        <BsFillEmojiSmileFill />
                                    </div> :

                                    data?.orderRating >= 3 && data?.orderRating < 4 ?
                                    <div className="text-[#FCA785]">
                                        <span className="text-sm">{t('dashboard.dash_rating.rating.meh')}</span>
                                        <BsFillEmojiNeutralFill />
                                    </div> :

                                    data?.orderRating >= 2 && data?.orderRating < 3 ?
                                    <div className="text-[#FA5769]">
                                        <span className="text-sm">{t('dashboard.dash_rating.rating.bad')}</span>
                                        <BsFillEmojiFrownFill />
                                    </div> :

                                    data?.orderRating >= 1 && data?.orderRating < 2 ?
                                    <div className="text-[#C6345C]">
                                        <span className="text-sm">{t('dashboard.dash_rating.rating.terrible')}</span>
                                        <GiVomiting />
                                    </div> : null
                                : null
                            }
                            
                        </div>
                    </div>

                    {/* <div className="text-base text-mainColor mt-2">
                        <p>{data?.orderFeedback}</p>
                    </div> */}
                </section>

                <section className="review-right-wrapper grow flex flex-wrap justify-center [&>div]:basis-full [&>div]:text-center">
                    <div className="mb-2 text-3xl text-mainColor font-extrabold">
                        {data?.orderRating}
                    </div>

                    <div className="flex justify-center">
                        {[...Array(data?.orderRating)].map(()=><AiFillStar className="text-mainYellow" />)}
                    </div>

                    {/* <div className="mt-4 [&>div]:justify-center [&>div]:w-fit [&>div]:border [&>div]:border-[#b5b5c3]">
                        <Button  className="capitalize m-auto text-[#B5B5C3] py-2 px-4 rounded-md flex items-center">
                            <MdOutlineReport className={`text-xl ${localStorage.getItem('lang')==='ar' ? 'ml-2' : 'mr-2'}`} /> 
                            <span>{localStorage.getItem('lang')==='ar' ? 'إبلاغ' : 'report'}</span>
                        </Button>
                    </div> */}
                </section>
            </div>
        </article>
    );
}

export default Reviews;