import { useState } from 'react';
import { timeOnlyFormate } from '../../Common/dateFormate';
import { useNavigate } from 'react-router-dom';

const RestaurantRequest = ({data,t}) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // console.log(data);
    const navigateHandler = ()=>{
        // <Navigate state={{id:data?.id,type:'request',data}} to='/dashboard/restaurant/details' />
        navigate('/dashboard/restaurant/details',{state:{id:data?.id,type:'request',data}})
    }
    return ( 
        <div className="restaurant-request-item-wrapper mb-1 border-b pb-2 cursor-pointer" onClick={navigateHandler}>
            <div className="flex items-center justify-between">

                <div className=' request-left-section flex items-center gap-x-2 justify-start'>
                    <div>
                        <img src={data?.logoUrl} loading='lazy' alt={localStorage.getItem('lang') === 'ar' ? data?.nameAr : data?.restaurantName} className='w-[70px] h-[70px] rounded-md' />
                    </div>

                    <div className='text-mainYellow [&>p]:mb-1'>
                        <p className='text-[#464E5F] font-semibold text-lg pl-1'>{localStorage.getItem('lang') === 'ar' ? data?.nameAr : data?.restaurantName}</p>
                        {/* <p className='flex items-center'><HiOutlineLocationMarker className='mr-1 text-lg' /> <span className='text-[14px]'>{data?.mainBranchAddress ? data?.mainBranchAddress : 'No Address Found'}</span></p> */}
                        <p className='text-[11px] text-[#B5B5C3] pl-1 mt-2'>{timeOnlyFormate(data?.createdAt)}</p>
                    </div>

                </div>
                
                {/* <div className=''>
                <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
                    <Menu
                        id="datatable-action-menu"
                        aria-labelledby="datatable-action-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        PaperProps={{ style: { boxShadow: 'none',padding:0} }}
                        className='shadow-lg p-0'
                    >
                        <div className=' [&>li>svg]:mr-2 [&>li>svg]:text-xl rounded-md overflow-hidden capitalize'>
                            <MenuItem className='text-mainGreen hover:bg-[#5cac7d42] mb-2'><BsCheck2Circle /> accept request</MenuItem>
                            <MenuItem className='text-mainRed hover:bg-[#c6345b42]'><MdOutlineCancel />Cancel request</MenuItem>
                        </div>
                    </Menu>
                </div> */}
            </div>
        </div>
    );
}

export default RestaurantRequest