import { Skeleton } from "@mui/material";

export const MenuSkeletons = () => {
    return ( 
        <div className="bg-white p-3 rounded-md">
            <div className="flex flex-wrap gap-4">
                <div>
                    <Skeleton variant="rectangular" className="rounded-md" width={80} height={80} />
                </div>
                <div className="flex flex-col flex-wrap justify-center grow [&>span]:block [&>span]:max-w-[70%]">
                    <Skeleton variant="text" sx={{ fontSize: '10px',height:'30px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '10px',height:'15px' }} />
                </div>
            </div>

            <div className="mt-2">
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </div>

            <div className="flex justify-end gap-x-2 mt-2">
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="circular" width={30} height={30} />
            </div>
        </div>
    );
}

export const ItemSkeletons = () => {
    return ( 
        <div className="bg-white p-3 rounded-md">
            <div className="flex flex-wrap justify-between items-center">
                <div className="flex flex-wrap basis-[60%] gap-x-2">
                    <div>
                        <Skeleton variant="rectangular" className="rounded-md" width={60} height={60} />
                    </div>
                    <div className="flex flex-col flex-wrap justify-center grow [&>span]:block [&>span]:max-w-[70%]">
                        <Skeleton variant="text" sx={{ fontSize: '10px',height:'30px' }} />
                        <Skeleton variant="text" sx={{ fontSize: '10px',height:'15px' }} />
                        <Skeleton variant="text" sx={{ fontSize: '8px',height:'10px' }} />
                    </div>
                </div>

                <div className="basis-[30%] text-right">
                    <Skeleton variant="rectangular" className="rounded-md ml-auto" sx={{ fontSize: '10px',height:'40px',width:'40px' }} />
                </div>
            </div>

        </div>
    );
}

export const RatingSkeletons = () => {
    return ( 
        <div className="bg-white p-3 rounded-md">
            <div className="mt-2">
                <div className="[&>span]:block mb-4">
                    <Skeleton className="w-[120px]" variant="text" sx={{ fontSize: '1.4rem' }} />
                    <Skeleton className="w-[90px]" variant="text" sx={{ fontSize: '.3rem' }} />
                </div>

                <div>

                    <div>
                    <Skeleton variant="text" sx={{ fontSize: '.7rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '.7rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '.7rem' }} />
                    </div>

                </div>
            </div>
        </div>
    );
}