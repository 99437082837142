export const TableStyles = {
    headRow: {
        style: {
        border: 'none',
        backgroundColor : '#fff',
        // fontFamily : "Playfair Display, serif "
        },
    },
    headCells: {
        style: {
            color: '#B5B5C3',
            fontSize: '1rem',
            position : 'relative',
            justifyContent : 'flex-start',
            fontFamily : "Playfair Display, serif "
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: '#442b7e12',
            borderBottomColor: '#FFFFFF',
            borderRadius: '5px',
            outline: '1px solid #FFFFFF',
        },
        style : {
            cursor : 'pointer'
        }
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
    cells: {
        style:{
            // padding : '5px 10px',
            fontSize : '1rem',
            justifyContent : 'flex-start',
            fontWeight : '400',
            // cursor : 'pointer'
        }
    }
};