import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getMealsType = createAsyncThunk("getMealsTypeFunc", async(_, {rejectWithValue,dispatch})=>{
    try {
        const res = await axios.get(`/meal-types/list`)
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

const initialState = {
    loading: false,
    mealsType : [],
    error : null,
}
export const MealsTypeSlice = createSlice({ 
    name: "MealsType-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(getMealsType.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(getMealsType.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.mealsType = action.payload
            // console.log(action.payload);
        })

        .addCase(getMealsType.rejected, (state,action)=>{
            state.loading = false;
            state.mealsType = ""
            state.error = action.payload
        })

    }
});

export default MealsTypeSlice.reducer

