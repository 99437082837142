import { BsTelephone } from "react-icons/bs";
import './orderDetails.scss'
import { CiLocationOn } from "react-icons/ci";
import TimeLine from "../../../../Components/Timeline/timeline";

const OrderDetailsLeftSection = ({data,t}) => {
    // console.log(data);
    const plate = data?.vehiclePlate
    const splitted = plate?.split("")

    // [
    //     {name:'waiting',time:'12:00 pm',status:'active'},
    //     {name:'accepted',time:'10:20 pm',status:'active'},
    //     {name:'cooked',time:'12:00 pm',status:'pending'},
    //     {name:'picked by hero',time:'12:00 pm',status:'inactive'},
    // ]
    const timelineEvents = data?.history
    return ( 
        <div className="left-section-wrapper flex flex-col flex-wrap mb-10">
            <div className="left-section-order-info-wrapper bg-white rounded-lg pt-4">
                <div className="customer-info-wrapper px-4 flex flex-wrap justify-between items-center mb-6">
                    <div className="customer-details capitalize text-[#464E5F] font-semibold mb-2 md:mb-0">
                        <p className="text-sm mb-1">{t('order_details.left_section.customer_details')}</p>
                        <p className="text-lg">{data?.customerName ? data?.customerName : t('common.none')}</p>
                    </div>

                    {data?.vehiclePlate ? <div className="car-plate-wrapper">
                        <table className="min-w-[150px] font-semibold text-[#262727] bg-[#ddd] border-2 border-[#292929] [&>tr]:border [&>tr]:border-[#292929] [&>tr>td]:border-[#292929] [&>tr>td]:border  [&>tr>td]:px-1 [&>tr>td]:text-center">
                            <tr>
                                <td>{data?.vehiclePlate}</td>
                                {/* <td>{data?.vehicleNumbers}</td> */}
                                {/* <td rowSpan='2' className="p-0">
                                    {splitted?.map((char)=>(<span className="block">{char}</span>))}
                                    <span className="block"><RxDotFilled className="w-5 h-5 m-auto" /></span>
                                </td> */}
                            </tr>

                            {/* <tr>
                                <td>{data?.vehicleNumbers}</td>
                                <td>{data?.vehicleLetters}</td>
                            </tr> */}
                        </table>
                    </div> : 
                        null
                        // <span className="capitalize text-[#333]">no vehicle info found</span>
                    }
                </div>

                <div className="px-4 customer-contact-wrapper mt-2">
                    <div className="flex items-center">
                    <div className={`${localStorage.getItem('lang') === 'ar' ? 'ml-4' : 'mr-4'}`}>
                            <span className="w-10 h-10 rounded-[50%] bg-mainYellow text-center flex items-center"><BsTelephone className="m-auto text-white text-xl" /></span>
                        </div>
                        <div className="capitalize">
                        <p className="text-[#B5B5C3] text-[12px]">{localStorage.getItem('lang') === 'ar' ? 'رقم الهاتف' : 'telephone'}</p>
                            <p className="text-[#464E5F] mt-1" dir="ltr">{data?.customerMobileNumber ? data?.customerMobileNumber : t('common.none')}</p>
                        </div>
                    </div>
                </div>

                <div className="order-notes-wrapper mt-12">
                    <div className="relative bg-[#3E4954] rounded-lg">
                        <div className="notes mb-2 px-4 py-3">
                            <h2 className="capitalize text-white font-bold">{t('order_details.left_section.order_notes')}</h2>
                            <p className="order-note-text text-[12px] text-[#B5B5C3] max-h-20 overflow-y-auto mt-2 px-1 leading-5 capitalize">{data?.orderNotes ? data?.orderNotes : localStorage.getItem('lang') === 'ar' ? 'لايوجد ملاحظات لعرضها' : 'there is no order notes to display'}</p>
                        </div>

                        <div className="relative bottom-0 left-0 right-0 bg-mainYellow px-4 py-3 rounded-lg">
                            <div className="flex items-center">
                                <div>
                                    <span className="w-10 h-10 rounded-[50%] bg-white text-center flex items-center"><CiLocationOn className="m-auto text-mainYellow text-xl" /></span>
                                </div>

                                <div className={`text-[12px] text-white ${localStorage.getItem('lang') === 'ar' ? 'mr-4' : 'ml-4'} font-semibold`}>
                                    {/* <p>12 Rehab St, 23 Building 12</p>
                                    <p>Apartment Jeddah</p> */}
                                    <p>{data?.customerAddress ? data?.customerAddress : <span className="capitalize">{t('common.none')}</span>}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="left-section-delivery-status-timeline-wrapper mt-8 bg-white px-4 py-3 rounded-lg">
                <div className="delivery-title-wrapper mb-2">
                    <h2 className="capitalize text-[#464E5F]">{t('order_details.left_section.delivery_status')}</h2>
                </div>

                <div className="mt-5">
                    <TimeLine event={timelineEvents} />
                </div>
            </div>

        </div>
    );
}

export default OrderDetailsLeftSection;