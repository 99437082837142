import { useDispatch, useSelector } from "react-redux";
import TopBuyingItem from "./TopBuyingItem/topBuyingItem";
import { useEffect } from "react";
import { getMostSellingItems } from "../../../Redux/Slice/AccountManager/Menu/menu";
// import Overlay from "../Common/overlay";
import { ItemSkeletons } from "../../../Skeleton/skeleton";
import NoDataEmoji from "../../../Common/nodataEmoje";
import { useTranslation } from "react-i18next";

const BestSellingItems = () => {
    const {t,i18n} = useTranslation()

    const dispatch = useDispatch()
    const mostSelling = useSelector(state=>state?.categories)

    // console.log(mostSelling);

    useEffect(() => {
        dispatch(getMostSellingItems())
    }, [dispatch])
    
    return ( 
        <article className="dash-best-selling-items pb-3">
            <section className="best-selling-title-wrapper text-[#464E5F] font-semibold text-[15px] capitalize">
                <h2>{t('dashboard.best_selling.title')}</h2>
            </section>

            <section className="top-selling-items-wrapper mt-5 max-h-[398px] overflow-hidden overflow-y-auto px-1 relative" dir="ltr">
                {   mostSelling?.mostSelling?.data?.length === 0 ? 
                        <div className="h-[250px]">
                            <NoDataEmoji />
                        </div>
                : 
                    mostSelling?.loading 
                        ? [...Array(5)]?.map((_,index)=><ItemSkeletons key={index} />)
                    
                    : mostSelling?.mostSelling?.data?.slice(0,5)?.map((item,index)=>(<TopBuyingItem key={index} item={item} />))
                }
                
            </section>
        </article>
    );
}

export default BestSellingItems;