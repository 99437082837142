import { createSlice } from "@reduxjs/toolkit"

export const TabSlice = createSlice({
	name: "Tab",
	initialState: {
		tabs:'ALL',
        index : 0
	},
	reducers: {
        tabHandler : (state,action)=>{
            state.tabs = action.payload?.tabs
            state.index = action.payload?.index
        }
    },
})
export const { tabHandler } = TabSlice.actions
export default TabSlice.reducer
