import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const activeUser = createAsyncThunk("activeActiveFun", async(id, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await toast.promise(axios.put(`/users/reactivate`,{id:id}),{
            pending: 'loading...',
            success: 'User Activated Successfully 👌',
        },{toastId : "activeUserToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})

export const disactiveUser = createAsyncThunk("disActiveFun", async(id, {rejectWithValue,dispatch})=>{
    // console.log(page,row);
    try {
        const res = await toast.promise(axios.put(`/users/deactivate`,{id:id}),{
            pending: 'loading...',
            success: 'User Deactivated Successfully 👌',
        },{toastId : "disactiveUserToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
             if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    status : [],
    error : null
}
export const ToggleActiveSlice = createSlice({ 
    name: "ToggleActive-Slice", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(activeUser.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(activeUser.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.status = action.payload
            // console.log(action.payload);
        })

        .addCase(activeUser.rejected, (state,action)=>{
            state.loading = false;
            state.status = ""
            state.error = action.payload
        })

        .addCase(disactiveUser.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(disactiveUser.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.status = action.payload
            // console.log(action.payload);
        })

        .addCase(disactiveUser.rejected, (state,action)=>{
            state.loading = false;
            state.status = ""
            state.error = action.payload
        })

    }
});

// export const {} = ToggleActiveSlice.actions

export default ToggleActiveSlice.reducer

