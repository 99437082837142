import { Box, Button, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { FaRegTimesCircle } from "react-icons/fa";
import Astrisks from "../../../../Components/Common/astrisk";
import { useDispatch } from "react-redux";
import { refundOrder } from "../../../../Components/Redux/Slice/Orders/refundSlice";

const RefundCustomer = ({open,close,id,t}) => {

    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,reset} = form
    const {errors} = formState
    const dispatch = useDispatch()
    
    // console.log(refund);

    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        minWidth : '550px',
        height: 'auto',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 2,
        px: 2,
        py: 3,
        borderRadius : 5,
    };

    // console.log(id);
    const submitHandler = async (values)=>{        
        await dispatch(refundOrder({id,values})).then( (e)=>{
            if(e?.payload?.message === "SUCCESS"){
                close()
                reset()
            }
        } )
        // console.log(values);
    }

    return ( 
        <>
            <Modal
                open={open}
                onClose={()=>{
                    reset()
                    close()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                    <div className="flex justify-between items-center  mb-2">
                        <h1 className="text-xl text-[#333] capitalize font-bold">{t('order_details.refund_btn')}</h1>

                        <div>
                            <FaRegTimesCircle 
                                onClick={()=>{
                                    reset()
                                    close()
                                }} 
                                className="text-mainRed text-xl cursor-pointer" />
                        </div>
                    </div>
                    <p className="text-sm font-normal text-[#828181] capitalize]">{t('order_details.refund_modal.helper')}</p>

                    <form noValidate onSubmit={handleSubmit(submitHandler)} className="mt-8">
                        <div className=" [&>div>label]:capitalize [&>div>label]:text-[#333]  [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-bold [&>div>input]:bg-[#f3f6f9] [&>div>input]:text-sm [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            
                            <div className="w-full border-b border-gray-400 pb-6 mb-3">
                                <label>{t('order_details.refund_modal.form.amount')} <Astrisks /></label>

                                <input type="text" id="refundCustomer"
                                    placeholder={t('order_details.refund_modal.form.amount_placeholder')}
                                    {...register('amount',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                        valueAsNumber : true,
                                        pattern:{
                                            value: /^\+?\d+$/,
                                            message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                        },
                                        maxLength : {
                                            value : 10,
                                            message : t('common.max_length',{length : '10'})
                                        },   
                                    })} 
                                    maxLength={10}
                                />
                                
                                {errors?.amount?.message && <p className="capitalize text-[13px] text-danger">{errors?.amount?.message}</p>}
                            </div>

                            <div className="w-full mt-3 [&>label]:capitalize [&>label]:text-[#333]  [&>label]:block [&>label]:mb-2 [&>label]:font-bold [&>textarea]:bg-[#f3f6f9] [&>textarea]:text-sm [&>textarea]:w-full [&>textarea]:p-3 [&>textarea]:focus:outline-slate-400 [&>textarea]:rounded-md [&>textarea]:text-mainColor [&>textarea]:mb-1">
                                <label>{t('order_details.refund_modal.form.reason')} <Astrisks /></label>
                                <textarea
                                    className="resize-none"
                                    placeholder={t('order_details.refund_modal.form.reason_placeholder')}
                                    {...register('reason',{
                                        required:{
                                            value : true,
                                            message : t('common.required')
                                        },
                                    })} 
                                >
                                </textarea>
                                {errors?.reason?.message && <p className="capitalize text-[13px] text-danger">{errors?.reason?.message}</p>}
                            </div>
                            
                            <div className={`flex justify-end ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'} item-center gap-x-4 mt-6 mb-0 capitalize text-sm [&>button]:capitalize [&>button]:rounded-sm [&>button]:min-w-[150px]`}>
                                <Button className="bg-transparent border border-solid border-mainRed text-mainRed transition-all duration-300 hover:bg-mainRed hover:text-white" 
                                    onClick={()=>{
                                        reset()
                                        close()
                                    }} >
                                    {t('common.cancel_btn')}
                                </Button>
                                <Button className="bg-mainColor text-white disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed" type="submit">{t('order_details.refund_btn')}</Button>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    );
}

export default RefundCustomer;