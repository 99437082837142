import logo from '../../Assets/imgs/logo.svg'
import collapseLogo from '../../Assets/imgs/collapse-logo.svg'
import linth from '../../Assets/imgs/Linth.svg'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Astrisks from '../../Components/Common/astrisk'
import { Autocomplete, Button, TextField } from '@mui/material'
import { getPartners, managerLogin } from '../../Components/Redux/Slice/AccountManager/manager'
import Overlay from '../../Components/Common/overlay'
import { getMapKey } from '../../Components/Redux/Slice/common/mapSlice'

const SelectRestaurant = () => {
    const {t, i18n}  = useTranslation()
    const partners = useSelector(state=>state?.manager)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isMobile,setisMobile] = useState(false)
    // used to check if the window width less that 768 to swap between image srcs for clear responsive view
    useEffect( ()=>{
        const handleResize = ()=>{
            setisMobile(window.innerWidth < 768)
        }
        window.addEventListener('resize',handleResize)
        handleResize()

        // clean up function 
        return ()=> window.removeEventListener('resize',handleResize)
    },[] )


    /* UseForm Hook */
    const form = useForm()
    const {register,control,handleSubmit,formState,getValues} = form
    const {errors,isDirty} = formState

    useEffect(() => {
        dispatch(getPartners())
        if(!localStorage.getItem('gkey')){
            dispatch(getMapKey())
        }
    }, [dispatch])

    // console.log(!localStorage.getItem('gkey'));
    // console.log(partners?.partners?.data);
    
    
    const defaultProps = {
        options: partners?.partners?.data || [],
        getOptionLabel: (option) => option ? localStorage.getItem('lang') === 'en' ? option.restaurantName : option.restaurantNameAr : '',
        isOptionEqualToValue : (option, value) => option && value ? option.restaurantId === value.restaurantId : false
        
    };

    const [restaurant,setrestaurant] = useState('')

    const submitHandler = (data)=>{
        // console.log(data?.partnerId);
        dispatch(managerLogin(data)).then((e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                navigate('/dashboard/partner/home')
                localStorage.setItem('pid',data?.partnerId)
            }
        })
    }

    // console.log(getValues());

    return ( 
        <article className="login-wrapper h-screen w-full">
            <div className="login-sections-wrapper grid grid-cols-2 h-full">
                {/* This Section for the left side of the page with logo and login type*/}
                <section className="login-banner-wrapper w-full h-full bg-mainColor relative">
                    {/* <div className="banner-title py-10 px-6 rounded-md overflow-hidden">
                        <span className="capitalize p-2 bg-mainGreen text-white font-semibold text-sm rounded-md">partner login</span>
                    </div> */}

                    <div className="banner-logo-img-wrapper text-center w-full pt-24">
                        <img src={isMobile ? collapseLogo : logo } alt="logo" className='m-auto w-[340px] h-[130px]' />
                    </div>

                    <div className='login-banner-linth absolute bottom-0 left-0'>
                        <img src={linth} alt='linth' className='m-auto lg:w-[410px] lg:h-[340px]' />
                    </div>
                </section>

                {/* This Section for the right side of the page with the login form */}
                <section className="login-form-wrapper w-full h-full bg-white">
                    <div className='form-wrapper m-auto pt-32 pl-11 md:pl-20 lg:pl-32 xl:pl-44'>
                        <div className='login-form-title [&>p]:capitalize font-extrabold font-Ubuntu text-xl [&>p]:text-[#2F3044] text-left'>
                            <p>welcome to takeIN</p>
                            <p>Account Manager Dashboard</p>
                            <p>Please choose restaurant to proceed</p>
                            
                        </div>

                        <form noValidate onSubmit={handleSubmit(submitHandler)} className='form w-[95%] md:w-[75%] lg:w-[70%] xl:w-[55%] mt-10 [&>div]:mb-5 [&>div>label]:block [&>div>label]:text-lg [&>div>label]:capitalize [&>div>label]:font-Ubuntu [&>div>label]:text-[#2f3044] [&>div>label]:font-bold [&>div>label]:mb-1 [&>div>input]:w-full [&>div>input]:py-4 [&>div>input]:px-2 [&>div>input]:rounded-md [&>div>input]:bg-[#EEF1F5]'>
                            <div>
                                <label>Restaurants <Astrisks /></label>
                                <Controller
                                    name='partnerId'
                                    rules={{required:{
                                        value : true,
                                        message : t('common.required')
                                    }}}
                                    control={control}
                                    // defaultValue={}
                                    render={({field:{onChange}})=>(
                                        <Autocomplete
                                            {...defaultProps}
                                            id="partnerId"
                                            value={restaurant}
                                            onChange={(event, newValue) => {
                                                onChange(newValue?.restaurantId);
                                                setrestaurant(newValue)
                                            }}
                                            // renderOption={(props, option) => (
                                            //     <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            //         <img
                                            //             loading="lazy"
                                            //             width="20"
                                            //             srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            //             src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            //             alt=""
                                            //         />
                                            //         {option.label} ({option.code}) +{option.phone}
                                            //     </Box>
                                            // )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="" variant="outlined" />
                                            )}

                                            sx={{'& .MuiTextField-root' : {backgroundColor : '#F3F6F9', borderRadius : '6px'}, '& .MuiOutlinedInput-root' : {padding:'2px'}, '& fieldset' : {border : 'none', outline : 'none'} }}
                                        />
                                    )}
                                />
                                {errors && <p className='text-danger text-sm mt-1 capitalize'>{errors?.selectedRestaurant?.message}</p>}
                            </div>

                            <div className='submit-btn mt-8'>
                                <Button type='submit' disabled={partners?.loading} className='disabled:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainColor px-8 py-2 rounded-md text-white font-semibold w-full font-Ubuntu'>{t('common.next')}</Button>
                            </div>
                        </form>
                    </div>

                    <footer className='login-footer mt-16 w-[70%] mx-auto'>
                        <ul className='capitalize flex items-center justify-evenly text-center [&>li]:mr-6 md:[&>li]:mr-12 [&>li>a]:text-mainColor [&>li>a]:font-playfair font-bold'>
                            <li>
                                <Link to='/termsconditions'>terms & conditions</Link>
                            </li>

                            <li>
                                <Link to='/privacypolicies'>Privacy & Policies</Link>
                            </li>

                            <li>
                                <Link to='/aboutus'>About us</Link>
                            </li>
                        </ul>
                    </footer>
                </section>
            </div>

            {partners?.loading ? <Overlay /> : null}        
        </article>
    );
}

export default SelectRestaurant;