import { FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { BiDotsHorizontalRounded, BiUserCheck, BiUserX } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { activeUser, disactiveUser } from "../../../Components/Redux/Slice/Users/toggleActiveSlice";
import { getBackOfficeUsers } from "../../../Components/Redux/Slice/Users/usersSlice";
import { dateFormate } from "../../../Components/Common/dateFormate";
import { CiSearch } from "react-icons/ci";
import DataTable from "react-data-table-component";
import ColumnSelection from "../../../Components/Common/columnSelection";
import { useRemoteSort } from "../../../Components/Common/sortHook";
import NoDataEmoji from "../../../Components/Common/nodataEmoje";
import {TableStyles} from '../../../Components/Common/tableStyle'
import { resetPage } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";


function ActionCell( data ) {
    const [page, setpage] = useState(1)
    const [row, setrow] = useState(10)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // console.log(data?.id);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch()
    const status = useSelector(state=>state?.users)

    const [loading, setloading] = useState(false)

    const handleActive = () =>{
        dispatch(activeUser(data?.id)).then(() => {
            dispatch(getBackOfficeUsers(page, row))
            dispatch(resetPage());
        })
        .catch((error) => console.log(`Error activating user: ${error}`));
        setloading(true)
    }

    const handleDisactive = ()=>{
        dispatch(disactiveUser(data?.id)).then(() => {
            dispatch(getBackOfficeUsers(page, row))
            dispatch(resetPage());
        })
        .catch((error) => console.log(`Error activating user: ${error}`));
        setloading(true)
    };

    useEffect(() => {
      setTimeout(() => {
        setloading(false)
      }, 3000);
    }, [loading])    
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0 } }}
                className='shadow-md p-0'
            >
                <div className={`[&>li]:mb-1 [&>li>svg]:text-xl rounded-md overflow-hidden [&>li]:flex [&>li]:items-center [&>li]:gap-x-2 ${localStorage?.getItem('lang') === 'ar' ? '[&>li]:flex-row-reverse' : '[&>li]:flex-row'}`}>
                    {data?.status === 'ON_HOLD' ?
                        <>
                            <MenuItem className='text-mainGreen hover:bg-[#5cac7d42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleActive}><BiUserCheck />{localStorage?.getItem('lang') === 'ar' ? 'نشط' : 'Activate'}</MenuItem>
                            <MenuItem className='text-mainRed hover:bg-[#c6345b42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleDisactive}><BiUserX />{localStorage?.getItem('lang') === 'ar' ? 'غير نشط' : 'Deactivate'}</MenuItem>
                        </>

                        : data?.status === 'ACTIVE' ?
                        <MenuItem className='text-mainRed hover:bg-[#c6345b42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleDisactive}><BiUserX />{localStorage?.getItem('lang') === 'ar' ? 'غير نشط' : 'Deactivate'}</MenuItem>

                        :data?.status === 'DEACTIVATED' ?
                        <MenuItem className='text-mainGreen hover:bg-[#5cac7d42] disabled:opacity-50 disabled:cursor-not-allowed' disabled={loading} onClick={handleActive}><BiUserCheck />{localStorage?.getItem('lang') === 'ar' ? 'نشط' : 'Activate'}</MenuItem>

                    : null}
                </div>
            </Menu>
        </div>
    );
}

const BackOfficeUsers = ({t}) => {
    const dispatch = useDispatch()
    const users = useSelector(state=>state.users)
    const [page,setpage] = useState(1)
    const [size,setsize] = useState(10)
    const [searchValue, setsearchValue] = useState('')

    const {currentPage} = useSelector((state) => state?.resetPagination);
    const {toggle} = useSelector((state) => state?.resetPagination);
    // const [col, setcol] = useState(undefined)
    // const [dir, setdir] = useState('asc')

    useEffect(() => {
        dispatch(getBackOfficeUsers({page,size}))
    }, [])

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])


    // useEffect(() => {
    //     dispatch(getBackOfficeUsers({page,row,searchValue,col,dir}))
    // }, [dispatch, page,row,searchValue])

    const data = users?.backOfficeUsers?.data

    const columns = [
        {
            id: 'name',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الاسم' : 'Name'}`,
            selector: row => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            id: 'gender',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الجنس' : 'Gender'}`,
            cell: row => <span data-tag="allowRowEvents" className="capitalize">{localStorage.getItem('lang') === 'ar' ? row?.genderAr : row?.gender }</span>,
            sortable: false,
        },
        {
            id: 'email',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الايميل' : 'Email'}`,
            selector: row => row.email,
            sortable: true,
        },
        {
            id: 'phone',
            name: `${localStorage.getItem('lang') === 'ar' ? 'رقم التليفون' : 'Phone'}`,
            selector: row => row.mobileNumber,
            sortable: false,
        },
        {
            id: 'createdAt',
            name: `${localStorage.getItem('lang') === 'ar' ? 'تم انشاءه فى' : 'Created At'}`,
            cell: (row) => <span>{dateFormate(row.createdAt)}</span>,
            sortable: true,
        },
        {
            id: 'status',
            name: `${localStorage.getItem('lang') === 'ar' ? 'الحالة' : 'Status'}`,
            // cell : (row)=>(row.status === 'ON_HOLD' ?  <span className='text-mainYellow font-semibold capitalize px-2 py-1 rounded-md bg-opacity-10 bg-mainYellow min-w-[100px] text-center block'>{row.status?.toLowerCase().replace(/_/g," ")}</span> : row.status === 'ACTIVE' ? <span className='text-mainGreen font-semibold capitalize px-2 py-1 rounded-md bg-opacity-10 bg-mainGreen block min-w-[100px] text-center'>{row.status?.toLowerCase().replace(/_/g," ")}</span> : row.status === 'DEACTIVATED' ? <span className='text-mainRed font-semibold capitalize px-2 py-1 rounded-md bg-opacity-10 bg-mainRed min-w-[100px] text-center block'>{row.status?.toLowerCase().replace(/_/g," ")}</span> : null ),
            cell : (row) => <span className={`text-center bg-opacity-25 font-semibold capitalize px-2 py-1 rounded-md min-w-[100px]  ${row?.status === 'ON_HOLD' ? 'text-mainYellow bg-mainYellow' : row?.status === 'ACTIVE' ? 'text-mainGreen bg-mainGreen' : row?.status === 'DEACTIVATED' ? 'text-mainRed bg-mainRed' : null }`}>
                {
                    row?.status === 'ON_HOLD' ? t('common.onHold') :
                    row?.status === 'ACTIVE' ? t('common.active') :
                    row?.status === 'DEACTIVATED' ? t('common.deactivated') : null
                }
            </span>,
        },
        {
            id: 'actions',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: row=>ActionCell(row)
        },
        ];
      

    const handleSearch = ()=>{
        dispatch(getBackOfficeUsers({searchValue})).then( ()=> dispatch(resetPage()))
    }

    const handleReset = ()=>{
        dispatch(getBackOfficeUsers({page,size})).then( ()=> dispatch(resetPage()))
        setsearchValue('')
    }

    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])



    // handle dropdown sort
    // const [anchorElBy, setAnchorElBy] = useState(null);
    // const openBy = Boolean(anchorElBy);
    // const handleClickBy = (event) => {
    //     setAnchorElBy(event.currentTarget);
    // };
    // const handleCloseBy = () => {
    //     setAnchorElBy(null);
    // };
    // const [anchorElOrder, setAnchorElOrder] = useState(null);
    // const openOrder = Boolean(anchorElOrder);
    // const handleClickOrder = (event) => {
    //     setAnchorElOrder(event.currentTarget);
    // };
    // const handleCloseOrder = () => {
    //     setAnchorElOrder(null);
    // };

    // const [selectedByIndex, setselectedByIndex] = useState(-1)
    // const [selectedOrderIndex, setselectedOrderIndex] = useState(-1)

    // const sortByList = [
    //     {name:'id'},
    //     {name:'name'},
    //     {name:'email'},
    //     // {name:'Price Before'},
    //     // {name:'Calories'},
    //     {name:'created at'},
    //     // {name:'Final Amount'},
    // ]

    // const sortOrderList = [
    //     {name:'Asc'},
    //     {name:'Desc'},
    // ]

    // const handleActiveBy = (item,index)=>{
    //     setselectedByIndex(index)
    //     setcol(item?.name)
    //     setAnchorElBy(null)
    //     // dispatch(getBranches({page,size,col,dir,searchValue}))
    // }
    // const handleActiveOrder = (item,index)=>{
    //     setselectedOrderIndex(index)
    //     setdir(item?.name)
    //     setAnchorElOrder(null)
    // }

    const {handleRemoteSort, icon} = useRemoteSort(getBackOfficeUsers, dispatch, page, size, searchValue);


    const [selectedColumns, setSelectedColumns] = useState(['name', 'gender', 'email', 'phone', 'role', 'createdAt', 'status', 'actions']);
    
    const [open, setopen] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpen = ()=>{
        setopen(true)
    }
    const handleClose = ()=>{
        setopen(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    useEffect(() => {
        setSelectedColumns(['name', 'gender', 'email', 'phone', 'role', 'createdAt', 'status', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);
    
    return ( 
        <>
            <section className="users-control-wrapper relative flex flex-wrap justify-end items-center mt-2 w-full">

                <div className="absolute -top-[50.5px] right-0 flex gap-x-4">
                    <ColumnSelection columns={columns} handleOpen={handleOpen} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={open} close={handleClose} />
                    {/* <Button onClick={handleOpen} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                </div>

                {/* <div className='flex gap-x-3'>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openBy ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBy ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickBy}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-[600] capitalize'
                        >
                            {col ? col?.replace(/_/g, " ") : 'Sort By'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElBy}
                            open={openBy}
                            onClose={handleCloseBy}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42] capitalize'>
                                Sort By
                            </MenuItem>

                            {sortByList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] font-semibold hover:bg-[#d9c8ff42]'
                                    onClick={()=>handleActiveBy(item,index)}
                                    selected = {index === selectedByIndex}
                                >
                                    {item?.name?.replace(/_/g, " ")}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>

                    <div>
                        <Button
                            id="sort-dropdown"
                            aria-controls={openOrder ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openOrder ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClickOrder}
                            endIcon={<KeyboardArrowDownIcon />}
                            className='bg-[#fff] text-mainColor py-3 text-sm font-semibold capitalize'
                        >
                            {dir ? dir : 'Sort Order'}
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'sort-dropdown',
                            }}
                            anchorEl={anchorElOrder}
                            open={openOrder}
                            onClose={handleCloseOrder}
                            className=' w-full capitalize'
                        >
                            <MenuItem disabled className='text-[#9b96a8] hover:bg-[#d9c8ff42]'>
                                Sort Order
                            </MenuItem>

                            {sortOrderList?.map( (item,index)=>(
                                <MenuItem 
                                    key={index} 
                                    className='text-[#333] hover:bg-[#d9c8ff42] font-semibold'
                                    onClick={()=>handleActiveOrder(item,index)}
                                    selected = {index === selectedOrderIndex}
                                >
                                    {item?.name}
                                </MenuItem>
                            ) )}
                            
                        </Menu>
                    </div>
                </div> */}

                <section className={`addNewUser-btn-wrapper flex items-center gap-x-3 w-full ${localStorage?.getItem('lang') === 'ar' ? 'flex-row' : 'flex-row-reverse'}`}>
                    <div className={`users-search-wrapper flex items-center`}>
                        <FormControl 
                            className="search-form [&>div>input]:py-[.6rem]"
                            sx={{ m: 1, width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                            <OutlinedInput
                                type="search"
                                id="outlined-adornment-search"
                                placeholder={localStorage.getItem('lang') === 'ar' ? 'ابحث في المستخدمين...' : 'Search Users...'}
                                onChange={(e)=>{
                                    setsearchValue(e.target.value.trim())
                                    dispatch(resetPage())
                                    if (!e.target.value) {
                                        handleReset();
                                        dispatch(resetPage())
                                    }}}
                                startAdornment={
                                <InputAdornment position="start">
                                    
                                    <IconButton
                                        aria-label="search"
                                        onClick={handleSearch}
                                        edge="start"
                                    >
                                        <CiSearch />
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                </section>
            </section>

            {/* <section className="w-full mt-4">
                <p className="mb-2 font-semibold capitalize text-mainColor">select columns</p>
                <div className="w-full">
                    <Select
                        sx={{'& fieldset': { borderColor: 'transparent',border:'none' }}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedColumns}
                        onChange={handleColumnSelection}
                        className="w-full bg-white"
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} className="bg-[#63bad7] text-white" />
                                ))}
                            </Box>
                            )}
                    >
                        {columns.map((column,index) => (
                            <MenuItem
                                selected = {index === 0 || 1 || 2}
                                key={index}
                                value={column.name}
                            >
                                {column.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </section> */}

            <section className="datatable-wrapper mt-5 bg-white relative">
                {data?.length ===0 ? 
                        <div className='p-8'><NoDataEmoji /></div>
                    :

                        <>
                            <DataTable
                                direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                                columns={visibleColumns}
                                data={data}
                                pagination
                                paginationPerPage = {size}
                                paginationRowsPerPageOptions = {[10,50,100]}
                                paginationServer
                                paginationTotalRows={users?.backOfficeUsers?.meta?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleRowChange}
                                customStyles={TableStyles}
                                highlightOnHover
                                sortServer
                                sortIcon={icon}
                                onSort={handleRemoteSort}
                                paginationDefaultPage={page}
                                keyField="id"
                                paginationResetDefaultPage = {true}
                                paginationComponentOptions={
                                {
                                    rowsPerPageText : t('common.paginationRowText'),
                                    rangeSeparatorText : t('common.of')
                                }
                            }
                            />
                        </>
                }

                {users?.loading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null}
            </section>
        </>
     );
}

export default BackOfficeUsers;