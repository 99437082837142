import { AiOutlineCloseCircle } from "react-icons/ai"; 
import { Box, Button, FormControl, FormControlLabel, InputAdornment, InputLabel, ListItemText, MenuItem, Modal, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { editPayout, getCourierById, getCouriers, getCouriersRequests } from "../../../Components/Redux/Slice/Couriers/couriersSlice";
import { resetPage } from "../../../Components/Redux/Slice/ResetPagination/resetPagination";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    minWidth : '500px',
    // height: '90%',
    // minHeight: '600px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 2,
    py: 4,
    px:2,
    borderRadius : 5,
};

const PayoutModal = ({open,close,type,id, data}) => {
    const {t} = useTranslation()
    
    const dispatch = useDispatch()
    const contractor = useSelector(state=>state?.deliveryContractor)
    
    const form = useForm({
        defaultValues : {
            payoutAmountType: data?.payoutAmountType,
            payoutFlatFeesValue : data?.payoutFlatFeesValue,
            payoutFrequency : data?.payoutFrequency,
            payoutPercentageValue : data?.payoutPercentageValue
        }
    })
    
    const {register,getValues,formState,handleSubmit,reset,control,watch,setValue} = form
    const {errors} = formState

    watch('payoutMethod')
    watch('payoutAmountType')

    const [feesType, setfeesType] = useState()

    const feesTimeFrequancy = [
        {id:1,name:'payout per week',value:"PAYOUT_PER_WEEK"},
        {id:2,name:'payout per month',value:"PAYOUT_PER_MONTH"},
        {id:3,name:'payout per year',value:"PAYOUT_PER_YEAR"},
    ]

    const handleCustomizedClose = ()=>{
        close()
        reset()
    }

    useEffect(() => {
        setValue('payoutAmountType',data?.payoutAmountType)
        setValue('payoutFlatFeesValue',data?.payoutFlatFeesValue)
        setValue('payoutFrequency',data?.payoutFrequency)
        setValue('payoutPercentageValue',data?.payoutPercentageValue)
    }, [setValue,data])


    useEffect(() => {
        if (open) {
            reset({
                payoutMethod : data?.payoutMethod,
                payoutAmountType: data?.payoutAmountType,
                payoutFrequency : data?.payoutFrequency,
                payoutPercentageValue : data?.payoutPercentageValue,
                payoutFlatFeesValue : data?.payoutFlatFeesValue,
            });
        }
    }, [open, reset, data]);


    const handleEditPayoutSubmit = async (data)=>{
        const page = 1,
        size = 10
        
        const values = {
            id : id,
            ...data
        }
        await dispatch(editPayout(values)).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                close()
                reset()
                dispatch(resetPage());

                if(type === 'tableAll'){
                    dispatch(getCouriers({page,size}))
                } else if(type === 'tableRequests'){
                    dispatch(getCouriersRequests({page,size}))
                } else if(type === 'details'){
                    dispatch(getCourierById(id))
                }
            }
        } )
    }

    const handleRadioChangeHandler = (e) =>{
        const value = e.target.value;
        if (value === "FLAT_FEES_PER_TIME") {
            setValue('payoutPercentageValue',null)
        } else if (value === "PERCENTAGE_OF_DELIVERY_FEES") {
            setValue('payoutFlatFeesValue',null)
            setValue('payoutFrequency',null)
        } else if (value === "PERCENTAGE_OF_ORDER_AMOUNT") {
            setValue('payoutFlatFeesValue',null)
            setValue('payoutFrequency',null)
        }
    }
    
    return ( 
        <>
            <Modal
                open={open}
                onClose={handleCustomizedClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                dir
            >
            <Box sx={style} className='overflow-auto' dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                <div className="flex justify-between items-center border-b pb-2 mb-3">
                    <h1 className="text-xl text-[#333] capitalize font-semibold">{t('couriers.editCourierPayoutMethod')}</h1>

                    <span className="w-fit text-mainRed cursor-pointer text-xl" onClick={handleCustomizedClose}><AiOutlineCloseCircle /></span>
                </div>

                <form noValidate onSubmit={handleSubmit(handleEditPayoutSubmit)} className="grid grid-cols-1 md:grid-cols-2 gap-3 [&>div>p]:text-sm [&>div>p]:text-mainRed [&>div>p]:capitalize [&>div>label]:block [&>div>label]:font-semibold [&>div>label]:text-sm [&>div>label]:text-[#353535] [&>div>label]:capitalize [&>div>label]:mb-1 [&>div>input]:text-mainColor [&>div>input]:p-3 [&>div>input]:w-full [&>div>input]:rounded-md [&>div>input]:bg-[#f3f6f9]">
                    <div className="col-span-2">
                        <label>{t('deliveryContractors.form.payoutCalcMethod')}</label>
                        <FormControl>
                        <Controller
                            name="payoutAmountType"
                            control={control}
                            rules={
                                {required : {
                                    value : true,
                                    message : t('common.required')
                                }}
                            }
                            render={({ field }) => (
                                <RadioGroup 
                                    {...field}
                                    onChange={(e)=>{
                                        field.onChange(e)
                                        handleRadioChangeHandler(e)
                                        
                                    }} 
                                    
                                    aria-labelledby="row-radio-buttons-group-label" className="capitalize"
                                >
                                    <FormControlLabel value="FLAT_FEES_PER_TIME" control={<Radio />} label={t('deliveryContractors.form.flatFeesPerTime')} />
                                    <FormControlLabel value="PERCENTAGE_OF_DELIVERY_FEES" control={<Radio />} label={t('deliveryContractors.form.PERCENTAGE_OF_DELIVERY_FEES')} />
                                    {/* <FormControlLabel value="DELIVERY_FEES" control={<Radio />} label={t('deliveryContractors.form.deliveryFees')} /> */}
                                    <FormControlLabel value="PERCENTAGE_OF_ORDER_AMOUNT" control={<Radio />} label={t('deliveryContractors.form.percentOfOrder')} />
                                </RadioGroup>
                            )}
                        />

                        </FormControl>
                        {errors && <p>{errors?.payoutAmountType?.message}</p>}
                    </div>

                    {getValues('payoutAmountType') === 'FLAT_FEES_PER_TIME' ?

                        <>
                            <div className={`[&>div]:bg-[#f3f6f9] [&>div]:rounded-md`}>
                                <label>{t('deliveryContractors.form.flatFeesTimeFreqancy')}</label>
                                <FormControl sx={{width: '100%' }}>
                                    <InputLabel id="demo-simple-select-label" className="text-[#8a8a8a] text-sm">{t('deliveryContractors.form.flatFeesTimeFreqancyPlaceholder')}</InputLabel>
                                    <Controller
                                        control={control}
                                        name="payoutFrequency"
                                        value={feesType}
                                        rules={{required:t('common.required')}}
                                        render={({field,fieldState: { error }})=>(
                                            <>
                                                <Select {...field}
                                                    label={t('deliveryContractors.form.flatFeesTimeFreqancyPlaceholder')}
                                                    displayEmpty
                                                    // inputRef={{...register('payoutFrequency')}}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="payoutFrequency"
                                                    // value={feesType}
                                                    // onChange={handleFeesTypeChange}
                                                    // renderValue={(selected) => selected.join(', ')}
                                                    sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'9px 14px'},'& fieldset':{border : 0,},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9',},'& .css-zx5rfr' : {backgroundColor : '#f3f6f9'}}}
                                                >
                                                    {feesTimeFrequancy?.map( (item)=>(
                                                        <MenuItem key={item?.id} value={item?.value}>
                                                            <ListItemText className='capitalize' primary={item?.name?.toLowerCase()?.replace(/_/g," ")} />
                                                        </MenuItem> 
                                                    ) )}
                                                </Select>                                                    
                                            </>
                                        )}
                                        
                                    />
                                    
                                </FormControl>

                                {errors?.payoutFrequency && <p className="capitalize text-sm text-danger mt-1">{errors?.payoutFrequency?.message}</p>}
                            </div>
                            
                            <div>
                                <label>{t('deliveryContractors.form.flatFeesAmount')}</label>
                                    <TextField
                                        className="w-full"
                                        id="payoutFlatFeesValue"
                                        {...register('payoutFlatFeesValue',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            max:{
                                                value:60000,
                                                message : t('common.max_value',{value : '60000'})
                                            },
                                            valueAsNumber : true
                                        })}
                                        // label="With normal TextField"
                                        // sx={{ m: 1, width: '25ch', }}
                                        sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
                                        }}
                                        inputProps={{
                                            min:0
                                        }}
                                    />
                                {errors && <p>{errors?.payoutFlatFeesValue?.message}</p>}
                            </div>
                        </>
                        
                        :

                        (getValues('payoutAmountType') === 'PERCENTAGE_OF_DELIVERY_FEES' || getValues('payoutAmountType') === 'PERCENTAGE_OF_ORDER_AMOUNT' ) ?
                            <div className="col-span-2">
                                <label>{t('deliveryContractors.form.payoutPercentageValue')}</label>
                                <input type="text" 
                                    className="placeholder:text-[#999] placeholder:capitalize placeholder:text-sm" 
                                    placeholder={t('deliveryContractors.form.payoutPercentageValue')} 
                                    {...register('payoutPercentageValue',{
                                        required : {
                                            value : true,
                                                message : t('common.required')
                                            },
                                            pattern : {
                                                value: /^[0-9]*$/,
                                                message: t('common.onlyNumbers')
                                            },
                                            valueAsNumber : true,
                                            max : {
                                                value : 100,
                                                message : t('common.max_value',{value : '100'})
                                            }
                                        })}
                                    />
                                    {errors && <p>{errors?.payoutPercentageValue?.message}</p>}
                                </div>
                                :
                                
                                null
                        }

                        <div className="text-right col-span-2 mt-5">
                            <Button type="submit" className="min-w-[120px] bg-mainColor text-white capitalize">{t('common.submit')}</Button>
                        </div>
                </form>

            </Box>
        </Modal>
        </>
     );
}
 
export default PayoutModal;