import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AccManager = () => {
    const location = useLocation()
    const auth = useSelector(state=>state.auth)

    // console.log(auth?.role === 'ACCOUNT_MANAGER' && !localStorage.getItem('pid'));
    return ( 
        (auth?.role === 'ACCOUNT_MANAGER' && !localStorage.getItem('pid') ) ? <Navigate to='/selectrestaurant' state={{from:location}} replace /> : auth?.role === 'ACCOUNT_MANAGER' && localStorage.getItem('pid') ? <Outlet /> : <Navigate to='/dashboard/unauthorized' state={{from:location}} replace />
    );
}

export default AccManager;

export const EvictRole = ()=>{
    const location = useLocation()
    const auth = useSelector(state=>state.auth)

    return ( 
        (auth?.role !== 'ACCOUNT_MANAGER' ) ? <Outlet /> : (auth?.role === 'ACCOUNT_MANAGER' && !localStorage.getItem('pid')) ? <Navigate to='/selectrestaurant' state={{from:location}} replace /> :  <Navigate to='/dashboard/unauthorized' state={{from:location}} replace />
    );
}